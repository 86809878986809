import React from 'react';
import { PropTypes } from 'prop-types';

import { CardContent, CardHeader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import { GRAY, ROLES_ADMIN } from '../../../../../../utils/constants';
import { getTimeConversionFn } from '../../../../../../utils/utils';
import { hasRoles } from '../../../../../../utils/keycloak';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)',
        borderRadius: 0,
    },
    header: {
        backgroundColor: GRAY,
        color: 'white',
    },
    cardContent: {
        color: GRAY,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        padding: theme.spacing(3, 4),
    },
}));

export default function InfoCardComponent({
    id,
    codename,
    description,
    recordingPolicy,
}) {
    const classes = useStyles();
    const intl = useIntl();

    const infoTitle = intl.formatMessage({ id: 'information' });
    const isAdmin = hasRoles(ROLES_ADMIN);
    return (
        <Card className={classes.root}>
            <CardHeader title={infoTitle} className={classes.header} />
            <CardContent className={classes.cardContent}>
                {isAdmin ? (
                    <Typography variant={'body2'}>
                        <b>{intl.formatMessage({ id: 'deployment_id' })}:</b>
                        {id}
                    </Typography>
                ) : null}
                <Typography variant={'body2'}>
                    <b>{intl.formatMessage({ id: 'deployment_code' })}:</b>
                    {codename}
                </Typography>
                <Typography variant={'body2'}>
                    <b>
                        {intl.formatMessage({ id: 'deployment_description' })}:
                    </b>{' '}
                    {description}
                </Typography>
                <Typography variant={'body2'}>
                    <b>{intl.formatMessage({ id: 'rec_policy' })}:</b>
                    {recordingPolicy
                        ? getTimeConversionFn(recordingPolicy.window, intl)
                        : '-'}
                </Typography>
            </CardContent>
        </Card>
    );
}

InfoCardComponent.propTypes = {
    id: PropTypes.string.isRequired,
    codename: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    recordingPolicy: PropTypes.object,
};
