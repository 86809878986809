import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { filterCameraMosaicActionCreator } from "../../../../actions/commonActions";
import { ROUTE_DEPLOYMENT_MOSAIC } from "../../../../utils/routes";
import { buildUrl } from "../../../../utils/axiosRequests";
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import marker from "../../../../../src/images/marker.png"
import shadow from "../../../../../src/images/shadow.png"
import {MAP_URL} from "./mapConstants";


const useStyles = makeStyles(() => ({
  map: {
    height: '400px',
    overflow: 'hidden',
  },
}));

const MapComponent = ({deploymentLocation, locale}) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { deployment_id } = useParams();
  const MAX_DECIMALS = 6;
  const POPUP_MARGINS = '0px 0px 0px 0px';
  const DEFAULT_ZOOM = 15;
  const cameraList = useSelector((state) => state.commonState.cameraList[deployment_id]) || [];
  const [mapControls, setMapControls] = useState(null);
  const [mapMarkers, setMapMarkers] = useState(null);

  const DefaultIcon = L.Icon.extend({
    options: {
      shadowUrl: shadow,
      iconSize: [34, 34],
      shadowSize: [26, 11],
      iconAnchor: [27, 28],
      shadowAnchor: [23, 0],
      popupAnchor: [-10, -34]
    }
  });

  const defaultIcon = new DefaultIcon({ iconUrl: marker });


  const filterCameraMosaic = useCallback((cameras) => {
    dispatch(filterCameraMosaicActionCreator({ deployment_id, filteredCameraList: cameras }));
  }, [dispatch, deployment_id]);

  const handleGoToCamera = (camera) => {
    filterCameraMosaic([camera])
    const url = buildUrl(ROUTE_DEPLOYMENT_MOSAIC, { deployment_id })
    history.push(url);
  };

  const centerOnDeployment = () => {
    if (deploymentLocation && mapControls) {
      mapControls.setView([deploymentLocation.latitude, deploymentLocation.longitude], DEFAULT_ZOOM);
    }
  }

  useEffect(() => {
    let map;

    map = L.map('map').setView([0, 0], 2);
    setMapControls(map);
    L.tileLayer(MAP_URL, {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);
  }, [])

  useEffect(() => {
    if (mapControls) {
      if (cameraList?.length) {
        const camerasGroup = new L.FeatureGroup();
        for (let camera of cameraList) {
          if (camera?.place?.geo?.latitude && camera?.place?.geo?.longitude) {
            let marker = L.marker([camera.place.geo.latitude, camera.place.geo.longitude], {icon: defaultIcon});

            const div = document.createElement("div");

            const link = document.createElement("span");
            link.innerHTML = camera.description;
            link.style.cursor = 'pointer';
            link.style.color = 'blue';
            link.style.textDecoration = 'underline';
            link.onclick = () => handleGoToCamera(camera);

            const coordinates_latitude = document.createElement("p");
            coordinates_latitude.innerHTML = camera.place.geo.latitude.toFixed(MAX_DECIMALS) + " " + intl.formatMessage({ id: 'cardinal_north' });
            coordinates_latitude.style.margin = POPUP_MARGINS;
            const coordinates_longitude = document.createElement("p");
            coordinates_longitude.innerHTML = (camera.place.geo.longitude.toFixed(MAX_DECIMALS) * -1) + " " + intl.formatMessage({ id: 'cardinal_west' });
            coordinates_longitude.style.margin = POPUP_MARGINS;

            const last_updated = document.createElement("p");
            const date = new Date(camera.place.geo.last_updated * 1000).toLocaleString();
            last_updated.innerHTML = intl.formatMessage({ id: 'last_updated' }) + date;
            last_updated.style.margin = POPUP_MARGINS;

            div.appendChild(link);
            div.appendChild(coordinates_latitude);
            div.appendChild(coordinates_longitude);
            div.appendChild(last_updated);
            marker.bindPopup(div, {closeButton: false});
            camerasGroup.addLayer(marker);
          }
        }
        if (camerasGroup.getLayers().length) {
          camerasGroup.addTo(mapControls);
          if (mapMarkers) {
            mapMarkers.remove();
          } else {
            mapControls.fitBounds(camerasGroup.getBounds());
          }
          setMapMarkers(camerasGroup);
        } else {
          centerOnDeployment();  
        }
      } else {
        centerOnDeployment();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapControls, cameraList, locale, deploymentLocation])

  return <div id="map" className={classes.map}></div>;
};

export default MapComponent;
