export const getStyles = (theme) => ({
  controlsContainer: {
    position: 'absolute',
    display: 'flex',
    height: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    bottom: theme.spacing(1.5),
    left: theme.spacing(1.5),
    padding: '0 0.6rem',
    opacity: 0,
    [theme.breakpoints.down('md')]: {
      bottom: theme.spacing(0.7),
      left: theme.spacing(0.7),
    },
  },
  playerButton: {
    position: 'relative',
    zIndex: 1,
    fontSize: '3vh',
    color: theme.palette.white.main,
  },
  show: {
    transition: 'opacity 1s',
    webkitTransition: 'opacity 1s',
    opacity: 1,
  },
  hide: {
    transition: 'opacity 1s',
    webkitTransition: 'opacity 1s',
    opacity: 0,
  },
});
