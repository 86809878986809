import {tenantPrimaryButton, tenantSecondaryButton} from "../../../utils/tenantStyles";

export const styles = theme => ({
    textFieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            gap: theme.spacing(3),
            alignItems: 'center'
        }
    },
    textFieldBlock: {
        display: 'flex',
        gap: theme.spacing(0, 12),
        width: 'fit-content',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: theme.spacing(3),
            justifyContent: 'center'
        }
    },
    textField: {
        minWidth: '14rem'
    },
    formFooter: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(3)
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(4)
    },
    primaryButton: tenantPrimaryButton(theme),
    secondaryButton: tenantSecondaryButton(theme),
    disabled: {
        backgroundColor: theme.palette.disabled.light + '!important',
        border: 0 + '!important',
        color: theme.palette.disabled.main + '!important'
    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center'
    }
});
