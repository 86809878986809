import React from "react";
import format from "date-fns/format";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { es, enUS } from "date-fns/locale";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import {
  tenantSecondaryButton,
  tenantPrimaryButton
} from "../../utils/tenantStyles";
import {
  GRAY,
  I18N_CONFIG,
  LOCAL_STORAGE__LOCALE
} from "../../utils/constants";
import { getLanguage } from "../../utils/utils";
import ScheduleRoundedIcon from "@material-ui/icons/ScheduleRounded";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(2)
    },
    boxShadow: "4px 4px 20px rgba(38, 37, 37, 0.1)"
  },
  paper: {
    padding: theme.spacing(2),
    width: "100%"
  },
  pickerGroupTitle: {
    display: "flex",
    marginTop: 10,
    [theme.breakpoints.up("lg")]: {
      textAlign: "center"
    }
  },
  pickerGroup: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: -10
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  text: { color: GRAY },
  controlsContainer: {
    height: "100%"
  },
  buttonsFilters: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      flexDirection: "row",
      justifyContent: "center",
      gap: theme.spacing(2)
    }
  },
  statusControls: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(4)
    }
  },
  streamText: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(1)
    }
  },
  durationWarning: {
    padding: theme.spacing(2, 0, 0, 2)
  },
  primaryButton: tenantPrimaryButton(theme),
  secondaryButton: tenantSecondaryButton(theme),
  disabled: {
    backgroundColor: theme.palette.disabled.light + "!important",
    border: 0 + "!important",
    color: theme.palette.disabled.main + "!important"
  }
}));

const FilterPanelComponent = props => {
  const locale = localStorage.getItem(LOCAL_STORAGE__LOCALE) || getLanguage();
  const currentLang = locale === I18N_CONFIG.es.title ? es : enUS;

  const {
    recordingPolicy,
    today,
    videoDateFromState,
    videoDateToState,
    videoTimeFromState,
    videoTimeToState,
    handleSetFilters,
    handleSetVideoDateFromState,
    handleSetVideoDateToState,
    handleSetVideoTimeFromState,
    handleSetVideoTimeToState,
    handleClearFilter,
    allowFilter,
    durationWarning,
    futureTimeWarning,
    recordingPolicyWarning
  } = props;

  const intl = useIntl();
  const classes = useStyles();

  const APPLY_FILTERS = intl.formatMessage({ id: "apply_filters" });
  const CLEAR_FILTERS = intl.formatMessage({ id: "clear_filters" });
  const minDateToFilter = new Date(
    format(today, "T") - recordingPolicy.window * 1000
  );

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item container md={12}>
        <Paper className={classes.paper} elevation={0}>
          <MuiPickersUtilsProvider locale={currentLang} utils={DateFnsUtils}>
            <Grid container justify="center">
              <Grid item container md={12} lg={9}>
                <Grid item container spacing={3}>
                  <Grid item sm={1} lg={1} className={classes.pickerGroupTitle}>
                    <Typography className={classes.text} variant="h6">
                      {intl.formatMessage({ id: "date" })}
                    </Typography>
                  </Grid>
                  <Grid item sm={11} lg={11} className={classes.pickerGroup}>
                    <KeyboardDatePicker
                      disableFuture
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-from"
                      label={intl.formatMessage({ id: "date_from" })}
                      value={videoDateFromState}
                      onChange={date => handleSetVideoDateFromState(date)}
                      minDate={minDateToFilter}
                      maxDate={today}
                      okLabel={intl.formatMessage({ id: "accept" })}
                      cancelLabel={intl.formatMessage({ id: "cancel" })}
                      minDateMessage={intl.formatMessage({
                        id: "min_date_message"
                      })}
                    />
                    <KeyboardDatePicker
                      disableFuture
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-to"
                      label={intl.formatMessage({ id: "date_to" })}
                      value={videoDateToState}
                      onChange={date => handleSetVideoDateToState(date)}
                      minDate={minDateToFilter}
                      maxDate={today}
                      okLabel={intl.formatMessage({ id: "accept" })}
                      cancelLabel={intl.formatMessage({ id: "cancel" })}
                      maxDateMessage={intl.formatMessage({
                        id: "max_date_message"
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={3}>
                  <Grid item sm={1} lg={1} className={classes.pickerGroupTitle}>
                    <Typography className={classes.text} variant="h6">
                      {intl.formatMessage({ id: "time" })}
                    </Typography>
                  </Grid>
                  <Grid item sm={11} lg={11} className={classes.pickerGroup}>
                    <KeyboardTimePicker
                      disableFuture
                      ampm={false}
                      margin="normal"
                      id="time-picker-from"
                      label={intl.formatMessage({ id: "date_from" })}
                      okLabel={intl.formatMessage({ id: "accept" })}
                      cancelLabel={intl.formatMessage({ id: "cancel" })}
                      value={videoTimeFromState}
                      onChange={time => handleSetVideoTimeFromState(time)}
                      keyboardIcon={<ScheduleRoundedIcon />}
                    />
                    <KeyboardTimePicker
                      disableFuture
                      ampm={false}
                      margin="normal"
                      id="time-picker-interval"
                      label={intl.formatMessage({ id: "date_to" })}
                      okLabel={intl.formatMessage({ id: "accept" })}
                      cancelLabel={intl.formatMessage({ id: "cancel" })}
                      value={videoTimeToState}
                      onChange={time => handleSetVideoTimeToState(time)}
                      keyboardIcon={<ScheduleRoundedIcon />}
                    />
                  </Grid>
                </Grid>
                {durationWarning && (
                  <Grid item xs={12} className={classes.durationWarning}>
                    <Typography variant="subtitle2" color="secondary">
                      {intl.formatMessage({ id: "filter_time_warning" })}
                    </Typography>
                  </Grid>
                )}
                {futureTimeWarning && (
                  <Grid item xs={12} className={classes.durationWarning}>
                    <Typography variant="subtitle2" color="secondary">
                      {intl.formatMessage({ id: "filter_time_future_warning" })}
                    </Typography>
                  </Grid>
                )}
                {recordingPolicyWarning && (
                  <Grid item xs={12} className={classes.durationWarning}>
                    <Typography variant="subtitle2" color="secondary">
                      {intl.formatMessage({
                        id: "filter_recording_policy_warning"
                      })}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item sm={12} lg={3} className={classes.buttonsFilters}>
                <Button
                  onClick={handleClearFilter}
                  className={classes.secondaryButton}
                >
                  {CLEAR_FILTERS}
                </Button>
                <Button
                  onClick={handleSetFilters}
                  disabled={!allowFilter}
                  className={classes.primaryButton}
                  classes={{ disabled: classes.disabled }}
                >
                  {APPLY_FILTERS}
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Paper>
      </Grid>
    </Grid>
  );
};

FilterPanelComponent.propTypes = {
  allowFilter: PropTypes.bool.isRequired,
  durationWarning: PropTypes.bool.isRequired,
  futureTimeWarning: PropTypes.bool.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
  handleSetFilters: PropTypes.func.isRequired,
  handleSetVideoDateFromState: PropTypes.func.isRequired,
  handleSetVideoDateToState: PropTypes.func.isRequired,
  handleSetVideoTimeFromState: PropTypes.func.isRequired,
  handleSetVideoTimeToState: PropTypes.func.isRequired,
  recordingPolicy: PropTypes.any,
  recordingPolicyWarning: PropTypes.bool.isRequired,
  today: PropTypes.object.isRequired,
  videoDateFromState: PropTypes.any.isRequired,
  videoDateToState: PropTypes.any.isRequired,
  videoTimeFromState: PropTypes.any.isRequired,
  videoTimeToState: PropTypes.any.isRequired
};

export default FilterPanelComponent;
