import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CameraCardComponent from "../../../common/cameraCardComponent/CameraCardComponent";
import PaginationComponent from "../../../common/PaginationComponent";
import { CardDND } from "../FilteredCamerasComponent/CardDND";
import {
  setCameraReferenceActionCreator,
  setCameraSelectedAction
} from "../../../../actions/commonActions";
import {
  CAMERA_STATUS_OK,
  CAMERAS_PER_PAGE,
  KEEP_ALIVE_TIMEOUT
} from "../../../../utils/constants";
import {
  updateKeepAliveByDeploymentService,
  updateKeepAliveByMosaicService
} from "../../../../services/commonServices";
import OverlayModalComponent from "../../../common/modal/OverlayModalComponent";

const CamerasCardListComponent = ({
  classes,
  deploymentId,
  displayedCameraListProp,
  filters,
  mosaicId,
  camerasToShow,
  displayVideoPlayers,
  live
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [camerasList, setCamerasList] = useState(null);
  const [gridSize, setGridSize] = useState(null);
  const [displayedCameraList, setDisplayedCameraList] = useState(
    displayedCameraListProp
  );
  const cameraSelected = useSelector(state => state.commonState.cameraSelected);

  const setCameraReference = useCallback(
    data => {
      dispatch(setCameraReferenceActionCreator(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (mosaicId) {
      setDisplayedCameraList(camerasToShow);
    } else if (displayedCameraListProp.length > 0) {
      setDisplayedCameraList(displayedCameraListProp);
    } else {
      const camerasLocalStorage = JSON.parse(
        localStorage.getItem(deploymentId)
      );
      if (camerasLocalStorage?.length > 0) {
        setDisplayedCameraList(camerasLocalStorage);
      }
    }
    // eslint-disable-next-line
  }, [displayedCameraListProp]);

  useEffect(() => {
    if (displayedCameraList.length > 0) {
      for (const camera of displayedCameraList) {
        if (camera?.status?.last_status?.toLowerCase() === CAMERA_STATUS_OK) {
          setCameraReference({ cameraReference: camera._id });
        }
      }
    }
  }, [displayedCameraList, setCameraReference]);

  const onPageChanged = data => {
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    setCurrentPage(currentPage);
    setCamerasList(displayedCameraList.slice(offset, offset + pageLimit));
    handleSetGrid(displayedCameraList.slice(offset, offset + pageLimit));
  };

  const loadPagination = useCallback(() => {
    const paginationData = {
      currentPage: 1,
      totalPages: Math.ceil(displayedCameraList.length / CAMERAS_PER_PAGE),
      pageLimit: CAMERAS_PER_PAGE,
      totalRecords: displayedCameraList.length
    };
    const { currentPage, pageLimit } = paginationData;
    const offset = (currentPage - 1) * pageLimit;
    setCurrentPage(currentPage);
    setCamerasList(displayedCameraList.slice(offset, offset + pageLimit));
    handleSetGrid(displayedCameraList.slice(offset, offset + pageLimit));
  }, [displayedCameraList]);

  useEffect(() => {
    loadPagination();
  }, [loadPagination]);

  useEffect(() => {
    deploymentId
      ? updateKeepAliveByDeploymentService(deploymentId)
      : updateKeepAliveByMosaicService(mosaicId);

    let secondsInterval = null;
    secondsInterval = setInterval(() => {
      deploymentId
        ? updateKeepAliveByDeploymentService(deploymentId)
        : updateKeepAliveByMosaicService(mosaicId);
    }, KEEP_ALIVE_TIMEOUT);

    return () => clearInterval(secondsInterval);
  }, [deploymentId, mosaicId]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = camerasList[dragIndex];
      const cameraArray = update(camerasList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      });
      setCamerasList(cameraArray);
      handleSetGrid(cameraArray);
    },
    [camerasList]
  );

  const backendForDND = "ontouchstart" in window ? TouchBackend : HTML5Backend;
  const totalPages = Math.ceil(displayedCameraList?.length / CAMERAS_PER_PAGE);

  const handleSetGrid = cameras => {
    switch (cameras.length) {
      case 1:
        setGridSize(12);
        break;
      case 2:
        setGridSize(6);
        break;
      default:
        setGridSize(4);
        break;
    }
  };

  const handleCloseOverlayModal = useCallback(() => {
    dispatch(setCameraSelectedAction(null));
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={2} className={classes.camerasContainer}>
        <DndProvider backend={backendForDND}>
          {camerasList?.map((camera, index) => (
            <Grid
              item
              sm={12}
              md={camerasList.length > 1 ? 6 : 12}
              lg={gridSize}
              key={camera._id}
            >
              {camerasList.length === 1 ? (
                <CameraCardComponent
                  deploymentId={deploymentId}
                  camera={camera}
                  filters={filters}
                  gridSize={gridSize}
                  camerasList={camerasList}
                  live={live}
                  displayVideoPlayers={displayVideoPlayers}
                />
              ) : (
                <CardDND
                  index={index}
                  id={camera._id}
                  moveCard={moveCard}
                  component={
                    <CameraCardComponent
                      deploymentId={deploymentId}
                      camera={camera}
                      filters={filters}
                      gridSize={gridSize}
                      camerasList={camerasList}
                      live={live}
                      displayVideoPlayers={displayVideoPlayers}
                    />
                  }
                />
              )}
            </Grid>
          ))}
        </DndProvider>
        {displayedCameraList.length > CAMERAS_PER_PAGE && (
          <Grid container item className={classes.buttonGroup}>
            <div className="d-flex flex-row py-4 align-items-center">
              <PaginationComponent
                totalRecords={displayedCameraList.length}
                pageLimit={CAMERAS_PER_PAGE}
                onPageChanged={onPageChanged}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            </div>
          </Grid>
        )}
      </Grid>
      {cameraSelected && (
        <OverlayModalComponent handleClose={handleCloseOverlayModal} />
      )}
    </>
  );
};

CamerasCardListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  deploymentId: PropTypes.any,
  live: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired
};

export default CamerasCardListComponent;
