import React from "react";
import PropTypes from "prop-types";
import style from "./Modal.module.css";
import { BUTTON } from "./button/buttonConstants";
import ButtonComponent from "./button/ButtonComponent";

function ModalComponent({
  body,
  handleClose,
  buttonAcceptText,
  buttonCancelText,
  handleSave = false,
  saveDisabled = false,
  displayCloseButton = true,
  slim = false
}) {
  return (
    <section className={style.over}>
      <div className={`${style.container} ${slim && style.containerSlim}`}>
        {body}
        <div className={style.buttonContainer}>
          {!!buttonAcceptText && !!handleSave && (
            <ButtonComponent
              text={buttonAcceptText}
              variant={BUTTON.VARIANT_PRIMARY}
              onClick={handleSave}
              disabled={saveDisabled}
            />
          )}
          {displayCloseButton && (
            <ButtonComponent
              text={buttonCancelText}
              variant={BUTTON.VARIANT_PRIMARY}
              onClick={handleClose}
            />
          )}
        </div>
      </div>
    </section>
  );
}

ModalComponent.propTypes = {
  body: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func,
  buttonAcceptText: PropTypes.string
};

ModalComponent.defaultProps = {
  handleSave: undefined,
  buttonAcceptText: ""
};

export default ModalComponent;
