import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {getUniqueMosaic} from "../../../services/commonServices";
import CameraMosaicComponent from "./components/cameraMosaicComponent/CameraMosaicComponent"
import {
    fetchDeploymentCameras,
    filterCameraMosaicActionCreator,
    putCameraMosaicActionCreator
} from "../../../actions/commonActions";
import {useKeycloak} from "@react-keycloak/web";

const CameraMosaicContainer = () => {

    const dispatch = useDispatch();
    const {keycloak, initialized} = useKeycloak();
    const {deployment_id, mosaic_id} = useParams();

    const [mosaic, setMosaic] = useState(null);
    const [groupMosaicCamerasToShow, setGroupMosaicCamerasToShow] = useState(0);
    const storedCameraList = JSON.parse(localStorage.getItem(deployment_id));
    const cameraList = useSelector((state) => state.commonState.cameraList[deployment_id]);
    const filteredCameraList = useSelector(state => state.commonState.filteredCameraList);
    const customerId = localStorage.getItem('customer');

    const [camerasToShow, setCamerasToShow] = useState(null);
    const [cameraGroupToShow, setCameraGroupToShow] = useState(null);

    useEffect(() => {
        if (mosaic_id && mosaic) {
            setCameraGroupToShow(mosaic.mosaic_config.camera_lists.map((group) => group.map((cameraId) => mosaic.camera_details.find((camera) => camera._id === cameraId))))
        } else if (!mosaic_id && cameraList) {
            setCameraGroupToShow(cameraList)
        }
    }, [mosaic, cameraList, mosaic_id])

    const filterCameraMosaic = useCallback((cameras) => {
        dispatch(filterCameraMosaicActionCreator({deployment_id, filteredCameraList: cameras}));
    }, [dispatch, deployment_id]);

    useEffect(() => {
        if (!mosaic_id && keycloak && initialized) {
            dispatch(fetchDeploymentCameras(deployment_id));
        } else if (mosaic_id && keycloak && initialized && mosaic) {
            for (const deployment of mosaic.deployment_ids) {
                dispatch(putCameraMosaicActionCreator({
                    deployment_id: deployment,
                    cameraList: mosaic.camera_details.filter(camera => camera.deployment_id === deployment)
                }));
            }
        }
    }, [keycloak, initialized, mosaic, deployment_id, dispatch, mosaic_id]);

    useEffect(() => {
        if (filteredCameraList[deployment_id]?.length > cameraList?.length) {
            filterCameraMosaic(cameraList);
            localStorage.setItem(deployment_id, JSON.stringify(cameraList));
        } else if (!filteredCameraList[deployment_id] && !!storedCameraList?.length) {
            filterCameraMosaic(storedCameraList)
        } else if (!filteredCameraList[deployment_id] && !storedCameraList?.length && cameraList) {
            filterCameraMosaic(cameraList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraList])

    const handleSetMosaic = (response) => {
        setMosaic(response.data);
    }

    useEffect(() => {
        if (mosaic_id && keycloak?.token) {
            getUniqueMosaic(mosaic_id, handleSetMosaic)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mosaic_id, keycloak.token])


    useEffect(() => {
        let secondsInterval = null;
        if (mosaic && mosaic.mosaic_config.camera_lists.length > 1) {
            secondsInterval = setInterval(() => {
                groupMosaicCamerasToShow === mosaic.mosaic_config.camera_lists.length - 1 ?
                    setGroupMosaicCamerasToShow(0) :
                    setGroupMosaicCamerasToShow(groupMosaicCamerasToShow => groupMosaicCamerasToShow + 1)
            }, mosaic?.mosaic_config?.time);
        }
        return () => clearInterval(secondsInterval);
    }, [mosaic, groupMosaicCamerasToShow]);

    useEffect(() => {
        if (mosaic && mosaic_id && cameraGroupToShow) {
            setCamerasToShow(cameraGroupToShow[groupMosaicCamerasToShow])
        }
    }, [groupMosaicCamerasToShow, mosaic, cameraGroupToShow, mosaic_id]);

    useEffect(() => {
        if (!mosaic_id && cameraList) {
            setCamerasToShow(cameraList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraList])

    return (
        <div>
            {camerasToShow?.length > 0 && <CameraMosaicComponent
                mosaicId={mosaic_id}
                deploymentId={deployment_id}
                customerId={customerId}
                camerasToShow={camerasToShow}/>}
        </div>)
}
export default CameraMosaicContainer;