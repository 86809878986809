import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {styles} from './UserIconStyles';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => styles(theme));

export default function UserIconComponent() {

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <PersonIcon className={classes.img}/>
        </div>
    );
}
