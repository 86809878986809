import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import AbstractTable from "../../shared/abstractTable/AbstractTable";
import {GRAY, ROLE_TIIVII_CLIENT_USER} from "../../../utils/constants";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const UsersTableComponent = ({userList, handlePasswordModal, classes, handleRemoveAccessConfirmation, handleDeleteUserConfirmation}) => {
    const intl = useIntl();

    const USER_ID = intl.formatMessage({id: 'user_id'});
    const USER_NAME = intl.formatMessage({id: 'user_username'});
    const USER_DESCRIPTION = intl.formatMessage({id: 'user_description',});
    const USER_ACTIONS = intl.formatMessage({id: 'actions'});
    const USER_ROLE = intl.formatMessage({id: 'user_role'});

    return (
        <AbstractTable
            columns={
                [
                    {
                        title: USER_ID, field: '_id', headerStyle: {
                            width: '15%'
                        },
                        cellStyle: {
                            width: '15%'
                        }
                    },
                    {
                        title: USER_NAME, field: 'credentials.username', headerStyle: {
                            width: '25%'
                        },
                        cellStyle: {
                            width: '25%'
                        }
                    },
                    {
                        title: USER_DESCRIPTION, field: 'description', headerStyle: {
                            width: '40%'
                        },
                        cellStyle: {
                            width: '40%'
                        }
                    },
                    {
                        title: USER_ROLE, field: 'roles', headerStyle: {
                            width: '10%'
                        },
                        cellStyle: {
                            width: '10%'
                        }
                    },
                    {
                        title: USER_ACTIONS,
                        render: rowData => (
                            rowData.roles.find(role => role === ROLE_TIIVII_CLIENT_USER) ?
                                <div className={classes.tableButtonContainer}>
                                    <Tooltip title={intl.formatMessage({id: 'user_delete_access'})}>
                                        <IconButton className={classes.iconButton}
                                                    onClick={() => handleRemoveAccessConfirmation(rowData)}>
                                            <HighlightOffRoundedIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={intl.formatMessage({id: 'user_delete_user'})}>
                                        <IconButton className={classes.iconButton}
                                                    onClick={() => handleDeleteUserConfirmation(rowData)}>
                                            <DeleteForeverRoundedIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={intl.formatMessage({id: 'user_edit_password'})}>
                                        <IconButton className={classes.iconButton}
                                                    onClick={() => handlePasswordModal(rowData)}><VpnKeyRoundedIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div> : null),
                        headerStyle: {
                            width: '10%'
                        },
                        cellStyle: {
                            width: '10%'
                        }
                    }
                ]
            }
            data={userList || []}
            options={{
                actionsColumnIndex: -1,
                showEmptyDataSourceMessage: true,
                doubleHorizontalScroll: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 25, 50],
                search: false,
                toolbar: false,
                headerStyle: {
                    backgroundColor: GRAY,
                    color: 'white',
                    fontWeight: 'bold',
                },
            }}
            style={{boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)'}}
        />
    );
};

UsersTableComponent.propTypes = {
    contractList: PropTypes.array,
};

export default UsersTableComponent;
