import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { styles } from './LoadingComponentStyles';
import spinner from '../../../images/spinner.svg';
import './spinner.css'

const useStyles = makeStyles((theme) => (styles(theme)));
export default function LoadingComponent({ description, open }) {
  const classes = useStyles();
  return (
    <div className={!open ? `${classes.disable} ${classes.root} pageContainer` : `${classes.root} pageContainer` }>
      <div>   
      <img src={spinner} className={`spinner ${classes.spinner}`} alt="logo" />
        {description && <p className={classes.description}><FormattedMessage id="loading_description" /></p>} 
      </div>
    </div>
  );
}

LoadingComponent.propTypes = {
  description: PropTypes.bool.isRequired,
  open: PropTypes.bool,
};
