import React, {useCallback, useEffect, useState} from "react";
import AlertsTableComponent from "../../../common/AlertsTableComponent";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import {
    ALERT_TABLE_ITEMS_PER_PAGE,
    ALERT_TIME_UPDATE,
    GRAY, INITIAL_SECONDS, ROUTE_NAME_MOSAIC,
    TIIVII_API_ALARMS
} from "../../../../utils/constants";
import {getAllAlarmsService} from "../../../../services/commonServices";
import {createFullDate, formatFullDate} from "../../../../utils/utils";
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {buildUrl} from "../../../../utils/axiosRequests";
import {ROUTE_DEPLOYMENT_MOSAIC} from "../../../../utils/routes";
import {useHistory} from 'react-router-dom';
import {
    filterCameraMosaicActionCreator,
    setAlertsActionCreator, setSecondsActionCreator,
    setSelectedAlertActionCreator
} from "../../../../actions/commonActions";
import Switch from "@material-ui/core/Switch";
import format from "date-fns/format";

const useStyles = makeStyles(theme => ({
    tableContainer: {
        width: "100%",
    },
    tableHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
        color: GRAY
    },
    switchContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    switchRoot: {},
    switchBase: {
        '&$checked': {
            color: theme.palette.primary.main,
            '& + $track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 0.7,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: theme.palette.primary.main,
            border: '6px solid #fff',
        },
    },
    switchThumb: {},
    switchTrack: {
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    switchChecked: {},
    switchFocusVisible: {},
    checked: {},
    track: {},
    focusVisible: {},
    thumb: {}
}));

let initialPageSize = ALERT_TABLE_ITEMS_PER_PAGE;

let getDataTable = data => data.map(item => {
    item.camera = item.streamsource_description;
    item.date = formatFullDate(item.timestamp);
    item.deploymentName = item.deployment.description;
    item.description = item.alert_type?.description;
    return item;
});

const AlertsContainer = ({customerId, deploymentId, mosaicId, locale, handleSetLive, live, filters}) => {

    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const [pageSize, setPageSize] = useState(initialPageSize);
    const [isInitPage, setIsInitPage] = useState(true);
    const [loads, setLoads] = useState(5);

    const [displaySwitch, setDisplaySwitch] = useState(false);
    const [displayFilteredAlerts, setDisplayFilteredAlerts] = useState(true);

    const filteredCameraList = useSelector(state => state.commonState.filteredCameraList)[deploymentId];
    const cameraList = useSelector(state => state.commonState.cameraList);
    const selectedAlert = useSelector(state => state.commonState.selectedAlert);

    const [from, setFrom ] = useState(0);
    const [to, setTo ] = useState(0);

    const storeAlerts = data => {
        dispatch(setAlertsActionCreator(data))
    };

    const resetStoredSeconds = () => {
        dispatch(setSecondsActionCreator({seconds: INITIAL_SECONDS}));
    };

    const filterCameraMosaic = useCallback((deployment_id, cameras) => {
        dispatch(filterCameraMosaicActionCreator({deployment_id, filteredCameraList: cameras}));
    }, [dispatch]);

    const handleSwitchChange = () => {
        setDisplayFilteredAlerts(!displayFilteredAlerts)
    };

    const handleSetSelectedAlert = data => {
        dispatch(setSelectedAlertActionCreator({selectedAlert: data}))
    };

    const handlerChangePageSize = (page) => {
        setPageSize(page);
        initialPageSize = page;
    };

    const handlerInitPage = (isInitPage) => setIsInitPage(isInitPage);
    const handlerChangePage = (page) => {
        if (page === 0) {
            handlerInitPage(true)
        }
        if (page > 0 && isInitPage) {
            handlerInitPage(false)
        }
    };

    useEffect(() => {
      if (filters) {
          const {dateFrom, timeFrom, dateTo, timeTo} = filters;
          setFrom(format(createFullDate(dateFrom, +timeFrom * 1000), 't'));
          setTo(format(createFullDate(dateTo, +timeTo * 1000), 't'));
      }

    }, [filters]);

    const remotePaginate = (query) => new Promise((resolve) => {
        let getAlarmsUrl = '';

        if (mosaicId) {
            getAlarmsUrl = `mosaic_id=${mosaicId}`;
        } else if (displayFilteredAlerts && filteredCameraList?.length > 1) {
            let camerasToQuery = filteredCameraList.map(camera => camera._id).toString();
            getAlarmsUrl = `streamsource_ids=${camerasToQuery}`;
        } else if (displayFilteredAlerts && filteredCameraList?.length === 1) {
            getAlarmsUrl = `streamsource_id=${filteredCameraList[0]._id}`;
        } else if (!Array.isArray(deploymentId) && customerId) {
            getAlarmsUrl = deploymentId ? `deployment_id=${deploymentId}` : customerId ?
                `customer_id=${customerId}` : "";
        } else if (!deploymentId) {
            getAlarmsUrl = `customer_id=${customerId}`;
        } else {
            getAlarmsUrl = `deployment_id=${deploymentId}`;
        }

        let url = `${TIIVII_API_ALARMS}?${getAlarmsUrl}`;

        if (!live && filters) {
            url = `${url}&from=${from}&to=${to}&limit=${query.pageSize}&offset=${(query.page) * pageSize}`;
        } else {
            url = url + `&limit=${query.pageSize}&offset=${(query.page) * pageSize}`;
        }

        getAllAlarmsService(url, result => {
            storeAlerts(result.data);
            resolve({
                data: getDataTable(result.data),
                page: query.page,
                totalCount: parseInt(result.headers["pagination-total-results"]),
            })
        })
    });

    const handleGoToAlarm = data => {
        const camera = cameraList[data.deployment._id].find(camera => camera._id === data.streamsource_id);
        filterCameraMosaic(camera.deployment_id, [camera]);
        
        handleSetSelectedAlert(data);
        resetStoredSeconds();
        const url = buildUrl(ROUTE_DEPLOYMENT_MOSAIC, {deployment_id: data.deployment._id});

        if (handleSetLive) {
            handleSetLive(false);
        }

        if (mosaicId || customerId) {
            history.push(url)
        }
    };

    useEffect(() => {
        let secondsInterval = null;
        if (!!live && isInitPage) {
            secondsInterval = setInterval(() => {
                setLoads(new Date().getTime())
            }, ALERT_TIME_UPDATE)
        } else {
            clearInterval(secondsInterval)
        }
        return () => clearInterval(secondsInterval)
    }, [isInitPage, live]);

    useEffect(() => {
        setLoads(new Date().getTime())
    }, [displayFilteredAlerts, filteredCameraList, locale, live, filters, selectedAlert]);

    useEffect(() => {
        setDisplaySwitch(window.location.pathname.split("/")[4] === ROUTE_NAME_MOSAIC)

        return () => setDisplaySwitch(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location]);

    return (
        <div className={classes.tableContainer}>
            <div className={classes.tableHeader}>
                <Typography className={classes.sectionTitle} variant={"h5"}
                            align={"left"}>{intl.formatMessage({id: 'alert_panel'})}
                </Typography>
                {displaySwitch && <div className={classes.switchContainer}>
                    <Typography
                        variant={"caption"}>{intl.formatMessage({id: 'alerts_view_visible_cameras'})}</Typography>
                    <Switch
                        checked={!displayFilteredAlerts}
                        onChange={handleSwitchChange}
                        name="displayAllAlertsSwitch"
                        focusVisibleClassName={classes.focusVisible}
                        disableRipple
                        color={'primary'}
                        disabled={!filteredCameraList}/>
                    <Typography variant={"caption"}>{intl.formatMessage({id: 'alerts_view_all_cameras'})}</Typography>
                </div>}
            </div>
            <AlertsTableComponent data={remotePaginate}
                                  key={loads}
                                  handlerChangePageSize={handlerChangePageSize}
                                  handlerChangePage={handlerChangePage}
                                  pageSize={pageSize} handleGoToAlarm={handleGoToAlarm}/>
        </div>
    )
};

AlertsContainer.propTypes = {
    customerId: PropTypes.string,
    locale: PropTypes.object,
    deploymentId: PropTypes.any
};

export default AlertsContainer;

