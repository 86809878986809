import React from 'react';
import {PropTypes} from 'prop-types';

import {Typography} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {useIntl} from 'react-intl';
import {GRAY} from "../../../../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 180,
        textAlign: 'left',
        boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)',
        borderRadius: 0,
    },
    line: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
    icon: {
        height: 20,
        marginRight: theme.spacing(1),
        color: GRAY
    },
    header: {
        backgroundColor: GRAY,
        color: 'white',
    },
    cardContent: {
        padding: theme.spacing(3, 4),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    text: {
        color: GRAY
    }
}));

export default function ContactCardComponent({contacts}) {
    const classes = useStyles();
    const intl = useIntl();

    const contactTitle = intl.formatMessage({id: 'deployment_contact_list'});

    return (
        <Card className={classes.root}>
            <CardHeader title={contactTitle} className={classes.header}/>
            {contacts.map((contact) => (
                <div key={contact}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.line}>
                            <PersonIcon className={classes.icon}/>
                            <Typography
                                variant="body2"
                                display="inline"
                                className={classes.text}
                            >
                                {contact.name}
                                {' '}
                                /
                                {' '}
                                {contact.description}
                            </Typography>
                        </div>
                        <div className={classes.line}>
                            <EmailIcon className={classes.icon}/>
                            <Typography variant="body2" display="inline"
                                        className={classes.text}>{contact.email}</Typography>
                        </div>
                        <div className={classes.line}>
                            <PhoneIcon className={classes.icon}/>
                            <Typography className={classes.text}
                                        variant="body2" display="inline">{contact.telephone}</Typography>
                        </div>
                    </CardContent>
                </div>
            ))}
        </Card>
    );
}

ContactCardComponent.propTypes = {
    contacts: PropTypes.array,
};
