import {
    buildUrl, deleteRequest, get, patch, post,
} from '../utils/axiosRequests';
import {
    CAMERA_PROXY_BASE_URL,
    SEVERITY_ERROR,
    TIIVII_API_ACTIVATE_CAMERA,
    TIIVII_API_CREATE_CLIP,
    TIIVII_API_CUSTOMER_LIST,
    TIIVII_API_DEPLOYMENT,
    TIIVII_API_DEPLOYMENT_STREAM_SOURCE,
    TIIVII_API_DEPLOYMENT_STREAM_SOURCES,
    TIIVII_API_DESACTIVATE_CAMERA,
    TIIVII_API_DOWNLOAD_CLIP,
    TIIVII_API_LEMBRA_SOURCES,
    TIIVII_API_LIVE_URL,
    TIIVII_API_REC_POLICY,
    TIIVII_API_REC_POLICY_UPDATE,
    TIIVII_API_REC_URL,
    DEFAULT_HTTP_TIMEOUT,
    TIIVII_API_THEME,
    TIIVII_SUPERVISION_API_KEEP_ALIVE_BY_STREAMSOURCE,
    TIIVII_SUPERVISION_API_KEEP_ALIVE_BY_DEPLOYMENT,
    TIIVII_SUPERVISION_API_KEEP_ALIVE_BY_MOSAIC,
    TIIVII_API_MOSAIC_REC_POLICY,
    TIIVII_API_USER_INFO,
    TIIVII_API_USERS_BY_CUSTOMER,
    TIIVII_API_USER,
    TIIVII_API_NEW_USER,
    TIIVII_SUPERVISION_API_GET_MOSAICS,
    TIIVII_SUPERVISION_API_GET_FILTERED_MOSAIC,
    TIIVII_SUPERVISION_API_GET_UNIQUE_MOSAIC,
    TIIVII_SUPERVISION_API_POST_OVERLAY
} from '../utils/constants';
import {showMessage} from '../components/shared/notificationSnack/NotificationSnack';
import {getNavigatorLanguage} from "../utils/localStorge";
import {getOfficialLanguage} from "../utils/utils";

export const loadUserProfile = (keycloak, callback) => {
    keycloak.loadUserProfile()
        .then((profile) => {
            callback(profile.username);
        })
        .catch((error) => showMessage(error, SEVERITY_ERROR));
};

export const getCustomerList = (keycloak, successCustomerList) => {
    const callback = (username) => {
        const url = buildUrl(TIIVII_API_CUSTOMER_LIST, {username});
        get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, true, successCustomerList);
    };
    loadUserProfile(keycloak, callback);
};

export const getContractsList = (url, callback) => {
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, true, callback);
};

export const getAllAlarmsService = (url, callback) => {
    get(url, {}, false, callback, null, {"Accept-Language": getOfficialLanguage(getNavigatorLanguage())});
};

export const downloadImage = (camera, camera_id, setFile) => {
    const url = buildUrl(`${CAMERA_PROXY_BASE_URL}/axis-cgi/jpg/image.cgi`, {camera_id});
    const auth = camera.users.viewer;
    get(url, {
        responseType: 'blob',
        auth: {
            username: auth.user,
            password: auth.pass,
        },
    }, true, (response) => setFile(response.data));
};

export const deploymentStreamSource = (deployment_id, streamsource_id, callback, refresh = false, loading = false) => {
    const url = `${buildUrl(TIIVII_API_DEPLOYMENT_STREAM_SOURCE, {
        deployment_id,
        streamsource_id
    })}?/refresh=${refresh}`;
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, loading, callback);
};

export const deploymentStreamSources = (deployment_id, callback) => {
    const url = buildUrl(TIIVII_API_DEPLOYMENT_STREAM_SOURCES, {deployment_id});
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, true, callback);
};

export const getDeployments = (keycloak, deployment_id, setDeployment) => {
    const callback = () => {
        const url = buildUrl(TIIVII_API_DEPLOYMENT, {deployment_id});
        get(url, {timeout: DEFAULT_HTTP_TIMEOUT},
            true,
            (response) => setDeployment(response.data));
    };
    loadUserProfile(keycloak, callback);
};

export const getLiveUrl = (deployment_id, streamsource_id, callback, callbackError) => {
    const url = buildUrl(TIIVII_API_LIVE_URL, {deployment_id, streamsource_id});
    get(url, {}, false, callback, callbackError);
};

export const getRecUrl = (deployment_id, streamsource_id, callback, callbackError, from, to) => {
    const url = `${buildUrl(TIIVII_API_REC_URL, {deployment_id, streamsource_id})}?From=${from}&To=${to}`;
    get(url, {}, false, callback, callbackError);
};

export const createLembraSource = (deployment_id, streamsource_id, data, callback) => {
    const url = buildUrl(TIIVII_API_LEMBRA_SOURCES, {deployment_id, streamsource_id});
    post(url, data, true, callback);
};

export const deleteLembraSource = (service_contract_id, streamsource_id, data, callback) => {
    const url = buildUrl(TIIVII_API_LEMBRA_SOURCES, {service_contract_id, streamsource_id});
    delete (url, {DEFAULT_HTTP_TIMEOUT}, true, callback);
};

export const updatePolicy = (service_contract_id, streamsource_id, data, callback) => {
    const url = buildUrl(TIIVII_API_REC_POLICY_UPDATE, {service_contract_id, streamsource_id});
    patch(url, data, {timeout: DEFAULT_HTTP_TIMEOUT}, callback);
};

export const getRecordingPolicy = (deployment_id, mosaic_id, callback) => {
    const url = deployment_id ?
        buildUrl(TIIVII_API_REC_POLICY, {deployment_id}) :
        buildUrl(TIIVII_API_MOSAIC_REC_POLICY, {mosaic_id});

    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, true, callback);
};

export const deactivateCameraService = (deployment_id, streamsource_id, callback) => {
    const url = buildUrl(TIIVII_API_DESACTIVATE_CAMERA, {deployment_id, streamsource_id});
    post(url, {timeout: DEFAULT_HTTP_TIMEOUT}, {}, true, callback);
};

export const activateCameraService = (deployment_id, streamsource_id, callback) => {
    const url = buildUrl(TIIVII_API_ACTIVATE_CAMERA, {deployment_id, streamsource_id});
    post(url, {timeout: DEFAULT_HTTP_TIMEOUT}, {}, true, callback);
};

export const createClips = (deployment_id, streamsource_id, data, thenCallback, catchCallback) => {
    const url = buildUrl(TIIVII_API_CREATE_CLIP, {deployment_id, streamsource_id});
    post(url, data, {}, false, thenCallback, catchCallback,
        {'Content-Type': 'application/x-www-form-urlencoded'});
};

export const downloadClip = (deployment_id, streamsource_id, clip_id, callback) => {
    const url = buildUrl(TIIVII_API_DOWNLOAD_CLIP, {deployment_id, streamsource_id, clip_id});
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, false, callback);
};


export const getCustomerStyles = (username, callback) => {
    const url = buildUrl(TIIVII_API_THEME, {username});
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, false, callback)
}

export const updateKeepAliveByStreamService = (deployment_id, streamsource_id) => {
    const url = buildUrl(TIIVII_SUPERVISION_API_KEEP_ALIVE_BY_STREAMSOURCE, {deployment_id, streamsource_id});
    post(url, {}, {}, false);
}

export const updateKeepAliveByDeploymentService = (deployment_id) => {
    const url = buildUrl(TIIVII_SUPERVISION_API_KEEP_ALIVE_BY_DEPLOYMENT, {deployment_id});
    post(url, {}, {}, false);
}

export const updateKeepAliveByMosaicService = (mosaic_id) => {
    const url = buildUrl(TIIVII_SUPERVISION_API_KEEP_ALIVE_BY_MOSAIC, {mosaic_id});
    post(url, {}, {}, false);
}

export const getUserInfo = (username, callback) => {
    const url = buildUrl(TIIVII_API_USER_INFO, {username})
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, false, callback)
}

export const updateUserPassword = (username, body, callback) => {
    const url = buildUrl(TIIVII_API_USER_INFO, {username});
    patch(url, body, {}, true, callback);
};

export const getUserListByCustomer = (customer_id, callback) => {
    const url = buildUrl(TIIVII_API_USERS_BY_CUSTOMER, {customer_id});
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, false, callback)
};

export const removeAccessService = (customer_id, user_id, callback) => {
    const url = buildUrl(TIIVII_API_USER, {customer_id, user_id});
    deleteRequest(url, {DEFAULT_HTTP_TIMEOUT}, true, callback);
};

export const deleteUserService = (username, callback) => {
    const url = buildUrl(TIIVII_API_USER_INFO, {username});
    deleteRequest(url, {timeout: DEFAULT_HTTP_TIMEOUT}, true, callback);
};

export const postNewUserService = (body, thenCallback, catchCallback) => {
    post(TIIVII_API_NEW_USER, body, {}, false, thenCallback, catchCallback)
};

export const postAccessToClientService = (data, customer_id, callback) => {
    const body = `user_id=${data._id}`;
    const url = buildUrl(TIIVII_API_USERS_BY_CUSTOMER, {customer_id})
    post(url, body, {}, false, callback, undefined, {"Content-Type": "application/x-www-form-urlencoded"})
};

export const getMosaics = (callback) => {
    get(TIIVII_SUPERVISION_API_GET_MOSAICS, {timeout: DEFAULT_HTTP_TIMEOUT}, true, callback)
}

export const getFilteredMosaic = (mosaic_id, callback) => {
    const url = buildUrl(TIIVII_SUPERVISION_API_GET_FILTERED_MOSAIC, {mosaic_id});
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, true, callback)
}

export const getUniqueMosaic = (mosaic_id, callback) => {
    const url = buildUrl(TIIVII_SUPERVISION_API_GET_UNIQUE_MOSAIC, {mosaic_id});
    get(url, {}, true, callback)
}

export const postFilteredMosaic = (data, callback) => {
    post(TIIVII_SUPERVISION_API_GET_MOSAICS, data, {timeout: DEFAULT_HTTP_TIMEOUT}, true, callback)
}

export const patchFilteredMosaic = (mosaic_id, data, callback) => {
    const url = buildUrl(TIIVII_SUPERVISION_API_GET_FILTERED_MOSAIC, {mosaic_id});

    patch(url, data, {timeout: DEFAULT_HTTP_TIMEOUT}, true, callback)
}

export const deleteFilteredMosaic = (mosaic_id, callback) => {
    const url = buildUrl(TIIVII_SUPERVISION_API_GET_FILTERED_MOSAIC, {mosaic_id});

    deleteRequest(url, {timeout: DEFAULT_HTTP_TIMEOUT}, true, callback)
}

export const getOverlay = (streamsource_id, callback = undefined, errorCallback = undefined) => {
    const url = buildUrl(TIIVII_SUPERVISION_API_POST_OVERLAY, {streamsource_id});
    const getAction = {
        "action":  "get-current-overlay"
    };
    post(url, getAction, {}, false, callback, errorCallback)
}

export const setOverlay = (streamsource_id, settings, callback, errorCallback = undefined) => {
    const url = buildUrl(TIIVII_SUPERVISION_API_POST_OVERLAY, {streamsource_id});
    const body = {action: "change-overlay", ...settings}
    post(url, body, {}, false, callback, errorCallback)
}

export const removeOverlay = (streamsource_id, callback, errorCallback = undefined) => {
    const url = buildUrl(TIIVII_SUPERVISION_API_POST_OVERLAY, {streamsource_id});
    const removeAction = {
        "action":  "remove-overlay"
    };
    post(url, removeAction, {}, false, callback, errorCallback)
}