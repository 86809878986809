import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AbstractTable from '../../../shared/abstractTable/AbstractTable';
import { buildUrl } from '../../../../utils/axiosRequests';
import { ROUTE_CUSTOMER } from '../../../../utils/routes';

import InstallationsIconComponent from '../../../common/customIcons/installationIconComponent/InstallationsIconComponent';
import { GRAY, ROLES_ADMIN } from '../../../../utils/constants';
import { hasRoles } from '../../../../utils/keycloak';

const CustomerTableComponent = ({ customerList }) => {
    const intl = useIntl();
    const history = useHistory();

    const handleGoToCustomer = (customer) => {
        const url = buildUrl(ROUTE_CUSTOMER, { customer_id: customer });
        history.push(url);
    };

    const handleRowClick = (event, rowData) => {
        handleGoToCustomer(rowData._id);
    };

    const CUSTOMER_ID = intl.formatMessage({ id: 'customer_id' });
    const CUSTOMER_DESCRIPTION = intl.formatMessage({
        id: 'customer_description',
    });
    const CUSTOMER_ACTIONS = intl.formatMessage({ id: 'actions' });
    const isAdmin = hasRoles(ROLES_ADMIN);
    return (
        <AbstractTable
            columns={
                isAdmin
                    ? [
                          { title: CUSTOMER_ID, field: '_id' },
                          { title: CUSTOMER_DESCRIPTION, field: 'description' },
                          {
                              title: CUSTOMER_ACTIONS,
                              render: (rowData) => (
                                  <Button
                                      onClick={() =>
                                          handleGoToCustomer(rowData._id)
                                      }
                                      size="small"
                                      //color="secondary"
                                  >
                                      <InstallationsIconComponent tooltipText="see_installations" />
                                  </Button>
                              ),
                              headerStyle: {
                                  textAlign: 'right',
                              },
                              cellStyle: {
                                  textAlign: 'right',
                                  paddingRight: 30,
                              },
                          },
                      ]
                    : [
                          { title: CUSTOMER_DESCRIPTION, field: 'description' },
                          {
                              title: CUSTOMER_ACTIONS,
                              render: (rowData) => (
                                  <Button
                                      onClick={() =>
                                          handleGoToCustomer(rowData._id)
                                      }
                                      size="small"
                                      //color="secondary"
                                  >
                                      <InstallationsIconComponent tooltipText="see_installations" />
                                  </Button>
                              ),
                              headerStyle: {
                                  textAlign: 'right',
                              },
                              cellStyle: {
                                  textAlign: 'right',
                                  paddingRight: 30,
                              },
                          },
                      ]
            }
            data={customerList}
            options={{
                actionsColumnIndex: -1,
                showEmptyDataSourceMessage: true,
                doubleHorizontalScroll: false,
                pageSize: 10,
                pageSizeOptions: [10, 25, 50],
                search: false,
                toolbar: false,
                cellStyle: {
                    minWidth: 100,
                },
                headerStyle: {
                    backgroundColor: GRAY,
                    color: 'white',
                    minWidth: 100,
                    fontWeight: 'bold',
                },
            }}
            style={{ boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)' }}
            onRowClick={handleRowClick}
        />
    );
};

CustomerTableComponent.propTypes = {
    customerList: PropTypes.array,
};

export default CustomerTableComponent;
