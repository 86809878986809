export const styles = (theme) => ({
  button: {
    borderRadius: '2px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    padding: '0.5rem 1rem',
    width: '100px',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
  },
  
  span: {
    display: 'flex',
  },
  
  primary: {
    backgroundColor: 'transparent',
  },
  
  disabled: {
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[400],
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[400],
    },
  }
});