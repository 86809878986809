import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, IconButton, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import LaunchRoundedIcon from "@material-ui/icons/LaunchRounded";
import FeaturedPlayListOutlinedIcon from "@material-ui/icons/FeaturedPlayListOutlined";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import MuteComponent from "../muteComponent/MuteComponent";
import poster from "../../../images/loadingV2.gif";
import VideoComponent from "../videoComponent/VideoComponent";
import {
  CAMERA_STATUS_ACTIVE,
  CAMERA_STATUS_OK,
  LEMBRA_SOURCE_STATE_RUNNING,
  VIDEO_RATIO_PERCENTAGE
} from "../../../utils/constants";
import AlertContainer from "../alertContainer/AlertContainer";
import {
  ALERT_DIALOG_TYPE_DOWNLOAD,
  ALERT_DIALOG_TYPE_INFO
} from "../alertContainer/AlertContainerConstants";
import { styles } from "./CameraCardStyles";
import { useDispatch, useSelector } from "react-redux";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  filterCameraMosaicActionCreator,
  setCameraSelectedAction
} from "../../../actions/commonActions";
import { buildUrl } from "../../../utils/axiosRequests";
import { ROUTE_DEPLOYMENT_MOSAIC } from "../../../utils/routes";
import {
  deploymentStreamSource,
  getOverlay
} from "../../../services/commonServices";
import PresetDialog from "../presetsComponent/PresetDialog";
import CameraPresetsComponent from "../presetsComponent/CameraPresetsComponent";
import {
  setPtzPresetsValuesActionCreator,
  storeConnectedCameraIdActionCreator
} from "../../../actions/ptzActions";
import {
  checkConnectionPtzService,
  getPresetsPtzService
} from "../../../services/ptzService";
import { onError } from "../../../utils/utils";
import { PRESET_BUTTONS } from "../ptz/PtzConstants";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Switch from "@material-ui/core/Switch";

const CameraCardComponent = ({
  camera,
  filters,
  detail = false,
  displayVideoPlayers,
  gridSize,
  camerasList,
  live
}) => {
  const dispatch = useDispatch();
  const { mosaic_id } = useParams();
  const history = useHistory();
  const deployment = camera.deployment_id;
  const element = useRef(null);
  const intl = useIntl();

  const [videoSize, setVideoSize] = useState(null);
  const cameraList = useSelector(
    state => state.commonState.cameraList[deployment]
  );
  const gamepad = useSelector(state => state.ptzState.gamepad);
  const connectedCamera = useSelector(state => state.ptzState.connectedCamera);
  const useStyles = makeStyles(theme =>
    styles(theme, cameraList && cameraList.length, videoSize)
  );
  const classes = useStyles();

  const [cameraActive, setCameraActive] = useState(null);
  const [
    displayCameraHandlingButton,
    setDisplayCameraHandlingButton
  ] = useState(false);
  const [lembraSourceStatus, setLembraSourceStatus] = useState(null);
  const [displayControls, setDisplayControls] = useState(false);
  const [mute, setMute] = useState(true);
  const [keyboardDevice, setKeyboardDevice] = useState(true);
  const [componentToRender, setComponentToRender] = useState(null);
  const [overlayServiceError, setOverlayServiceError] = useState(false);

  const handle = useFullScreenHandle();

  const storeConnectedCameraId = value => {
    dispatch(storeConnectedCameraIdActionCreator(value));
  };

  const setPtzPresetsValues = data => {
    dispatch(setPtzPresetsValuesActionCreator(data));
  };

  const onConnectionSuccess = response => {
    console.debug("Camera ready.");
    const isEmptyObject =
      Object.keys(response.data).length === 0 &&
      response.data.constructor === Object;
    !isEmptyObject && setDisplayCameraHandlingButton(true);
  };

  const onConnectionError = error => {
    console.error("Unable to connect to the camera. ", error);
    setDisplayCameraHandlingButton(false);
  };

  useEffect(() => {
    camerasList.length === 1 &&
      live &&
      !mosaic_id &&
      checkConnectionPtzService(
        camera._id,
        onConnectionSuccess,
        onConnectionError
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera?._id, camerasList?.length, live]);

  const getOverlayErrorCallback = () => {
    setOverlayServiceError(true);
  };

  useEffect(() => {
    getOverlay(camera._id, undefined, getOverlayErrorCallback);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parsePreset = line => {
    if (!line) {
      return;
    }

    const presetPos = line[0].substring(11) * 1;
    const presetName = line[1];

    return {
      index: presetPos,
      name: presetName,
      button: PRESET_BUTTONS[presetPos - 1]
    };
  };

  const handleSetPresets = response => {
    const presetsObject = response.data;

    if (!presetsObject) {
      return;
    }

    const lines = Object.entries(presetsObject);

    const home = parsePreset(lines[0]); // Home is always the first element.
    const _linesSorted = lines.sort();
    const presets = [];
    for (let i = 0; i < _linesSorted.length; i++) {
      const preset = parsePreset(_linesSorted[i]);
      if (!!preset) {
        presets.push(preset);
      }
    }

    setPtzPresetsValues({ presets, home });
  };

  useEffect(() => {
    !!connectedCamera &&
      live &&
      getPresetsPtzService(camera._id, handleSetPresets, onError);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedCamera, live]);

  const handleResize = () => {
    const width = element.current.clientWidth;
    setVideoSize(width * VIDEO_RATIO_PERCENTAGE);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  useEffect(() => {
    if (
      element.current.clientWidth !== element.current.clientHeight &&
      element.current.clientWidth > element.current.clientHeight
    ) {
      handleResize();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.current]);

  useEffect(() => {
    handleResize();
  }, [gridSize, camerasList]);

  const filterCameraMosaic = useCallback(
    cameras => {
      dispatch(
        filterCameraMosaicActionCreator({
          deployment_id: deployment,
          filteredCameraList: cameras
        })
      );
    },
    [dispatch, deployment]
  );

  const handleFilterCameraMosaic = camera => {
    filterCameraMosaic([camera]);

    if (mosaic_id) {
      history.push(
        buildUrl(ROUTE_DEPLOYMENT_MOSAIC, {
          deployment_id: camera.deployment_id
        })
      );
    }
  };

  const handleCameraActive = useCallback(() => {
    const deploymentStreamSourceSuccess = response => {
      setCameraActive(
        response.data.lembra_source.rec_policy.status === CAMERA_STATUS_ACTIVE
      );
      setLembraSourceStatus(response.data.lembra_source.status);
    };
    deploymentStreamSource(
      deployment,
      camera._id,
      deploymentStreamSourceSuccess,
      false,
      false
    );
  }, [camera?._id, deployment]);

  useEffect(() => {
    if (camera._id !== null) {
      handleCameraActive();
    }
  }, [camera?._id, handleCameraActive, camerasList]);

  const handleComponentSwitch = value => {
    setComponentToRender(value);
  };

  const changeCameraState = () => {
    setCameraActive(!cameraActive);
  };

  const handleMute = () => {
    setMute(!mute);
  };

  const changeComponentToRender = value => {
    setComponentToRender(value);
  };

  const getLightColor = () => {
    if (camera?.status?.last_status?.toLowerCase() === CAMERA_STATUS_OK) {
      if (lembraSourceStatus === LEMBRA_SOURCE_STATE_RUNNING) {
        return classes.iconActivatedColor;
      } else {
        return classes.iconWarningColor;
      }
    } else {
      return classes.iconDeactivatedColor;
    }
  };

  const handleActivateCameraHandling = () => {
    storeConnectedCameraId(connectedCamera === camera._id ? null : camera._id);
  };

  const handleSwitchDevice = () => {
    setKeyboardDevice(keyboardDevice => !keyboardDevice);
  };

  useEffect(() => {
    setKeyboardDevice(!(!!keyboardDevice && !!gamepad));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamepad]);

  useEffect(() => {
    return () => storeConnectedCameraId(null);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDisplayOverlayModal = useCallback(() => {
    dispatch(setCameraSelectedAction(camera._id));
  }, [dispatch, camera._id]);

  return (
    <div className={classes.cardContent} ref={element}>
      <Card className={classes.cardHeader}>
        <div className={classes.cameraButtonsContainer}>
          <Tooltip title={camera.description}>
            <span>
              <Button
                className={classes.secondaryButton}
                disabled={detail}
                variant="contained"
                size="small"
                disableElevation={true}
                startIcon={!detail ? <LaunchRoundedIcon /> : undefined}
                endIcon={
                  <FiberManualRecordRoundedIcon className={getLightColor()} />
                }
                onClick={() => {
                  handleFilterCameraMosaic(camera);
                }}
              >
                <Typography className={classes.ellipsis} variant="button">
                  {camera.description}
                </Typography>
              </Button>
            </span>
          </Tooltip>
          {displayCameraHandlingButton && (
            <div className={classes.cameraHandlingSwitch}>
              <Typography variant={"subtitle2"}>
                {intl.formatMessage({ id: "camera_handling" })}
              </Typography>
              <Switch
                checked={connectedCamera === camera._id}
                onChange={handleActivateCameraHandling}
                name="cameraHandlingSwitch"
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          )}
        </div>
        <div className={classes.cameraStatus}>
          <div>
            <Tooltip
              title={
                overlayServiceError
                  ? intl.formatMessage({
                      id: "video_overlay_modal_tooltip_unavailable"
                    })
                  : connectedCamera === camera._id
                  ? intl.formatMessage({
                      id: "video_overlay_modal_tooltip_disabled"
                    })
                  : intl.formatMessage({ id: "video_overlay_modal_tooltip" })
              }
              className={classes.tooltip}
            >
              <span>
                <IconButton
                  disabled={
                    overlayServiceError || connectedCamera === camera._id
                  }
                  onClick={handleDisplayOverlayModal}
                  classes={{ root: classes.iconButton }}
                >
                  <FeaturedPlayListOutlinedIcon
                    className={
                      overlayServiceError || connectedCamera === camera._id
                        ? `${classes.iconDisabledColor} ${classes.iconExtraPadding}`
                        : `${classes.iconMainColor} ${classes.iconExtraPadding}`
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </div>
          {!mosaic_id && (
            <div>
              <Tooltip
                title={
                  !live
                    ? intl.formatMessage({ id: "download_video" })
                    : intl.formatMessage({ id: "download_not_available" })
                }
                className={classes.tooltip}
              >
                <span>
                  <IconButton
                    disabled={live}
                    onClick={() =>
                      handleComponentSwitch(ALERT_DIALOG_TYPE_DOWNLOAD)
                    }
                    classes={{ root: classes.iconButton }}
                  >
                    <GetAppRoundedIcon
                      className={
                        live ? classes.iconDisabledColor : classes.iconMainColor
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          )}
          <div>
            <Tooltip
              title={intl.formatMessage({ id: "camera_info" })}
              className={classes.tooltip}
            >
              <IconButton
                onClick={() => handleComponentSwitch(ALERT_DIALOG_TYPE_INFO)}
                classes={{ root: classes.iconButton }}
              >
                <InfoRoundedIcon className={classes.iconMainColor} />
              </IconButton>
            </Tooltip>
          </div>
          {!mosaic_id && (
            <div>
              <Tooltip
                title={intl.formatMessage({ id: "mosaic" })}
                className={classes.tooltip}
              >
                <IconButton
                  onClick={handle.enter}
                  classes={{ root: classes.iconButton }}
                >
                  <FullscreenIcon className={classes.iconMainColor} />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </Card>
      {camerasList.length === 1 && connectedCamera === camera._id && (
        <Accordion className={classes.presetsCard}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <FormattedMessage id="camera_presets_header_text" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <CameraPresetsComponent
                  cameraId={camera?._id}
                  keyboardDevice={keyboardDevice}
                />
              </Grid>
              <Grid item xs={12} md={2} className={classes.presetsRightBlock}>
                <PresetDialog
                  camera={camera}
                  handleSetPresets={handleSetPresets}
                  keyboardDevice={keyboardDevice}
                />
                {gamepad && (
                  <div className={classes.switchContainer}>
                    <Typography variant={"subtitle2"}>
                      {intl.formatMessage({ id: "device_gamepad" })}
                    </Typography>
                    <Switch
                      checked={keyboardDevice}
                      onChange={handleSwitchDevice}
                      name="checkedA"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <Typography variant={"subtitle2"}>
                      {intl.formatMessage({ id: "device_keyboard" })}
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      <Card
        onMouseEnter={() => setDisplayControls(true)}
        onMouseLeave={() => setDisplayControls(false)}
        elevation={0}
        className={classes.videoCardMosaic}
      >
        <MuteComponent
          mute={mute}
          handleMute={() => handleMute()}
          camera={camera._id}
          displayControls={displayControls}
        />
        <FullScreen handle={handle} className={classes.fullScreenComponent}>
          {deployment && (
            <VideoComponent
              mute={mute}
              poster={poster}
              filters={filters}
              live={live}
              deployment_id={deployment}
              camera={camera}
              detail={detail}
              camerasList={camerasList}
              mosaicId={mosaic_id}
              videoSize={videoSize}
              gridSize={gridSize}
              displayVideoPlayers={displayVideoPlayers}
              fullScreen={handle.active}
            />
          )}
        </FullScreen>
      </Card>
      <AlertContainer
        componentToRender={componentToRender}
        changeComponentToRender={changeComponentToRender}
        filters={filters}
        camera={camera}
        cameraActive={cameraActive}
        changeCameraState={changeCameraState}
      />
      <div></div>
    </div>
  );
};

CameraCardComponent.propTypes = {
  camera: PropTypes.object.isRequired,
  live: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  detail: PropTypes.bool
};

export default CameraCardComponent;
