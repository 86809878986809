import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import {CAMERAS_PER_PAGE, DISABLED} from "../../utils/constants";

const styles = theme => ({
    paginationButton: {
        border: `1px solid ${theme.palette.primary.main} !important`,
        backgroundColor: theme.palette.primary.main + '!important',
        color: 'white !important',
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main} !important`,
            backgroundColor: 'white !important',
            color: theme.palette.primary.main + '!important'
        }
    },
    disabled: {
        backgroundColor: DISABLED
    }
});

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

class PaginationComponent extends Component {
    constructor(props) {
        super(props);
        const {totalRecords = null, pageLimit = CAMERAS_PER_PAGE} = props;

        this.pageLimit = typeof pageLimit === 'number' ? pageLimit : CAMERAS_PER_PAGE;
        this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;
    }

    fetchPageNumbers = () => {
        const {totalPages} = this.props;
        return range(1, totalPages);
    }

    componentDidMount() {
        this.gotoPage(1);
    }

    gotoPage = page => {
        const {onPageChanged, totalPages} = this.props;
        const currentPage = Math.max(0, Math.min(page, totalPages));
        const paginationData = {
            currentPage,
            totalPages,
            pageLimit: this.pageLimit,
            totalRecords: this.totalRecords
        };
        onPageChanged(paginationData)
    }

    handleClick = page => {
        this.gotoPage(page);
    }

    render() {
        const {currentPage, pageLimit, totalRecords, totalPages, classes} = this.props;
        const pages = this.fetchPageNumbers();

        if (!totalRecords || totalPages === 1) return null;

        const pageInterval = (page) => {
            const finalRecord = page * pageLimit;
            const firstRecordInCurrentPage = finalRecord - (pageLimit - 1);
            const finalRecordControled = totalRecords < finalRecord ? totalRecords : finalRecord

            return firstRecordInCurrentPage !== finalRecordControled ?
                `${firstRecordInCurrentPage} - ${finalRecordControled}` :
                firstRecordInCurrentPage;
        }

        return (
            <ButtonGroup variant="contained" color="primary">
                {pages && pages.map((page, index) => {
                    return (
                        <Button className={currentPage === page ? classes.disabled : classes.paginationButton}
                                key={index} disabled={currentPage === page}
                                onClick={() => this.handleClick(page)}>
                            {pageInterval(page)}
                        </Button>
                    );
                })}
            </ButtonGroup>

        );
    }
}

PaginationComponent.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired
};

export default withStyles(styles)(PaginationComponent);

