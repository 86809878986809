import React from 'react';
import {PropTypes} from 'prop-types';

import {Typography, CardHeader, CardContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AddressIcon from '@material-ui/icons/House';
import PhoneIcon from '@material-ui/icons/Phone';
import {useIntl} from 'react-intl';
import Card from '@material-ui/core/Card';
import {GRAY} from "../../../../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 180,
        textAlign: 'left',
        boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)',
        borderRadius: 0,
    },
    address: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    line: {
        display: 'flex',
        flexFlow: 'no-wrap row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    icon: {
        height: 20,
        color: GRAY,
        marginRight: theme.spacing(1)
    },
    description: {
        marginBottom: theme.spacing(2),
        color: GRAY
    },
    header: {
        backgroundColor: GRAY,
        color: 'white',
    },
    cardContent: {
        padding: theme.spacing(3, 4)
    },
    text: {
        color: GRAY
    }
}));

export default function AddressCardComponent({address}) {
    const classes = useStyles();
    const intl = useIntl();

    const addressTitle = intl.formatMessage({id: 'deployment_address'});

    return (
        <Card className={classes.root}>
            <CardHeader title={addressTitle} className={classes.header}/>
            <CardContent className={classes.cardContent}>
                <div className={classes.line}>
                    <Typography variant="body1" className={classes.description}>
                        {address.name}
                    </Typography>
                </div>
                <div className={classes.address}>
                    <div className={classes.line}>
                        <AddressIcon className={classes.icon}/>
                        <Typography className={classes.text} variant="body2"
                                    display="inline">{address.address}</Typography>
                    </div>
                    {address.telephone && (
                        <div className={classes.line}>
                            <PhoneIcon className={classes.icon}/>
                            <Typography className={classes.text} variant="body2"
                                        display="inline">{address.telephone}</Typography>
                        </div>)}
                </div>
            </CardContent>
        </Card>
    );
}

AddressCardComponent.propTypes = {
    address: PropTypes.object.isRequired,
};
