export const BUTTON = {
  /*Textos */
  DEFAULT_TEXT: "Texto del boton",
  DELETE: "common__buttons__delete_advise",
  CLOSE: "parcels_list__close_map_legend",
  /*Tipos */
  TYPE_BUTTON: "button",
  TYPE_SUBMIT: "submit",
  TYPE_RESET: "reset",
  /*Colors */
  VARIANT_PRIMARY: "primary",
};
