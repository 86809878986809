import React from "react";
import PropTypes from 'prop-types';
import AbstractTable from "../shared/abstractTable/AbstractTable";
import {makeStyles} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import {GRAY} from "../../utils/constants";
import arrowIcon from "../../images/FlechaAbajo.svg"

const useStyles = makeStyles(theme => ({
    alertTable: {
        margin: "0 auto",
        boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)',
    },
    iconContainer: {
        width: 34,
        height: 34,
        border: '1px solid black',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    arrowIcon: {
        transform: 'rotate(225deg)'
    }
}));

const AlertsTableComponent = ({data, handlerChangePage, handlerChangePageSize, pageSize, handleGoToAlarm}) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div className={classes.alertTable}>
            <AbstractTable
                columns={[
                    {
                        width: '20%',
                        render: () => <div className={classes.iconContainer}>
                            <img src={arrowIcon} alt={"Icon"} className={classes.arrowIcon}/>
                        </div>,
                        headerStyle: {
                            minWidth: 60,
                        },
                        cellStyle: {
                            minWidth: 60,
                            textAlign: 'center'
                        },
                    },
                    {
                        title: intl.formatMessage({id: "alert_column_date"}),
                        field: "date"
                    },
                    {title: intl.formatMessage({id: "alert_column_camera"}), field: "camera"},
                    {title: intl.formatMessage({id: "alert_column_installation"}), field: "deploymentName"},
                    {title: intl.formatMessage({id: "alert_column_description"}), field: "description"}
                ]}
                onChangePage={data => handlerChangePage(data)}
                onChangeRowsPerPage={data => handlerChangePageSize(data)}
                data={data}
                options={{
                    actionsColumnIndex: -1,
                    showEmptyDataSourceMessage: true,
                    doubleHorizontalScroll: false,
                    pageSize: pageSize,
                    pageSizeOptions: [5, 10, 25, 50],
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: GRAY,
                        color: "white",
                        fontWeight: 'bold'
                    }
                }}
                style={{boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)'}}
                onRowClick={(event, rowData) => handleGoToAlarm(rowData)}
            />
        </div>
    );
};

AlertsTableComponent.propTypes = {
    data: PropTypes.func.isRequired,
    handlerChangePage: PropTypes.func.isRequired,
    handlerChangePageSize: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired
};

export default AlertsTableComponent;
