import {buildUrl, deleteRequest, get, patch, post} from "../utils/axiosRequests";
import {
    DEFAULT_HTTP_TIMEOUT,
    PTZ_CONTROLLER_CENTER,
    PTZ_CONTROLLER_CHECK_CONNECTION,
    PTZ_CONTROLLER_MOVE,
    PTZ_CONTROLLER_ZOOM,
    PTZ_CONTROLLER_ABSOLUTE,
    PTZ_CONTROLLER_GO_TO_PRESET,
    PTZ_CONTROLLER_CURRENT_PRESET,
    PTZ_CONTROLLER_PRESETS,
    PTZ_CONTROLLER_SET_PRESET,
    PTZ_CONTROLLER_UPDATE_PRESET,
    PTZ_CONTROLLER_DELETE_PRESET,
    PTZ_MJPEG_URL
} from "../utils/constants";

export const checkConnectionPtzService = (streamsource_id, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_CHECK_CONNECTION, {streamsource_id});
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, false, thenCallback, catchCallback);
};

export const getLiveMjpeg = async (streamsource_id, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_MJPEG_URL, {streamsource_id});
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, true, thenCallback, catchCallback);
};

/**
 * PTZ HANDLING
 **/

export const panTiltPtzService = (streamsource_id, data, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_MOVE, {streamsource_id});
    post(url, data, {timeout: DEFAULT_HTTP_TIMEOUT}, false, thenCallback, catchCallback);
};

export const zoomPtzService = (streamsource_id, data, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_ZOOM, {streamsource_id});
    post(url, data, {timeout: DEFAULT_HTTP_TIMEOUT}, false, thenCallback, catchCallback);
};

export const centerPtzService = (streamsource_id, data, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_CENTER, {streamsource_id});
    post(url, data, {timeout: DEFAULT_HTTP_TIMEOUT}, false, thenCallback, catchCallback);
};

export const positionPtzService = (streamsource_id, data, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_ABSOLUTE, {streamsource_id});
    post(url, data, {timeout: DEFAULT_HTTP_TIMEOUT}, false, thenCallback, catchCallback);
};


/**
 * PRESETS
 **/

export const getPresetsPtzService = (streamsource_id, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_PRESETS, {streamsource_id});
    get(url, {/*timeout: DEFAULT_HTTP_TIMEOUT*/}, false, thenCallback, catchCallback);
};

export const getCurrentPresetPtzService = (streamsource_id, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_CURRENT_PRESET, {streamsource_id});
    get(url, {timeout: DEFAULT_HTTP_TIMEOUT}, false, thenCallback, catchCallback);
};

export const goToPresetPtzService = (streamsource_id, data, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_GO_TO_PRESET, {streamsource_id});
    post(url, data, {timeout: DEFAULT_HTTP_TIMEOUT}, false, thenCallback, catchCallback);
};

export const savePresetPtzService = (streamsource_id, preset, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_SET_PRESET, {streamsource_id, preset});
    post(url, {}, {timeout: DEFAULT_HTTP_TIMEOUT}, false, thenCallback, catchCallback);
};

export const deletePresetPtzService = (streamsource_id, preset, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_DELETE_PRESET, {streamsource_id, preset});
    deleteRequest(url, {timeout: DEFAULT_HTTP_TIMEOUT}, false, thenCallback, catchCallback);
};

export const editPresetPtzService = (streamsource_id, data, preset, thenCallback, catchCallback) => {
    const url = buildUrl(PTZ_CONTROLLER_UPDATE_PRESET, {streamsource_id, preset});
    patch(url, data, {timeout: DEFAULT_HTTP_TIMEOUT}, false, thenCallback, catchCallback);
};
