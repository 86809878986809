import React from 'react';
import {useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {Hidden, Typography} from '@material-ui/core';
import {styles} from './FooterStyles';
import {useSelector} from "react-redux";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => styles(theme));

export function FooterComponent() {

    const intl = useIntl();
    const classes = useStyles();

    const customerStyles = useSelector(state => state.commonState.customerStyles);

    return (
        <footer className={classes.footer}>
            <Typography variant={"caption"}
                        className={classes.copyright}>{customerStyles?.footer_data?.copyright_name}</Typography>
            <div className={classes.linksFooter}>
                <Link className={classes.link} href={customerStyles?.footer_data?.privacy_policy} target="_blank"
                      rel="noopener noreferrer">
                    {intl.formatMessage({id: 'footer_policy'})}
                </Link>
                <Hidden smDown>
                    <p className={classes.separation}>|</p>
                </Hidden>
                <Link className={classes.link} href={customerStyles?.footer_data?.use_terms} target="_blank"
                      rel="noopener noreferrer">
                    {intl.formatMessage({id: 'footer_contact'})}
                </Link>
            </div>
        </footer>
    );
};