import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AbstractTable from '../../../shared/abstractTable/AbstractTable';
import { buildUrl } from '../../../../utils/axiosRequests';
import { ROUTE_DEPLOYMENT } from '../../../../utils/routes';
import { GRAY, ROLES_ADMIN } from '../../../../utils/constants';
import CameraIconComponent from '../../../common/customIcons/cameraIconComponent/CameraIconComponent';
import { hasRoles } from '../../../../utils/keycloak';

const ContractTable = ({ contractList }) => {
    const intl = useIntl();
    const history = useHistory();

    const handleGoToDeployment = (deploymentId) => {
        const url = buildUrl(ROUTE_DEPLOYMENT, { deployment_id: deploymentId });
        history.push(url);
    };

    const handleRowClick = (event, rowData) => {
        handleGoToDeployment(rowData.deployment_id);
    };
    const isAdmin = hasRoles(ROLES_ADMIN);

    const INSTALLATION_ID = intl.formatMessage({ id: 'installation_id' });
    const INSTALLATION_NAME = intl.formatMessage({ id: 'installation_name' });
    const INSTALLATION_DESCRIPTION = intl.formatMessage({
        id: 'installation_description',
    });
    const INSTALLATION_ACTIONS = intl.formatMessage({ id: 'actions' });

    return (
        <AbstractTable
            columns={
                isAdmin
                    ? [
                          { title: INSTALLATION_ID, field: '_id' },
                          { title: INSTALLATION_NAME, field: 'name' },
                          {
                              title: INSTALLATION_DESCRIPTION,
                              field: 'description',
                          },
                          {
                              title: INSTALLATION_ACTIONS,
                              render: (rowData) => (
                                  <Button
                                      onClick={() =>
                                          handleGoToDeployment(
                                              rowData.deployment_id
                                          )
                                      }
                                      size="small"
                                      color="secondary"
                                  >
                                      <CameraIconComponent tooltipText="see_cameras" />
                                  </Button>
                              ),
                              headerStyle: {
                                  textAlign: 'right',
                              },
                              cellStyle: {
                                  textAlign: 'right',
                              },
                              sorting: false,
                          },
                      ]
                    : [
                          { title: INSTALLATION_NAME, field: 'name' },
                          {
                              title: INSTALLATION_DESCRIPTION,
                              field: 'description',
                          },
                          {
                              title: INSTALLATION_ACTIONS,
                              render: (rowData) => (
                                  <Button
                                      onClick={() =>
                                          handleGoToDeployment(
                                              rowData.deployment_id
                                          )
                                      }
                                      size="small"
                                      color="secondary"
                                  >
                                      <CameraIconComponent tooltipText="see_cameras" />
                                  </Button>
                              ),
                              headerStyle: {
                                  textAlign: 'right',
                              },
                              cellStyle: {
                                  textAlign: 'right',
                              },
                              sorting: false,
                          },
                      ]
            }
            data={contractList}
            options={{
                actionsColumnIndex: -1,
                showEmptyDataSourceMessage: true,
                doubleHorizontalScroll: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 25, 50],
                search: false,
                toolbar: false,
                cellStyle: {
                    minWidth: 120,
                },
                headerStyle: {
                    backgroundColor: GRAY,
                    color: 'white',
                    minWidth: 120,
                    fontWeight: 'bold',
                },
            }}
            style={{ boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)' }}
            onRowClick={handleRowClick}
        />
    );
};

ContractTable.propTypes = {
    contractList: PropTypes.array,
};

export default ContractTable;
