import {
    CHANGE_CAMERA_DEFAULT_SPEED_ACTION,
    CHANGE_CAMERA_SECONDARY_SPEED_ACTION,
    CHANGE_DEAD_ZONE_ACTION,
    RESET_PTZ_VALUES,
    LOAD_PTZ_PRESETS_VALUES_ACTION,
    GAMEPAD_CONNECTED_ACTION, STORE_VIDEO_DIMENSIONS, STORE_CONNECTED_CAMERA_ID
} from "./actionTypes";

export const storeConnectedCameraIdActionCreator = (data) => ({
    type: STORE_CONNECTED_CAMERA_ID,
    data
});

export const setPtzPresetsValuesActionCreator = (data) => ({
    type: LOAD_PTZ_PRESETS_VALUES_ACTION,
    data
});

export const resetPtzPresetsValues = () => ({
    type: RESET_PTZ_VALUES
});

export const changeCameraSecondarySpeedValue = (data) => ({
    type: CHANGE_CAMERA_SECONDARY_SPEED_ACTION,
    data
});

export const changeCameraDefaultSpeedValue = (data) => ({
    type: CHANGE_CAMERA_DEFAULT_SPEED_ACTION,
    data
});

export const changeDeadZoneValue = (data) => ({
    type: CHANGE_DEAD_ZONE_ACTION,
    data
});

export const storeConnectedGamepadActionCreator = (data) => ({
    type: GAMEPAD_CONNECTED_ACTION,
    data
});

export const storeVideoDimensionsActionCreator = (data) => ({
    type: STORE_VIDEO_DIMENSIONS,
    data
});