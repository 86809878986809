import React from "react";
import './FullScreenMosaic.css'
import VideoCellComponent from "./components/VideoCellComponent";

function FullScreenMosaic({arrayOfCameras, filters, live, poster, mosaicId, fullScreen}) {

    const gridClasses = (
        arrayOfCameras.length < 3 && arrayOfCameras.length > 1 ? "gridTwo" :
            arrayOfCameras.length > 2 && arrayOfCameras.length < 5 ? "gridThreeFour" :
                arrayOfCameras.length > 4 && arrayOfCameras.length < 7 ? "gridFiveSix" : "gridOne"
    )

    return (
        <div className='flex'>
            <div className={`flex ${gridClasses}`}>
                {arrayOfCameras.map((item, index) =>
                    <VideoCellComponent
                        key={item._id}
                        mute={true}
                        poster={poster}
                        filters={filters}
                        live={live}
                        deployment_id={item.deployment_id}
                        camera={item}
                        detail={false}
                        index={index}
                        camerasList={arrayOfCameras}
                        mosaicId={mosaicId}
                        fullScreen={fullScreen}
                    />)}
            </div>
        </div>
    )

}

export default FullScreenMosaic;