import React, {useCallback, useEffect, useRef, useState} from "react";
import {IconButton, makeStyles} from "@material-ui/core";
import {
    formatPanTiltValue,
    generateSvgLine,
    generateSvgPolyline,
    onError,
    transformPanTiltZoom,
} from "../../../utils/utils";
import {useSelector} from "react-redux";
import {DEFAULT_VALUE_DEAD_ZONE, MAX_CAMERA_SPEED, PTZ_STOP, REQUEST_HYSTERESIS, REQUEST_TYPE_PAN_TILT} from "../ptz/PtzConstants";
import {panTiltPtzService, zoomPtzService} from "../../../services/ptzService";
import {
    INITIAL_MATRIX_POINTS,
    SVG_ARROW_POINTS,
    SVG_LINE_SIZE,
    SVG_RADIUS
} from "./MouseControlConstants";
import {getCancelToken} from "../../../utils/axiosRequests";
import {MAX_PENDING_REQUESTS} from "../../../utils/constants";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

const useStyles = makeStyles((theme) => ({
    svgContainer: {
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    svg: {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 1,
    },
    circleGroup: {
        stroke: 'white',
        strokeWidth: 2,
        cursor: "pointer",
        '& circle': {
            fillOpacity: 0,
            "&:hover": {
                fillOpacity: 0.5,
                fill: "rgba(0, 0, 0, 0.5)",
            }
        }
    },
    iconButton: {
        position: 'absolute',
        left: '50%',
        bottom: '10px',
        zIndex: 2,
        '&:hover': {
            backgroundColor: "transparent",
            borderRadius: '50%'
        }
    },
    iconButtonRight: {
        transform: 'translate(0%)',
    },
    iconButtonLeft: {
        transform: 'translate(-95%)',
    },
    iconMainColor: {
        color: theme.palette.white.main,
        opacity: 1,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: '50%'
        }
    },
    iconDisabledColor: {
        color: theme.palette.white.main,
        opacity: 0.6,
    },
}));

const ZoomController = {
    petitionsSent: 0,
    petitionsStopped: 0,
    zoomSent: function() {
        this.petitionsSent++;
    },
    zoomArrived: function() {
        this.petitionsSent--;
        this.petitionsStopped++;
    },
    zoomStopped: function() {
        if (this.petitionsStopped > 0) {
            this.petitionsStopped--;
        }
    },
    areAllPetitionsStopped: function() {
        return this.petitionsStopped === 0 && this.petitionsSent === 0;
    }
}

const MouseControlComponent = ({cameraId, fullScreen, enableCenterRequest, disableCenterRequest}) => {
    const classes = useStyles();
    const frameRef = useRef();
    const groupRef = useRef();

    const storedWidth = useSelector(state => state.ptzState.videoDimensions.width);
    const storedHeight = useSelector(state => state.ptzState.videoDimensions.height);

    let currentRequests = [];
    let lastRequestTimestamp = new Date().getTime();

    const [width, setWidth] = useState(storedWidth);
    const [height, setHeight] = useState(storedHeight);
    const [centerX, setCenterX] = useState(storedWidth / 2);
    const [centerY, setCenterY] = useState(storedHeight / 2);

    const [arrowLine, setArrowLine] = useState(null);
    const [arrow, setArrow] = useState(null);

    const [xLine, setXLine] = useState(null);
    const [yLine, setYLine] = useState(null);

    const [zoom, setZoom] = useState(0);
    const zoomOpSuccessRef = useRef(Object.create(ZoomController));
    const [panTiltEnabled, setPanTiltEnabled] = useState(true);
    const panTiltEnabledRef = useRef(panTiltEnabled);
    const [isDragging, setIsDragging] = useState(false);
    const isDraggingRef = useRef(isDragging);

    const [windowFocus, setWindowFocus] = useState(true);

    const availableRequests = () => currentRequests.length < MAX_PENDING_REQUESTS;

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener('blur', onBlur);
        window.addEventListener('resize', setScreenValues);

        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener('blur', onBlur);
            window.removeEventListener('resize', setScreenValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onFocus = () => {
        setScreenValues();
        setWindowFocus(true);
    };

    const onBlur = () => {
        setScreenValues();
        setWindowFocus(false);
    };

    const setScreenValues = () => {
        setWidth(frameRef.current.offsetWidth);
        setHeight(frameRef.current.offsetHeight);
        setCenterX(frameRef.current.offsetWidth / 2);
        setCenterY(frameRef.current.offsetHeight / 2);
    };

    useEffect(() => {
        setScreenValues();
    }, [fullScreen, storedHeight, storedWidth]);

    const onMouseMove = useCallback((event) => {
        if (!panTiltEnabledRef.current) {
            return;
        }

        const layerX = event.layerX ?? event.nativeEvent.layerX;
        const layerY = event.layerY ?? event.nativeEvent.layerY;

        arrowLine.setAttribute('x2', layerX);
        arrowLine.setAttribute('y2', layerY);

        const xOrigin = arrowLine.getAttribute('x1');
        const yOrigin = arrowLine.getAttribute('y1');

        const center = {x: xOrigin, y: yOrigin};
        const cursor = {x: layerX, y: layerY};

        const angle = Math.atan2(cursor.y - center.y, cursor.x - center.x) + (Math.PI / 2);

        const point1 = Math.cos(angle).toFixed(4);
        const point2 = Math.sin(angle).toFixed(4);
        const point3 = -Math.sin(angle).toFixed(4);
        const point4 = Math.cos(angle).toFixed(4);

        const matrix = `matrix(${point1},${point2},${point3},${point4},${cursor.x},${cursor.y})`;

        arrow.setAttribute("transform", matrix);

        const parameters = {
            pan: formatPanTiltValue(layerX - xOrigin),
            tilt: formatPanTiltValue(layerY - yOrigin) * -1
        };

        if (!!windowFocus && (!availableRequests() || (parameters.pan < DEFAULT_VALUE_DEAD_ZONE && parameters.tilt < DEFAULT_VALUE_DEAD_ZONE))) {
            console.debug("cancelling all requests");
            cancelRequests();
        }

        !!windowFocus && addRequest(REQUEST_TYPE_PAN_TILT, parameters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panTiltEnabled, arrowLine])

    useEffect(() => {
        if (groupRef.current && !!width && !!height && groupRef.current.contains(xLine) && groupRef.current.contains(yLine)) {
            groupRef.current.removeChild(xLine);
            groupRef.current.removeChild(yLine);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, groupRef.current])

    useEffect(() => {
        if (groupRef.current && xLine && yLine) {
            groupRef.current.appendChild(xLine);
            groupRef.current.appendChild(yLine);
        }
    }, [groupRef, xLine, yLine]);

    useEffect(() => {
        if (frameRef.current && width && height && centerX && centerY) {
            if (arrowLine) {
                arrowLine.removeLine();
            }
            setArrowLine(generateSvgLine(centerX, centerX, centerY, centerY, null, null, null, 'arrowLineId'));

            const transform = `matrix(${INITIAL_MATRIX_POINTS}, ${centerX}, ${centerY})`;
            if (arrow) {
                arrow.removePolyline();
            }
            setArrow(generateSvgPolyline(SVG_ARROW_POINTS, transform, 'arrowId'));

            if (!!width && !!height) {
                yLine?.removeLine();
                xLine?.removeLine();
                setYLine(generateSvgLine(centerX - SVG_LINE_SIZE, centerX + SVG_LINE_SIZE, centerY, centerY, centerX, centerY, SVG_RADIUS, 'yLineId'));
                setXLine(generateSvgLine(centerX, centerX, centerY - SVG_LINE_SIZE, centerY + SVG_LINE_SIZE, centerX, centerY, SVG_RADIUS, 'xLineId'));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frameRef, width, height, centerX, centerY])

    useEffect(() => {
        frameRef.current && frameRef.current.removeEventListener("mousemove", onMouseMove);
    }, [fullScreen, onMouseMove])

    const resetArrowPosition = () => {
        arrowLine.setAttribute('x2', centerX);
        arrowLine.setAttribute('y2', centerY);

        const points = "0,0,5,5,-5,5";
        const transform = `matrix(1,0,0,1, ${centerX}, ${centerY})`;

        arrow.setAttribute('points', points);
        arrow.setAttribute('transform', transform);
    };

    const handleMoveCamera = (event) => {
        setIsDragging(true);
        isDraggingRef.current = true;
        setScreenValues();
        resetArrowPosition();
        frameRef.current && frameRef.current.addEventListener("mousemove", onMouseMove);

        groupRef.current.removeChild(xLine);
        groupRef.current.removeChild(yLine);

        groupRef.current.appendChild(arrowLine);
        groupRef.current.appendChild(arrow);
    };

    const handleStopCamera = () => {
        setIsDragging(false);
        isDraggingRef.current = false;
        frameRef.current && frameRef.current.removeEventListener("mousemove", onMouseMove);

        groupRef.current.appendChild(xLine);
        groupRef.current.appendChild(yLine);

        !!groupRef.current?.contains(arrowLine) && groupRef.current.removeChild(arrowLine);
        !!groupRef.current?.contains(arrow) && groupRef.current.removeChild(arrow);

        const parameters = {pan: PTZ_STOP, tilt: PTZ_STOP};
        panTiltPtzService(cameraId, parameters, onSuccess, onError);

        resetArrowPosition();
    };

    const addRequest = (type, parameters) => {
        console.debug("REQUESTS: ", currentRequests.length);
        const timestamp = new Date().getTime();

        let cancelable = !(Math.abs(parameters.pan) < DEFAULT_VALUE_DEAD_ZONE && Math.abs(parameters.tilt) < DEFAULT_VALUE_DEAD_ZONE);
        console.debug("**** control: ", timestamp - lastRequestTimestamp, "(", parameters.pan, ", ", parameters.tilt, ")");

        if (cancelable && (timestamp - lastRequestTimestamp < REQUEST_HYSTERESIS)) {
            console.debug("**** discarded: ", timestamp - lastRequestTimestamp, "(", parameters.pan, ", ", parameters.tilt, ")");
            return;
        }

        lastRequestTimestamp = timestamp;
        const CancelToken = getCancelToken();
        const source = CancelToken.source();
        panTiltPtzService(cameraId, parameters, onSuccess, onError);

        currentRequests.push({
            type,
            source,
            timestamp,
            cancelable
        })
    };

    const cancelRequests = type => {
        currentRequests = currentRequests.reduce(
            (acc, request) => {
                if (!type || request.type === type) {
                    console.warn('**** Cancelling request ', request);
                    request.source.cancel('Too many PTZ requests.');
                } else {
                    acc.push(request)
                }
                return acc;
            },
            []
        );
    };

    const removeRequest = timestamp => {
        currentRequests = currentRequests.filter(request => timestamp !== request.timestamp);
    }

    const onSuccess = (response) => {
        removeRequest(lastRequestTimestamp);
        console.debug('Request successful', response);
    };

    const handleZoomSuccess = (response) => {
        zoomOpSuccessRef.current.zoomArrived();
        onSuccess(response);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleZoomError = (error) => {
        zoomOpSuccessRef.current.zoomArrived();
        onError(error);
    }

    const onZoomStopSuccess = (response) => {
        zoomOpSuccessRef.current.zoomStopped();
        if (!zoomOpSuccessRef.current.areAllPetitionsStopped()) {
            handleZoomStop();
        }
        onSuccess(response);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const onZoomStopError = (error) => {
        onError(error);
        handleZoomStop();
    }

    const handleZoomIn = () => {
        let parameters = {
            zoom: transformPanTiltZoom(1, MAX_CAMERA_SPEED/2)
        };
        zoomPtzService(cameraId, parameters, handleZoomSuccess, onError);
    }

    const handleZoomOut = () => {
        let parameters = {
            zoom: transformPanTiltZoom(-1, MAX_CAMERA_SPEED/2)
        };
        zoomPtzService(cameraId, parameters, handleZoomSuccess, handleZoomError);
    }

    const handleZoomStop = () => {
        cancelRequests();
        let parameters = {
            zoom: PTZ_STOP
        };
        zoomPtzService(cameraId, parameters, onZoomStopSuccess, onZoomStopError);
    };

    useEffect(() => {
        if (zoom === 1) {
            zoomOpSuccessRef.current.zoomSent();
            handleZoomIn();
        } else if (zoom === -1) {
            zoomOpSuccessRef.current.zoomSent();
            handleZoomOut();
        } else if (zoom === 0) {
            handleZoomStop();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoom]);

    const onZoomButtonEnter = () => {
        if (!isDraggingRef.current) {
            disableCenterRequest();
            setPanTiltEnabled(false);
            panTiltEnabledRef.current = false;
            handleStopCamera();
        }
    }

    const onZoomButtonLeave = () => {
        if (!isDraggingRef.current) {
            setZoom(0);
            enableCenterRequest();
            setPanTiltEnabled(true);
            panTiltEnabledRef.current = true;
        }
    }

    return (
        <div ref={frameRef} className={classes.svgContainer} >
            <IconButton
                onMouseEnter={onZoomButtonEnter}
                onMouseLeave={onZoomButtonLeave}
                disabled={isDragging}
                onMouseDown={(event) => {
                    event.stopPropagation();
                    setZoom(1);
                }}
                onMouseUp={(event) => {
                    event.stopPropagation();
                    setZoom(0);
                }}
                classes={{ root: `${classes.iconButton} ${classes.iconButtonRight}` }}>
                <ZoomInIcon className={isDragging ? classes.iconDisabledColor : classes.iconMainColor} />
            </IconButton>
            <IconButton
                onMouseEnter={onZoomButtonEnter}
                onMouseLeave={onZoomButtonLeave}
                disabled={isDragging}
                onMouseDown={(event) => {
                    event.stopPropagation();
                    setZoom(-1);
                }}
                onMouseUp={(event) => {
                    event.stopPropagation();
                    setZoom(0);
                }}
                classes={{ root: `${classes.iconButton} ${classes.iconButtonLeft}` }}>
                <ZoomOutIcon className={isDragging ? classes.iconDisabledColor : classes.iconMainColor} />
            </IconButton>
            <svg className={classes.svg}
                                viewBox={`0 0 ${width} ${height}`} onMouseUp={handleStopCamera}>
                <g className={classes.circleGroup} ref={groupRef} onMouseDown={handleMoveCamera}>
                    <circle cx={centerX} cy={centerY} r={SVG_RADIUS} x={centerX} y={centerY} radius={SVG_RADIUS}/>
                </g>
            </svg>
        </div>
    )
};

export default MouseControlComponent;