import React, {useEffect, useImperativeHandle, useState} from 'react';
import {Slider, Typography, withStyles} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {useIntl} from 'react-intl';
import format from 'date-fns/format';
import keycloak from '../../../../utils/keycloak';

import ZoomInRoundedIcon from '@material-ui/icons/ZoomInRounded';
import ZoomOutRoundedIcon from '@material-ui/icons/ZoomOutRounded';

import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {createClips, downloadClip} from '../../../../services/commonServices';
import {showMessage} from '../../../shared/notificationSnack/NotificationSnack';
import {
    DOWNLOAD_CLIP_FORMAT,
    DOWNLOAD_MINIMUM_SLIDER_RANGE,
    DOWNLOAD_TIME_LIMIT_MILLISECONDS,
    DOWNLOAD_TIME_LIMIT_MINUTES, STATE_URL_AVAILABLE, STATE_URL_ERROR, STATE_URL_PENDING,
} from '../../../../utils/constants';
import {createFullDate} from '../../../../utils/utils';
import {GRAY} from "../../../../utils/constants";
import JsFileDownloader from 'js-file-downloader';
import {useDispatch} from "react-redux";
import {turnOffLoadingActionCreator, turnOnLoadingActionCreator} from "../../../../actions/commonActions";


const DownloadSlider = withStyles((theme) => ({
    disabled: {
        color: '#d3d3d3 !important',
    },
    valueLabel: {
        color: theme.palette.primary.main,
        left: 'calc(-50% - 16px)',
        top: -20,
        '& *': {
            background: 'transparent',
            color: theme.palette.primary.main,
        },
    },
    thumb: {
        height: 20,
        width: 4,
        borderRadius: 0,
        marginTop: -8,
        marginLeft: -3,
        '&:focus, &:hover, &:active': {
            boxShadow: 'inherit',
        },
    },
    track: {
        height: 4,
    },
    rail: {
        height: 3,
        color: GRAY
    },
    marked: {
        color: theme.palette.primary.main,
    },
}))(Slider);

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0, 12, 3, 12),
        display: 'flex',
        justifyContent: 'center',
    },
    sliderContainer: {
        margin: theme.spacing(1, 0),
    },
    textBlock: {
        marginBottom: theme.spacing(2),
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dateInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    durationText: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(3)
    },
    sliderControls: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(-1),
    },
    zoomButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: theme.spacing(-1.5, -2, 0, 0),

    },
    zoomButton: {
        color: theme.palette.primary.main
    },
    zoomButtonDisabled: {
        color: GRAY
    },
    title: {
        fontWeight: 'bold'
    },
    textData: {
        color: GRAY
    }
}));

let intervalCheckClipStatus = null;

const DownloadSettingsComponent = React.forwardRef(({
                                                        filters, camera, description, changeComponentToRender
                                                    }, ref) => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    const {deployment_id, camera_id} = useParams();

    const [cameraId, setCameraId] = useState(null);

    let urlToDownload = null;

    const [fromDate, setFromDate] = useState(createFullDate(filters.dateFrom, +filters.timeFrom * 1000));
    const [toDate, setToDate] = useState(createFullDate(filters.dateTo, +filters.timeTo * 1000));
    const [filteredRange, setFilteredRange] = useState(toDate - fromDate);
    const [sliderDownloadValues, setSliderDownloadValues] = useState([createFullDate(filters.dateFrom, +filters.timeFrom * 1000), createFullDate(filters.dateTo, +filters.timeTo * 1000)]);


    const [clipDurationMinutes, setClipDurationMinutes] = useState(Math.floor((sliderDownloadValues[1] - sliderDownloadValues[0]) / (DOWNLOAD_TIME_LIMIT_MINUTES * 1000)));
    const validDuration = clipDurationMinutes <= DOWNLOAD_TIME_LIMIT_MINUTES;
    const [futureWarning, setFutureWarning] = useState(false);

    const turnOnLoading = () => {
        dispatch(turnOnLoadingActionCreator())
    };

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator())
    };

    useEffect(() => {
        setCameraId(camera._id ? camera._id : camera_id)
    }, [camera, camera_id]);

    const downloadTheClip = (response) => {

        urlToDownload = response.data.URL;
        if (response.data.URL) {
            clearInterval(intervalCheckClipStatus)
            handleVideoDownload(response.data.URL);
        }
    };

    const getClipToDownloadVideo = (clip_id, cam) => {
        downloadClip(deployment_id, cam, clip_id, downloadTheClip);
    };

    const createClipsSourcesSuccess = (cam, response) => {
        turnOffLoading();
        switch (response.data.status) {
            case STATE_URL_PENDING:
                intervalCheckClipStatus = setInterval(() => getClipToDownloadVideo(response.data._id, cam), 2000);
                break;
            case STATE_URL_AVAILABLE:
                getClipToDownloadVideo(response.data._id, cam);
                break;
            case STATE_URL_ERROR:
                showMessage(intl.formatMessage({id: 'download_error'}))
                break;
            default:
                break;
        }
    };

    useImperativeHandle(ref, () => ({
            postCreateClips: () => {
                const from = Math.round(+sliderDownloadValues[0] / 1000);
                const to = Math.round(+sliderDownloadValues[1] / 1000);
                const now = format(new Date(), 'T');

                const validDuration = (to - from <= DOWNLOAD_TIME_LIMIT_MILLISECONDS);
                const validDates = from < now && to < now;

                if (validDuration) {
                    if (validDates) {
                        turnOnLoading()
                        const data = `From=${from}&To=${to}&Format=${DOWNLOAD_CLIP_FORMAT}`
                        createClips(deployment_id, cameraId, data, (response) => createClipsSourcesSuccess(cameraId, response), turnOffLoading);
                        changeComponentToRender(null);
                    } else {
                        setFutureWarning(true);
                    }
                }
            }
        }
    ));

    const handleVideoDownload = () => {
        new JsFileDownloader({
            url: urlToDownload,
            headers: [{
                name: 'Authorization',
                value: `Bearer ${(!!keycloak && !!keycloak.token) ? keycloak.token : undefined}`
            }]
        })
            .then(() => {
                turnOffLoading();
            })
            .catch((error) => {
                turnOffLoading();
                showMessage(error);
            });
    };

    const onMoving = (newValue) => {
        setFutureWarning(false);
        setSliderDownloadValues(newValue);
    };

    const onMoved = (newValue) => {
        setFutureWarning(false);
        setSliderDownloadValues(newValue);
    };

    const setDefaultSliderValues = () => {
        setSliderDownloadValues([sliderDownloadValues[0], sliderDownloadValues[0] + DOWNLOAD_TIME_LIMIT_MILLISECONDS]);
    };

    useEffect(() => {
        setClipDurationMinutes(Math.floor((sliderDownloadValues[1] - sliderDownloadValues[0]) / (DOWNLOAD_TIME_LIMIT_MINUTES * 1000)));
    }, [sliderDownloadValues]);

    const handleZoomIn = () => {
        if (!validDuration) {
            setDefaultSliderValues();
        }

        setFromDate(new Date(+sliderDownloadValues[0]));
        const newToDate = +sliderDownloadValues[0] + (+filteredRange / 2);
        setToDate(new Date(+newToDate));
        setFilteredRange(new Date(+newToDate) - new Date(+sliderDownloadValues[0]));

        if ((new Date(+newToDate) - new Date(+sliderDownloadValues[0])) < DOWNLOAD_TIME_LIMIT_MILLISECONDS) {
            setSliderDownloadValues([sliderDownloadValues[0], +sliderDownloadValues[0] + (+filteredRange / 2)]);
        }
    };

    const handleZoomOut = () => {
        if (!validDuration) {
            setDefaultSliderValues();
        }
        setFromDate(new Date(+sliderDownloadValues[0]));
        const newToDate = +sliderDownloadValues[0] + (+filteredRange * 2);
        setToDate(new Date(+newToDate));
        setFilteredRange(new Date(+newToDate) - new Date(+sliderDownloadValues[0]));
    };

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.sliderContainer}>
                <div className={classes.sliderContainer}>
                    <DownloadSlider
                        valueLabelDisplay="on"
                        aria-labelledby="track-false-range-slider"
                        valueLabelFormat={(date) => format(+date, 'HH:mm:ss')}
                        getAriaValueText={(date) => format(+date, 'HH:mm:ss')}
                        min={+format(fromDate, 'T')}
                        max={+format(toDate, 'T')}
                        value={[+sliderDownloadValues[0], +sliderDownloadValues[1]]}
                        onChange={(event, newValue) => onMoving(newValue)}
                        onChangeCommitted={(event, newValue) => onMoved(newValue)}
                        color={!validDuration ? 'secondary' : 'primary'}
                    />
                </div>
                <div className={classes.sliderControls}>
                    <div className={classes.durationText}>
                        <Typography
                            color="primary"
                            variant="subtitle2"
                        >
                            {intl.formatMessage({id: 'download_current_duration'})}
                            :
                            {' '}
                            {clipDurationMinutes}
                            {' '}
                            {intl.formatMessage({id: 'minutes'})}
                        </Typography>
                        {!validDuration
                        && (
                            <Typography color="secondary" variant="subtitle2">
                                {intl.formatMessage({id: 'download_max_duration'})}
                                :
                                {DOWNLOAD_TIME_LIMIT_MINUTES}
                                {' '}
                                {intl.formatMessage({id: 'minutes'})}
                            </Typography>
                        )}
                        {futureWarning
                        && (
                            <Typography color="secondary" variant="subtitle2">
                                {intl.formatMessage({id: 'download_future_warning'})}
                            </Typography>
                        )}
                    </div>
                    <div className={classes.zoomButtonsContainer}>
                        <IconButton
                            onClick={() => handleZoomIn()}
                            disabled={filteredRange < DOWNLOAD_MINIMUM_SLIDER_RANGE}
                        >
                            <ZoomInRoundedIcon
                                className={filteredRange < DOWNLOAD_MINIMUM_SLIDER_RANGE ? classes.zoomButtonDisabled : classes.zoomButton}
                                fontSize="large"
                            />
                        </IconButton>
                        <IconButton onClick={() => handleZoomOut()} disabled={toDate >= new Date()}>
                            <ZoomOutRoundedIcon
                                className={toDate >= new Date() ? classes.zoomButtonDisabled : classes.zoomButton}
                                fontSize="large"
                            />
                        </IconButton>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.dateContainer}>
                    <div className={classes.dateInfo}>
                        <div>
                            <Typography variant="subtitle2" color="primary" className={classes.title}>
                                {intl.formatMessage({id: 'camera'})}
                            </Typography>
                            <Typography className={classes.textData}>{description}</Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="primary" className={classes.title}>
                                {intl.formatMessage({id: 'camera_id_full'})}
                            </Typography>
                            <Typography className={classes.textData}>{cameraId}</Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="primary" className={classes.title}>
                                {intl.formatMessage({id: 'from'})}
                            </Typography>
                            <Typography
                                className={classes.textData}
                            >
                                {format(+sliderDownloadValues[0], 'HH:mm:ss')}
                                {' '}
                                -
                                {' '}
                                {filters.dateFrom}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="primary" className={classes.title}>
                                {intl.formatMessage({id: 'to'})}
                            </Typography>
                            <Typography
                                className={classes.textData}
                            >
                                {format(+sliderDownloadValues[1], 'HH:mm:ss')}
                                {' '}
                                -
                                {' '}
                                {filters.dateTo}
                            </Typography>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
});

DownloadSettingsComponent.propTypes = {
    filters: PropTypes.object.isRequired,
    camera: PropTypes.any.isRequired,
    description: PropTypes.string.isRequired,
    changeComponentToRender: PropTypes.func.isRequired
};

export default DownloadSettingsComponent;
