import React, {useCallback, useEffect, useState} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useHistory} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import {Button, Divider, Typography} from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import {tenantSecondaryButton} from "../../../../../utils/tenantStyles";
import {filterCameraMosaicActionCreator} from "../../../../../actions/commonActions";
import Checkbox from "@material-ui/core/Checkbox";
import {ROUTE_DEPLOYMENT_MOSAIC} from "../../../../../utils/routes";
import {buildUrl} from "../../../../../utils/axiosRequests";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 240,
        maxWidth: 300,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    menuItem: {
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main
        },
        '&.MuiListItem-root:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main
        }
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(0, 2, 1, 2)
    },
    buttonFlexContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        padding: theme.spacing(0, 2, 1, 2)
    },
    subheader: {
        '&.MuiListSubheader-root': {
            lineHeight: '36px'
        }
    },
    primaryButton: {...tenantSecondaryButton(theme), marginBottom: theme.spacing(1)},
    secondaryButton: tenantSecondaryButton(theme),
    disabled: {
        backgroundColor: theme.palette.disabled.light + '!important',
        border: 0 + '!important',
        color: theme.palette.disabled.main + '!important'
    },
    checkbox: {
        '&.Mui-checked': {
            color: theme.palette.white.main
        }
    }
}));

const CameraComboComponent = () => {

    const intl = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const {deployment_id} = useParams();
    const selectedAlert = useSelector(state => state.commonState.selectedAlert);
    const filteredCameraListState = useSelector(state => state.commonState.filteredCameraList);

    const storedCameraList = JSON.parse(localStorage.getItem(deployment_id));
    const cameraList = useSelector((state) => state.commonState.cameraList[deployment_id]) || storedCameraList;
    const filteredCameraList = filteredCameraListState ? filteredCameraListState[deployment_id] : null;

    const [selectedCamera, setSelectedCamera] = useState([]);
    const [openSelect, setOpenSelect] = useState(false);

    const filterCameraMosaic = useCallback((cameras) => {
        dispatch(filterCameraMosaicActionCreator({deployment_id, filteredCameraList: cameras}));
    }, [dispatch, deployment_id]);

    const handleOpen = () => {
        setOpenSelect(true);
    }

    const handleClose = () => {
        setOpenSelect(false);
    }

    const handleChange = (event) => {
        setSelectedCamera(event.target.value.filter(cam => cam !== undefined));
    };

    const handleSetMosaic = () => {
        const cameraArray = cameraList.filter(cam => selectedCamera.find(c => c === cam._id))

        if (selectedAlert) {
            history.push(buildUrl(ROUTE_DEPLOYMENT_MOSAIC, {deployment_id}))
        }

        filterCameraMosaic(cameraArray);
        handleClose();
    };

    const handleSetFullMosaic = () => {
        filterCameraMosaic(cameraList);
        handleClose();
    };

    const handleSetSavedMosaic = () => {
        filterCameraMosaic(storedCameraList);
        handleClose();
    };

    const checkedCamera = camera => {
        return selectedCamera.filter(cam => cam === camera).length > 0
    };

    useEffect(() => {
        if (filteredCameraList) {
            setSelectedCamera(filteredCameraList.map(cam => cam._id))
        }
    }, [filteredCameraList])

    return (
        <Grid>
            <FormControl variant="filled" className={classes.formControl}>
                <InputLabel
                    htmlFor="camera-simple-select-filled-label">{intl.formatMessage({id: "mosaic_settings"})}</InputLabel>
                <Select
                    open={openSelect}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    disableUnderline
                    renderValue={(selected) => {
                        const text = selected.length === 1 ?
                            intl.formatMessage({id: "selected_camera"}) :
                            intl.formatMessage({id: "selected_cameras"})
                        return selected.length + ' ' + text;
                    }}
                    value={selectedCamera}
                    onChange={handleChange}
                    id={"camera-simple-select-filled"}
                    labelId="camera-simple-select-filled-label"
                    multiple
                    className={classes.select}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}>
                    <div className={classes.buttonFlexContainer}>
                        <Button className={classes.primaryButton} onClick={() => handleSetFullMosaic()}>
                            <Typography variant={'button'}>{intl.formatMessage({id: 'see_all_cameras'})}</Typography>
                        </Button>
                        <Button className={classes.primaryButton} onClick={() => handleSetSavedMosaic()}
                                disabled={!storedCameraList?.length} classes={{disabled: classes.disabled}}>
                            <Typography variant={'button'}>{intl.formatMessage({id: 'see_saved_cameras'})}</Typography>
                        </Button>
                    </div>
                    <Divider/>
                    <ListSubheader
                        className={classes.subheader}>{intl.formatMessage({id: "select_cameras"})}</ListSubheader>
                    {cameraList && cameraList.map((camera) => (
                        <MenuItem key={camera._id} value={camera._id}
                                  className={classes.menuItem}>
                            <Checkbox checked={checkedCamera(camera._id)} className={classes.checkbox}/>
                            {camera.description}
                        </MenuItem>))}
                    <Divider/>
                    <div className={classes.buttonContainer}>
                        <Button className={classes.secondaryButton} fullWidth onClick={() => handleSetMosaic()}
                                disabled={selectedCamera.length === 0} classes={{disabled: classes.disabled}}>
                            <Typography variant={'button'}>{intl.formatMessage({id: 'apply_filters'})}</Typography>
                        </Button>
                    </div>
                </Select>
            </FormControl>
        </Grid>
    );
};

export default CameraComboComponent;






