import headerLogo from '../images/logo-para-web-grupo.png';
import loginLogo from '../images/logo-para-web-grupo.png';

import favicon from '../images/favicon.ico';
import { GRAY } from './constants';

export const tenantPrimaryButton = (theme) => {
    return {
        border: '1px solid !important',
        borderColor: theme.palette.primary.main + '!important',
        backgroundColor: theme.palette.primary.main,
        color: 'white !important',
        minWidth: 200,
        '&:hover': {
            border: '1px solid !important',
            borderColor: theme.palette.primary.main + '!important',
            backgroundColor: 'white !important',
            color: theme.palette.primary.main + '!important',
        },
        '&:disabled': {
            backgroundColor: theme.palette.disabled.light + '!important',
            border: 0 + '!important',
            color: theme.palette.disabled.main + '!important',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    };
};
export const tenantPrimaryButtonNoHover = (theme) => {
    return {
        border: '1px solid !important',
        borderColor: theme.palette.primary.main + '!important',
        backgroundColor: theme.palette.primary.main,
        color: 'white !important',
        cursor: 'default',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
            borderColor: theme.palette.primary.main + '!important',
            color: 'white !important',
            cursor: 'default',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    };
};
export const tenantPrimaryButtonMosaic = (theme) => {
    return {
        border: '1px solid !important',
        borderColor: theme.palette.primary.main + '!important',
        backgroundColor: theme.palette.primary.main,
        color: 'white !important',
        '&:hover': {
            border: '1px solid !important',
            borderColor: theme.palette.primary.main + '!important',
            backgroundColor: 'white !important',
            color: theme.palette.primary.main + '!important',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 'auto auto',
        },
    };
};


export const tenantSecondaryButton = (theme) => {
    return {
        border: '1px solid !important',
        borderColor: theme.palette.primary.main + '!important',
        backgroundColor: 'white !important',
        color: theme.palette.primary.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
            borderColor: theme.palette.primary.main + '!important',
            color: 'white !important',
        },
        '&:disabled': {
            borderColor: theme.palette.disabled.light + '!important',
            backgroundColor: 'transparent',
            color: theme.palette.disabled.main + '!important',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    };
};
export const tenantSecondaryButtonNoHover = (theme) => {
    return {
        border: '1px solid !important',
        borderColor: theme.palette.primary.main + '!important',
        backgroundColor: 'white !important',
        color: theme.palette.primary.main + '!important',
        '&:hover': {
            border: '1px solid !important',
            borderColor: theme.palette.primary.main + '!important',
            backgroundColor: 'white !important',
            color: theme.palette.primary.main + '!important',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    };
};
export const tenantFiltersMosaicButton = (theme) => {
    return {
        border: '1px solid !important',
        borderColor:  GRAY + '!important',
        backgroundColor: GRAY + '!important',
        color: 'white !important',
        '&:hover': {
            backgroundColor: 'white !important',
            borderColor: GRAY + '!important',
            color: GRAY + '!important',
        },
    };
};

export const TENANT_STYLES = {
    favicon: favicon,
    footer_data: {
        copyright_name: 'Grupo Euskaltel © 2021',
        privacy_policy: 'https://www.euskaltel.com/',
        use_terms: 'https://www.euskaltel.com/',
    },
    header_data: {
        logo: {
            alt: 'Logo',
            link: 'https://www.euskaltel.com/',
            src: headerLogo,
        },
    },
    login_data: {
        logo: {
            alt: 'Logo',
            link: 'https://www.euskaltel.com/',
            src: loginLogo,
        },
    },
    project_name: '',
    ui_theme: {
        primary_color: {
            main: '#FF5800',
        },
        secondary_color: {
            main: '#245ebb',
        },
    },
};
