import React, {useCallback, useEffect, useState} from "react";
import {compose} from "redux";
import withAuthorization from "../shared/auth/withAuthorization";
import {BREADCRUMB_MANAGEMENT, MIN_HEIGHT, ROLES_MANAGER} from "../../utils/constants";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./UserManagementStyles";
import PageHeaderComponent from "../common/pageHeaderComponent/PageHeaderComponent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useIntl} from "react-intl";
import {
    deleteUserService,
    getCustomerList,
    getUserListByCustomer,
    removeAccessService
} from "../../services/commonServices";
import {useKeycloak} from "@react-keycloak/web";
import {Button, Typography} from "@material-ui/core";
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';

import AdminPasswordComponent from "./components/AdminPasswordComponent";
import NewUserFormComponent from "./components/NewUserFormComponent";
import UsersTableComponent from "./components/UsersTableComponent";
import AlertDialog from "../common/alertContainer/components/AlertDialog";


const useStyles = makeStyles((theme) => styles(theme, {MIN_HEIGHT}));

const UserManagementComponent = () => {

    const classes = useStyles();
    const intl = useIntl();
    const {keycloak, initialized} = useKeycloak();


    const [customers, setCustomers] = useState(null);
    const [customer, setCustomer] = useState('');

    const [userList, setUserList] = useState(null);
    const [user, setUser] = useState(null);

    const [passwordModal, setPasswordModal] = useState(false);
    const [newUserModal, setNewUserModal] = useState(false);

    const [removeAccessConfirmation, setRemoveAccessConfirmation] = useState(false);
    const [deleteUserConfirmation, setDeleteUserConfirmation] = useState(false);

    const getCustomers = useCallback(() => {
        if (initialized && keycloak.authenticated) {
            const successCustomerList = (response) => {
                setCustomers(response.data);
            };
            getCustomerList(keycloak, successCustomerList);
        }
    }, [initialized, keycloak]);

    useEffect(() => {
        getCustomers();
    }, [getCustomers]);

    const handleSetUserList = response => {
        setUserList(response.data)
    };

    const handleGetUserList = () => {
        getUserListByCustomer(customer._id, handleSetUserList)
    };

    const handleSelectCustomer = e => {
        setCustomer(e.target.value);
        getUserListByCustomer(e.target.value._id, handleSetUserList)
    };

    const handlePasswordModal = user => {
        setUser(passwordModal ? null : user)
        setPasswordModal(passwordModal => !passwordModal)
    };

    const handleNewUserModal = () => {
        setNewUserModal(newUserModal => !newUserModal)
    };

    const handleDeleteUserConfirmation = (user) => {
        setUser(deleteUserConfirmation ? null : user);
        setDeleteUserConfirmation(deleteUserConfirmation => !deleteUserConfirmation)
    };

    const handleDeleteUser = () => {
        deleteUserService(user.credentials.username, handleGetUserList);
        handleDeleteUserConfirmation();
    };

    const handleRemoveAccessConfirmation = (user) => {
        setUser(removeAccessConfirmation ? null : user);
        setRemoveAccessConfirmation(removeAccessConfirmation => !removeAccessConfirmation)
    };

    const handleRemoveAccess = () => {
        removeAccessService(customer._id, user._id, handleGetUserList);
        handleRemoveAccessConfirmation();
    };

    return (
        <div className={classes.root}>
            <PageHeaderComponent type={BREADCRUMB_MANAGEMENT}/>
            <div className={classes.userContent}>
                <div className={classes.userForm}>
                    <FormControl className={classes.customerSelect}>
                        <InputLabel
                            id="demo-simple-select-label">{intl.formatMessage({id: 'customer_select'})}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={customer}
                            onChange={handleSelectCustomer}>
                            {customers ?
                                customers.map(customer => <MenuItem value={customer}
                                                                    key={customer.description}>{customer.description}</MenuItem>) :
                                <MenuItem value="">
                                    <em>{intl.formatMessage({id: 'customer_no_customers'})}</em>
                                </MenuItem>}
                        </Select>
                    </FormControl>
                    <Button className={!customer ? classes.disabled : classes.primaryButton} disabled={!customer}
                            startIcon={
                                <PersonAddRoundedIcon/>}
                            onClick={handleNewUserModal}>{intl.formatMessage({id: 'user_add'})}
                    </Button>
                </div>
                <UsersTableComponent classes={classes} userList={userList} handlePasswordModal={handlePasswordModal}
                                     handleRemoveAccessConfirmation={handleRemoveAccessConfirmation}
                                     handleDeleteUserConfirmation={handleDeleteUserConfirmation}/>

                <AlertDialog
                    titleDialog={intl.formatMessage({id: 'user_edit_password'})}
                    description={<AdminPasswordComponent username={user?.credentials?.username}
                                                         partnerId={user?.owned_by_partner_id}
                                                         handlePasswordModal={() => handlePasswordModal(null)}/>}
                    openDialog={passwordModal}/>
                <AlertDialog
                    titleDialog={intl.formatMessage({id: 'user_add'})}
                    description={<NewUserFormComponent handleNewUserModal={handleNewUserModal}
                                                       customer={customer} handleGetUserList={handleGetUserList}/>}
                    openDialog={newUserModal}/>
                <AlertDialog
                    buttonCloseText={intl.formatMessage({id: 'close'})}
                    buttonAcceptText={intl.formatMessage({id: 'accept'})}
                    titleDialog={intl.formatMessage({id: 'user_delete_access'})}
                    description={<Typography>{intl.formatMessage({id: 'user_remove_access'})}</Typography>}
                    onClose={handleRemoveAccessConfirmation}
                    dialogOnAccept={handleRemoveAccess}
                    openDialog={removeAccessConfirmation}/>
                <AlertDialog
                    buttonCloseText={intl.formatMessage({id: 'close'})}
                    buttonAcceptText={intl.formatMessage({id: 'accept'})}
                    titleDialog={intl.formatMessage({id: 'user_delete_user'})}
                    description={<Typography>{intl.formatMessage({id: 'user_delete_confirmation'})}</Typography>}
                    onClose={handleDeleteUserConfirmation}
                    dialogOnAccept={handleDeleteUser}
                    openDialog={deleteUserConfirmation}/>
            </div>
        </div>
    )
};


export default compose(
    withAuthorization(ROLES_MANAGER),
)(UserManagementComponent);
