import React, {useEffect, useState} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useIntl} from "react-intl";
import { useHistory} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {getMosaics} from "../../../../../services/commonServices";
import {ROUTE_DEPLOYMENT_GROUP_MOSAIC} from "../../../../../utils/routes";
import {buildUrl} from "../../../../../utils/axiosRequests";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 240,
        maxWidth: 300,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    menuItem: {
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main
        },
        '&.MuiListItem-root:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main
        }
    }
}));

const MosaicComboComponent = ({mosaicId}) => {

    const intl = useIntl();
    const classes = useStyles();
    const history = useHistory();

    const [mosaicList, setMosaicList] = useState(null);
    const [selectedMosaic, setSelectedMosaic] = useState(null);
    const [openSelect, setOpenSelect] = useState(false);

    const handleSetMosaicList = (response) => {
        setMosaicList(response.data);
    }

    useEffect(() => {
        getMosaics(handleSetMosaicList)
    }, [])

    const handleOpen = () => {
        setOpenSelect(true);
    }

    const handleClose = () => {
        setOpenSelect(false);
    }

    const handleChange = event => {
        setSelectedMosaic(event.target.value);
        const url = buildUrl(ROUTE_DEPLOYMENT_GROUP_MOSAIC , {mosaic_id: event.target.value._id});
        history.push(url);
    };

    useEffect(() => {
        if (mosaicList?.length > 0) {
            const mosaic = mosaicList.find(mosaic => mosaic._id === mosaicId);
            setSelectedMosaic(mosaic);
        }
    }, [mosaicList, mosaicId])

    return (
        <Grid>
            <FormControl variant="filled" className={classes.formControl}>
                <InputLabel
                    htmlFor="mosaic-simple-select-filled-label">{intl.formatMessage({id: "mosaic_choose"})}</InputLabel>
                <Select
                    open={openSelect}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    disableUnderline
                    renderValue={(selected) => {
                        return selected.mosaic_name;
                    }}
                    value={selectedMosaic || ""}
                    onChange={handleChange}
                    id={"mosaic-simple-select-filled"}
                    labelId="mosaic-simple-select-filled-label"
                    className={classes.select}
                    inputProps={{ 'aria-label': 'Without label' }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}>
                    <MenuItem value="">
                        <em>{intl.formatMessage({id: 'mosaic_choose'})}</em>
                    </MenuItem>
                    {mosaicList && mosaicList.map((mosaic) => (
                        <MenuItem key={mosaic._id} value={mosaic} className={classes.menuItem}>
                            {mosaic.mosaic_name}
                        </MenuItem>))}
                </Select>
            </FormControl>
        </Grid>
    );
};

export default MosaicComboComponent;






