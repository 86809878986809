import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import enFlag from '../../../images/flag_uk.svg';
import glFlag from '../../../images/flag_gl.png';
import esFlag from '../../../images/flag_es.png';

const img_src = {
  en: enFlag,
  gl: glFlag,
  es: esFlag,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 20,
    width: 20,
    marginLeft: 16,
  },
  img: {
    height: '100%',
    width: '100%',
  },
}));

export default function FlagIcon({ flag }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img alt={`${flag} flag`} className={classes.img} src={img_src[flag]} />
    </div>
  );
}
