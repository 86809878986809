import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import "./App.css";
import { IntlProvider } from "react-intl";
import {
  APP_BASE_ROUTE,
  ROUTE_CAMERA,
  ROUTE_DEPLOYMENT_LIST,
  ROUTE_CUSTOMER,
  ROUTE_CUSTOMER_LIST,
  ROUTE_DEPLOYMENT,
  ROUTE_DEPLOYMENT_MOSAIC,
  ROUTE_LANDING,
  ROUTE_USER_PROFILE,
  ROUTE_USER_MANAGEMENT,
  ROUTE_DEPLOYMENT_GROUP_MOSAIC
} from "./utils/routes";
import { ErrorBoundary } from "./components/shared/errorBoundaryComponent/ErrorBoundaryComponent";
import LandingComponent from "./components/landingComponent/LandingComponent";
import ErrorComponent from "./components/shared/errorComponent/ErrorComponent";
import NotificationSnack from "./components/shared/notificationSnack/NotificationSnack";
import GlobalLoadingComponent from "./components/globalLoadingComponent/GlobalLoadingComponent";
import CustomerListComponent from "./components/customerListComponent/CustomerListComponent";
import DeploymentComponent from "./components/deploymentComponent/DeploymentComponent";

import TopMenuComponent from "./components/topMenuComponent/TopMenuComponent";

import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";
//import messages_gl from './translations/gl.json';
import { FooterComponent } from "./components/common/footerComponent/FooterComponent";
import logoFooter from "./images/app_logo.png";

import { TENANT_STYLES } from "./utils/tenantStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSetStyles,
  setStylesActionCreator
} from "./actions/commonActions";
import { loadUserProfile } from "./services/commonServices";
import { hasRoles } from "./utils/keycloak";
import {
  DEFAULT_STYLE,
  DISABLED,
  GRAY,
  ROLES_ADMIN,
  LOCAL_STORAGE__LOCALE
} from "./utils/constants";
import { useKeycloak } from "@react-keycloak/web";
import DeploymentListComponent from "./components/deploymentListComponent/DeploymentListComponent";
import Favicon from "react-favicon";
import UserManagementComponent from "./components/userManagementComponent/UserManagementComponent";
import ProfileComponent from "./components/profileComponent/ProfileComponent";
import CameraMosaicContainer from "./components/cameraMosaicComponent/cameraMosaicContainer/CameraMosaicContainer";
import { getLanguage } from "./utils/utils";

const messages = {
  es: messages_es,
  en: messages_en
  //  gl: messages_gl
};

const links = {
  main: "https://www.cinfo.es/es/tiivii-es/",
  policy: "https://www.cinfo.es/es/politica-privacidad/",
  contact: "https://www.cinfo.es/es/tiivii-es/"
};

export default function App() {
  const language = localStorage.getItem(LOCAL_STORAGE__LOCALE) || getLanguage();
  const [locale, setLocale] = useState({
    locale: language,
    messages: messages[language]
  });
  const isAdmin = hasRoles(ROLES_ADMIN);
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();

  const customerStylesRedux = useSelector(
    state => state.commonState.customerStyles
  );
  const customerStylesStorage = JSON.parse(
    localStorage.getItem("customerStyles")
  );

  const [customerStyles, setCustomerStyles] = useState(customerStylesStorage);

  const setReduxStyles = useCallback(() => {
    localStorage.setItem("customerStyles", JSON.stringify(TENANT_STYLES));
    dispatch(setStylesActionCreator(TENANT_STYLES));
  }, [dispatch]);

  const loadCustomerStyles = useCallback(() => {
    if (keycloak && keycloak.authenticated && !isAdmin) {
      loadUserProfile(keycloak, dispatch(handleSetStyles));
    }
  }, [keycloak, isAdmin, dispatch]);

  useEffect(() => {
    setReduxStyles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (keycloak && initialized) {
      loadCustomerStyles();
    }
  }, [keycloak, initialized, loadCustomerStyles]);

  useEffect(() => {
    if (customerStylesRedux) {
      setCustomerStyles(customerStylesRedux);
    }
  }, [customerStylesRedux]);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main:
          customerStyles?.ui_theme?.primary_color?.main ||
          DEFAULT_STYLE.ui_theme.primary_color.main
      },
      secondary: {
        dark:
          customerStyles?.ui_theme?.secondary_color?.dark ||
          DEFAULT_STYLE.ui_theme.primary_color.main,
        light:
          customerStyles?.ui_theme?.secondary_color?.light ||
          DEFAULT_STYLE.ui_theme.primary_color.main,
        main:
          customerStyles?.ui_theme?.secondary_color?.main ||
          DEFAULT_STYLE.ui_theme.primary_color.main
      },
      disabled: {
        light: DISABLED,
        main: GRAY
      },
      white: {
        main: "#ffffff"
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          ".MuiButton-label": {
            "& p": {
              marginTop: 5
            }
          }
        }
      }
    }
  });

  const handleLanguageChanged = lang => {
    if (messages[lang]) {
      setLocale({ locale: lang, messages: messages[lang] });
    }
  };

  return (
    <>
      <Favicon url={customerStyles?.favicon} />
      <IntlProvider
        locale={locale.locale}
        messages={locale.messages}
        defaultLocale="es"
      >
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
            <ErrorBoundary key="landingErrorBoundary">
              <Router basename={APP_BASE_ROUTE}>
                <TopMenuComponent
                  handleLanguageChanged={handleLanguageChanged}
                  language={language}
                />
                <Switch>
                  <Route exact path={ROUTE_LANDING}>
                    <LandingComponent />
                  </Route>
                  <Route exact path={ROUTE_DEPLOYMENT_LIST}>
                    <DeploymentListComponent />
                  </Route>
                  <Route exact path={ROUTE_CUSTOMER_LIST}>
                    <CustomerListComponent />
                  </Route>
                  <Route exact path={ROUTE_CUSTOMER}>
                    <DeploymentListComponent locale={locale} />
                  </Route>
                  <Route exact path={ROUTE_DEPLOYMENT}>
                    <DeploymentComponent locale={locale} />
                  </Route>
                  <Route exact path={ROUTE_DEPLOYMENT_MOSAIC}>
                    <CameraMosaicContainer />
                  </Route>
                  <Route exact path={ROUTE_DEPLOYMENT_GROUP_MOSAIC}>
                    <CameraMosaicContainer />
                  </Route>
                  <Route exact path={ROUTE_CAMERA}>
                    <CameraMosaicContainer />
                  </Route>
                  <Route exact path={ROUTE_USER_PROFILE}>
                    <ProfileComponent locale={locale} />
                  </Route>
                  <Route exact path={ROUTE_USER_MANAGEMENT}>
                    <UserManagementComponent locale={locale} />
                  </Route>
                  <Route path="*">
                    <ErrorComponent error="error_404" />
                  </Route>
                </Switch>
                <FooterComponent logoFooter={logoFooter} links={links} />
              </Router>
            </ErrorBoundary>
            <NotificationSnack />
            <GlobalLoadingComponent />
          </div>
        </MuiThemeProvider>
      </IntlProvider>
    </>
  );
}
