import {MIN_HEIGHT} from "../../../../../utils/constants";

export const styles = (theme) => ({
    root: {
        zIndex: 0,
        padding: theme.spacing(0, 20),
        textAlign: 'left',
        minHeight: MIN_HEIGHT,
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 2),
            marginBottom: theme.spacing(6),
        },
    },
    cameraGrid: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    h4: {
        textAlign: 'left !important',
    },
    h5: {
        textAlign: 'left !important',
    },
    paper: {
        margin: 10,
        padding: theme.spacing(3),
        textAlign: 'left',
        flex: '0 1 auto',
    },
    camerasContainer: {
        marginBottom: theme.spacing(4),
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(4, 0),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80%',
    },
    modalContainer: {
        border: 'none',
        width: '90%',
        height: '45vh',
        margin: 'auto',
        // marginTop: theme.spacing(6),
        backgroundColor: 'white',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: theme.spacing(2),
        position: 'relative',
    },
    alertTable: {
        marginBottom: theme.spacing(12)
    }
});
