import React, {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useKeycloak} from '@react-keycloak/web';
import {
    ROUTE_DEPLOYMENT, ROUTE_DEPLOYMENT_LIST, ROUTE_CUSTOMER_LIST
} from '../../utils/routes';

import {TIIVII_API_DEPLOYMENT_LIST_BY_USER} from '../../utils/constants';
import {buildUrl} from "../../utils/axiosRequests";
import {getContractsList, getCustomerList} from "../../services/commonServices";


function LandingComponent() {
    const {keycloak, initialized} = useKeycloak();
    const history = useHistory();
 
    const getDeployments = useCallback(customer => {
        const urlByUser = buildUrl(TIIVII_API_DEPLOYMENT_LIST_BY_USER, {username: keycloak.profile.username});

        const successContractList = response => {
            if (response.data.length === 1) {
                const url = buildUrl(ROUTE_DEPLOYMENT, {customer, deployment_id: response.data[0].deployment_id});
                history.push(url)
            } else {
                const url = buildUrl(ROUTE_DEPLOYMENT_LIST, {customer});
                history.push(url)
            }
        };
        getContractsList(urlByUser, successContractList);
    },[history, keycloak]);


    const getCustomers = useCallback(() => {
        const successCustomerList = response => {
            if (response.data.length > 1) {
                history.push(ROUTE_CUSTOMER_LIST)
            } else {
                getDeployments(response.data[0]._id)
            }
        };
        getCustomerList(keycloak, successCustomerList);
    }, [getDeployments, history, keycloak]);  

    useEffect(() => {
        if (initialized) {
            getCustomers();
        }
    }, [initialized, getCustomers]);
 
    useEffect(() => {
        if (initialized && !!keycloak && !keycloak.authenticated) {
            keycloak.login();
        }
    },[initialized, keycloak])


    return  <></>
}

export default LandingComponent;
