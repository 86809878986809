import React from 'react';
import AlertDialog from "../../common/alertContainer/components/AlertDialog";
import {FormattedMessage} from "react-intl";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, open: null};
        this.error = null
    }

    static getDerivedStateFromError(error) {
        return {error: error.toString(), open: true};
    }

    onClose = () => this.setState({open: false});

    render() {
        if (this.state.error) {
            return (
                <div>
                    <AlertDialog
                        open={this.state.open}
                        title={<FormattedMessage id="error_boundary_title"/>}
                        description={<FormattedMessage id="error_boundary_description"/>}
                        onClose={this.onClose}
                        buttonCloseText={<FormattedMessage id="global_buttons_close"/>}
                    />
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
