import React, {useState, useEffect} from "react";
import {compose} from "redux";
import withAuthorization from "../shared/auth/withAuthorization";
import {BREADCRUMB_PROFILE, MIN_HEIGHT, ROLE_STARTS_WITH, ROLES_ALL} from "../../utils/constants";
import {useKeycloak} from '@react-keycloak/web';
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./ProfileStyles";
import PageHeaderComponent from "../common/pageHeaderComponent/PageHeaderComponent";
import UserInfoComponent from "./components/UserInfoComponent";
import PasswordComponent from "./components/PasswordComponent";
import {getUserInfo} from "../../services/commonServices";

const useStyles = makeStyles((theme) => styles(theme, {MIN_HEIGHT}));

const ProfileComponent = () => {

    const classes = useStyles();
    const {keycloak} = useKeycloak();

    const [userData, setUserData] = useState(null);
    const [userInfo, setUserInfo] = useState(null);


    useEffect(() => {
        if (keycloak) {
            keycloak.loadUserProfile().then(data => {
                setUserData({
                    username: data.username,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    role: keycloak?.realmAccess.roles.find(role => role.startsWith(ROLE_STARTS_WITH))
                })
            })
        }
    }, [keycloak])

    const handleSetUserInfo = response => {
        setUserInfo(response.data);
    };

    useEffect(() => {
        if (userData) {
            getUserInfo(userData?.username, handleSetUserInfo)
        }
    }, [userData])


    return (
        <div className={classes.root}>
            <PageHeaderComponent type={BREADCRUMB_PROFILE}/>
            <div className={classes.userContent}>
                {userData && <UserInfoComponent userData={userData} classes={classes}/>}
                <PasswordComponent classes={classes} username={userData?.username}
                                   partnerId={userInfo?.owned_by_partner_id}/>
            </div>
        </div>
    )
};

export default compose(
    withAuthorization(ROLES_ALL)
)(ProfileComponent);

