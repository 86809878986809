import React, {useCallback, useEffect, useState} from "react";
import {CardHeader, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {MESSAGE_TIMEOUT} from "../../../utils/constants";
import {updateUserPassword} from "../../../services/commonServices";


const PasswordComponent = ({classes, username, partnerId}) => {

    const intl = useIntl();

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');

    const [mustMatchWarning, setMustMatchWarning] = useState(false);
    const [samePasswordWarning, setSamePasswordWarning] = useState(false);

    const [successMessage, setSuccessMessage] = useState(false);
    const [failureMessage, setFailureMessage] = useState(false);

    const resetInputValues = useCallback(() => {
        handleCurrentPassword('');
        handleNewPassword1('');
        handleNewPassword2('');
    }, []);

    useEffect(() => {
        resetInputValues()
    }, [resetInputValues])

    const handleSetSuccessMessage = value => {
        setSuccessMessage(value)
    };

    const handleSetFailureMessage = value => {
        setFailureMessage(value)
    }

    const handleUpdateSuccess = () => {

        handleSetSuccessMessage(true);

        setTimeout(() => {
            handleSetSuccessMessage(false);
            resetInputValues();
        }, MESSAGE_TIMEOUT)
    };

    const handleUpdateFailure = () => {
        handleSetFailureMessage(true);

        setTimeout(() => {
            handleSetFailureMessage(false);
        }, MESSAGE_TIMEOUT)
    }

    const handleUpdateUserCredentials = () => {
        const body = {
            credentials: {
                password: newPassword1
            },
            owned_by_partner_id: partnerId
        };
        updateUserPassword(username, body, handleUpdateSuccess, handleUpdateFailure)
    }

    const handleCurrentPassword = value => {
        setCurrentPassword(value)
    }

    const handleNewPassword1 = value => {
        setNewPassword1(value)
    }

    const handleNewPassword2 = value => {
        setNewPassword2(value)
    }

    const handleMustMatchWarning = useCallback(value => {
            setMustMatchWarning(value)
        }, []
    )
    const handleSamePasswordWarning = useCallback(value => {
        setSamePasswordWarning(value)
    }, [])

    useEffect(() => {
        if (currentPassword && newPassword1 && currentPassword === newPassword1 && !samePasswordWarning) {
            handleSamePasswordWarning(true)
        } else if (currentPassword && newPassword1 && currentPassword !== newPassword1 && samePasswordWarning) {
            handleSamePasswordWarning(false)
        }

        if (newPassword1 && newPassword2 && newPassword1 !== newPassword2 && !mustMatchWarning) {
            handleMustMatchWarning(true)
        } else if (newPassword1 && newPassword2 && newPassword1 === newPassword2 && mustMatchWarning) {
            handleMustMatchWarning(false)
        }

        if (!currentPassword || !newPassword1) {
            handleSamePasswordWarning(false)
        }

        if (!newPassword1 || !newPassword2 || samePasswordWarning) {
            handleMustMatchWarning(false)
        }
    }, [currentPassword, newPassword1, newPassword2, handleMustMatchWarning, handleSamePasswordWarning, mustMatchWarning, samePasswordWarning])

    const validatePassword = () => {
        return !!currentPassword && !!newPassword1 && !!newPassword2 && !mustMatchWarning && !samePasswordWarning
    };

    return (
        <Card className={classes.cardRoot}>
            <CardHeader title={intl.formatMessage({id: "user_password_edit"})} className={classes.cardHeader}/>
            <CardContent className={classes.cardContent}>
                <form className={classes.textFieldContainer}>
                    <div className={classes.textFieldBlock}>
                        <TextField id="current-standard-basic" type="password" value={currentPassword}
                                   label={intl.formatMessage({id: 'user_password_old'})}
                                   className={classes.textField} onChange={e => handleCurrentPassword(e.target.value)}/>
                    </div>
                    <div className={classes.textFieldBlock}>
                        <TextField id="new1-standard-basic" type="password" value={newPassword1}
                                   label={intl.formatMessage({id: 'user_password_new'})}
                                   className={classes.textField} onChange={e => handleNewPassword1(e.target.value)}/>
                        <TextField id="new2-standard-basic" type="password" value={newPassword2}
                                   label={intl.formatMessage({id: 'user_password_new_again'})}
                                   className={classes.textField} onChange={e => handleNewPassword2(e.target.value)}/>
                        <Button
                            onClick={handleUpdateUserCredentials}
                            disabled={!validatePassword()}
                            className={classes.primaryButton}
                            classes={{disabled: classes.disabled}}>
                            {intl.formatMessage({id: 'global_buttons_send'})}
                        </Button>
                    </div>
                </form>
                <div className={classes.formFooter}>
                    <div className={classes.warningContainer}>
                        {mustMatchWarning && <Typography
                            variant="subtitle2"
                            color="secondary">
                            {intl.formatMessage({id: 'user_password_warning_match'})}
                        </Typography>}
                        {samePasswordWarning && <Typography
                            variant="subtitle2"
                            color="secondary">
                            {intl.formatMessage({id: 'user_password_warning_same'})}
                        </Typography>}
                    </div>
                </div>
                <div className={classes.messageContainer}>
                    {successMessage && <Typography
                        variant="subtitle2"
                        color="secondary">
                        {intl.formatMessage({id: 'user_password_edit_success'})}
                    </Typography>}
                    {failureMessage && <Typography
                        variant="subtitle2"
                        color="secondary">
                        {intl.formatMessage({id: 'user_password_edit_failure'})}
                    </Typography>}
                </div>
            </CardContent>
        </Card>
    )
};

export default PasswordComponent;