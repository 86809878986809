import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    SwipeableDrawer,
    Toolbar,
} from '@material-ui/core';
import {Link} from 'react-router-dom';

import MenuIcon from '@material-ui/icons/Menu';
import {useHistory} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useKeycloak} from '@react-keycloak/web';
import FlagIcon from './components/FlagIconComponent';
import CustomersIconComponent from '../common/customIcons/customersIconComponent/CustomersIconComponent';
import LoginIconComponent from '../common/customIcons/LoginIconComponent/LoginIconComponent';
import LogoutIconComponent from '../common/customIcons/LogoutIconComponent/LogoutIconComponent';
import {ROUTE_CUSTOMER_LIST, ROUTE_LANDING, ROUTE_USER_MANAGEMENT, ROUTE_USER_PROFILE} from '../../utils/routes';
import {ROLES_ADMIN, GRAY, LANGUAGES_ARRAY, I18N_CONFIG, ROLES_MANAGER, LOCAL_STORAGE__LOCALE} from '../../utils/constants';
import AuthorizedElement from '../shared/auth/AuthorizedElement';
import {useSelector} from "react-redux";
import UserIconComponent from "../common/customIcons/userIconComponent/UserIconComponent";
import SettingsIconComponent from "../common/customIcons/settingsIconComponent/SettingsIconComponent";

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: 66,
        backgroundColor: theme.palette.white.main,
    },
    toolbar: {
        color: 'white',
        height: 64,
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '40%',
        height: "100%",
        padding: theme.spacing(0, 20),
        [theme.breakpoints.down('sm')]: {
            maxWidth: '60%',
            padding: theme.spacing(0, 6),
        },
    },
    imageHeader: {
        maxHeight: 32,
        marginTop: 8,
        '&:img': {
            maxWidth: '100%',
        },
    },
    brand: {
        variant: 'caption',
        fontSize: 14,
        marginLeft: theme.spacing(2),
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },
    },
    menuButton: {
        position: 'absolute',
        right: 0,
        marginLeft: -12,
        marginRight: 20,
        cursor: 'pointer',
        color: GRAY,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    languageButton: {
        position: 'absolute',
        right: 80,
        height: 10,
        width: 10,
        color: GRAY,
        border: '2px solid white',
        fontSize: '1rem',
        fontWeight: 'bold',
        paddingBottom: theme.spacing(2),
        '&:hover': {
            border: `2px solid ${theme.palette.secondary.main}`,
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down('sm')]: {
            height: 20,
            width: 30,
            fontSize: '0.8rem',
        },
    },
    menuRow: {
        color: GRAY,
        '&:hover *': {
            color: theme.palette.primary.main + '!important'
        }
    }
}));

export default function TopMenuComponent({handleLanguageChanged, language}) {

    const classes = useStyles();
    const [mainMenuOpen, setMainMenuOpen] = useState(false);
    const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);

    const customerStyles = useSelector(state => state.commonState.customerStyles);

    const handleLanguageMenu = (event) => {
        event ? setLanguageMenuAnchor(event.currentTarget) : setLanguageMenuAnchor(null);
    };

    const handleMainMenu = () => setMainMenuOpen(!mainMenuOpen);

    return (
        <div>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.logoContainer}>
                        <img alt={customerStyles?.header_data?.logo.alt}
                             src={customerStyles?.header_data?.logo.src}
                             className={classes.imageHeader}/>
                    </div>
                    <IconButton
                        color="primary"
                        aria-label="Language"
                        className={classes.languageButton}
                        onClick={handleLanguageMenu}>
                        <div>
                            <p>{I18N_CONFIG[language].title}</p>
                        </div>
                    </IconButton>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={handleMainMenu}>
                        <MenuIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div>
                <LanguageMenu
                    anchor={languageMenuAnchor}
                    handleClose={() => handleLanguageMenu(null)}
                    handleLanguageChanged={handleLanguageChanged}/>
                <MainMenu open={mainMenuOpen} handleClose={handleMainMenu} classes={classes}/>
            </div>
        </div>
    );
}

TopMenuComponent.propTypes = {
    handleLanguageChanged: PropTypes.func.isRequired,
};

function MainMenu({open, handleClose, classes}) {
    const {keycloak, initialized} = useKeycloak();
    const history = useHistory();

    const handleLogout = () => {
        handleClose();
        history.push(ROUTE_LANDING);
        keycloak.logout();
    };

    const intl = useIntl();
    return (
        <div>
            <SwipeableDrawer
                anchor="right"
                open={open}
                onClose={handleClose}
                onOpen={() => console.log('onOpen')}>
                <div>
                    <div className={classes.menuRow}>
                        <AuthorizedElement roles={ROLES_ADMIN}>
                            <AppBarMenuItemComponent
                                onClick={handleClose}
                                text={intl.formatMessage({id: 'customer_list'})}
                                icon={<CustomersIconComponent/>}
                                link={ROUTE_CUSTOMER_LIST}
                            />
                        </AuthorizedElement>
                    </div>
                    <div className={classes.menuRow}>
                        <AppBarMenuItemComponent onClick={handleClose}
                                                 text={intl.formatMessage({id: 'user_my_profile'})}
                                                 icon={<UserIconComponent/>}
                                                 link={ROUTE_USER_PROFILE}/>
                    </div>
                    <div className={classes.menuRow}>
                        <AuthorizedElement roles={ROLES_MANAGER}>
                            <AppBarMenuItemComponent
                                onClick={handleClose}
                                text={intl.formatMessage({id: 'user_management'})}
                                icon={<SettingsIconComponent/>}
                                link={ROUTE_USER_MANAGEMENT}
                            />
                        </AuthorizedElement>
                    </div>
                </div>
                <div>
                    {initialized && keycloak.authenticated
                        ? (
                            <div className={classes.menuRow}>
                                <AppBarMenuItemComponent
                                    onClick={handleLogout}
                                    text={intl.formatMessage({id: 'logout'})}
                                    icon={<LogoutIconComponent/>}
                                    link="#"
                                />
                            </div>

                        )
                        : (
                            <div className={classes.menuRow}>
                                <AppBarMenuItemComponent
                                    onClick={() => {
                                        handleClose();
                                        keycloak.login();
                                    }}
                                    text={intl.formatMessage({id: 'login'})}
                                    icon={<LoginIconComponent/>}
                                    link="#"
                                />
                            </div>
                        )}
                </div>
            </SwipeableDrawer>
        </div>

    );
}

const LanguageMenu = ({anchor, handleClose, handleLanguageChanged}) => {
    const intl = useIntl();

    const changeLanguage = (event, language) => {
        handleClose();
        localStorage.setItem(LOCAL_STORAGE__LOCALE, language);
        handleLanguageChanged(language);
    };

    return (
        <Menu id="language-menu" anchorEl={anchor} keepMounted open={Boolean(anchor)} onClose={handleClose}>
            <div>
                {' '}
                {/* Included as anchor for the menu */}
                {LANGUAGES_ARRAY.map((language) => (
                    <AppBarMenuItemComponent
                        text={intl.formatMessage({id: I18N_CONFIG[language].label})}
                        icon={<FlagIcon flag={language}/>}
                        onClick={(event) => changeLanguage(event, language)}
                        language={language}
                        key={language}
                        link="#"
                    />
                ))}
            </div>
        </Menu>
    );
};

LanguageMenu.propTypes = {
    anchor: PropTypes.any,
    handleClose: PropTypes.func.isRequired,
    handleLanguageChanged: PropTypes.func.isRequired,
};

function AppBarMenuItemComponent(props) {
    const {
        icon, language, text, link, ...rest
    } = props;
    const ItemLink = React.forwardRef((props, ref) => <Link ref={ref} to={link} {...props} />);

    return (
        <MenuItem button {...rest} component={ItemLink}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={text}/>
        </MenuItem>
    );
}

AppBarMenuItemComponent.propTypes = {
    icon: PropTypes.object,
    language: PropTypes.string,
    text: PropTypes.string.isRequired,
    link: PropTypes.string,
};
