import React from 'react';
import TitlePageComponent from "./components/TitlePageComponent";
import BreadCrumbsComponent from "./components/BreadCrumbsComponent";
import PropTypes from "prop-types";
import {useStyles} from "./PageHeaderStyles";
import Hidden from "@material-ui/core/Hidden";
import AuthorizedElement from "../../shared/auth/AuthorizedElement";
import {BREADCRUMB_MANAGEMENT, BREADCRUMB_PROFILE, ROLES_ADMIN} from "../../../utils/constants";
import Link from "@material-ui/core/Link";
import {
    ROUTE_CAMERA_COMPLETE,
    ROUTE_CUSTOMER_COMPLETE,
    ROUTE_CUSTOMER_LIST,
    ROUTE_DEPLOYMENT_COMPLETE,
    ROUTE_DEPLOYMENT_GROUP_MOSAIC_COMPLETE,
    ROUTE_DEPLOYMENT_LIST_COMPLETE,
    ROUTE_DEPLOYMENT_MOSAIC_COMPLETE
} from "../../../utils/routes";
import {
    BREADCRUMB_CUSTOMER_lIST,
    BREADCRUMB_DEPLOYMENTS_DETAIL,
    BREADCRUMB_MOSAIC,
    BREADCRUMB_MOSAIC_AND_CAMERA,
    BREADCRUMB_SERVICE_DEPLOYMENT_LIST
} from "../../../utils/constants";
import {buildUrl} from "../../../utils/axiosRequests";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

export default function PageHeaderComponent({type}) {

    const classes = new useStyles();
    let {camera_id, customer, deployment_id, mosaic_id} = useParams();
    customer = !customer ? localStorage.getItem("customer") : customer;
    const intl = useIntl();

    let breadCrumbs = undefined;
    let title = undefined;


    const getBreadCrumbs = () => {
        switch (type) {
            case BREADCRUMB_CUSTOMER_lIST:
                title = intl.formatMessage({id: "customer_list"});
                break;
            case BREADCRUMB_SERVICE_DEPLOYMENT_LIST:
                title = intl.formatMessage({id: "installation_list"});
                breadCrumbs = (<span>
                                <AuthorizedElement roles={ROLES_ADMIN}>
                                    <Link className={classes.link} href={ROUTE_CUSTOMER_LIST}
                                          underline="none">{intl.formatMessage({id: 'customer_list'})}</Link>
                                    <span> > </span>
                                </AuthorizedElement>
                                <Link className={classes.link}
                                      href={customer ? buildUrl(ROUTE_CUSTOMER_COMPLETE, {customer_id: customer}) : ROUTE_DEPLOYMENT_LIST_COMPLETE}
                                      underline="none"> {intl.formatMessage({id: 'installation_list'})}</Link></span>);
                break;
            case BREADCRUMB_DEPLOYMENTS_DETAIL:
                title = intl.formatMessage({id: "deployment_detail"});
                breadCrumbs = (<span>
                                <AuthorizedElement roles={ROLES_ADMIN}>
                                    <Link className={classes.link} href={ROUTE_CUSTOMER_LIST}
                                          underline="none">{intl.formatMessage({id: 'customer_list'})}</Link>
                                    <span> > </span>
                                </AuthorizedElement>
                                <Link className={classes.link}
                                      href={customer ? buildUrl(ROUTE_CUSTOMER_COMPLETE, {customer_id: customer}) : ROUTE_DEPLOYMENT_LIST_COMPLETE}
                                      underline="none"> {intl.formatMessage({id: 'installation_list'})}</Link>
                                <span> > </span>
                                <Link className={classes.link}
                                      href={buildUrl(ROUTE_DEPLOYMENT_COMPLETE, {deployment_id})}
                                      underline="none">{intl.formatMessage({id: 'deployment_detail'})}</Link>
                                </span>)
                break;
            case BREADCRUMB_MOSAIC:
                title = intl.formatMessage({id: "mosaic"});
                breadCrumbs = (<span>
                 <AuthorizedElement roles={ROLES_ADMIN}>
                                    <Link className={classes.link} href={ROUTE_CUSTOMER_LIST}
                                          underline="none">{intl.formatMessage({id: 'customer_list'})}</Link>
                                    <span> > </span>
                                </AuthorizedElement>
                                <Link className={classes.link}
                                      href={customer ? buildUrl(ROUTE_CUSTOMER_COMPLETE, {customer_id: customer}) : ROUTE_DEPLOYMENT_LIST_COMPLETE}
                                      underline="none"> {intl.formatMessage({id: 'installation_list'})}</Link>

                                <span> > </span>
                                <Link className={classes.link}
                                      href={buildUrl(ROUTE_DEPLOYMENT_GROUP_MOSAIC_COMPLETE, {mosaic_id})}
                                      underline="none">{intl.formatMessage({id: 'mosaic'})}</Link>
                            </span>)
                break;
            case BREADCRUMB_MOSAIC_AND_CAMERA:
                const BREAD_CRUMBS_CAMERA = (!!camera_id) && <><span> > </span> <Link className={classes.link}
                                                                                      href={buildUrl(ROUTE_CAMERA_COMPLETE, {
                                                                                          deployment_id,
                                                                                          camera_id
                                                                                      })}
                                                                                      underline="none">{intl.formatMessage({id: 'camera'})}</Link></>;
                title = !camera_id ? intl.formatMessage({id: "mosaic"}) : intl.formatMessage({id: "camera"});
                breadCrumbs = (<span>
                 <AuthorizedElement roles={ROLES_ADMIN}>
                                    <Link className={classes.link} href={ROUTE_CUSTOMER_LIST}
                                          underline="none">{intl.formatMessage({id: 'customer_list'})}</Link>
                                    <span> > </span>
                                </AuthorizedElement>
                                <Link className={classes.link}
                                      href={customer ? buildUrl(ROUTE_CUSTOMER_COMPLETE, {customer_id: customer}) : ROUTE_DEPLOYMENT_LIST_COMPLETE}
                                      underline="none"> {intl.formatMessage({id: 'installation_list'})}</Link>
                                <span> > </span>
                                <Link className={classes.link}
                                      href={buildUrl(ROUTE_DEPLOYMENT_COMPLETE, {deployment_id})}
                                      underline="none">{intl.formatMessage({id: 'deployment_detail'})}</Link>
                    <span> <span> > </span>
                        <Link className={classes.link}
                              href={buildUrl(ROUTE_DEPLOYMENT_MOSAIC_COMPLETE, {deployment_id})}
                              underline="none">{intl.formatMessage({id: 'mosaic'})}</Link> </span>
                    {BREAD_CRUMBS_CAMERA}
                            </span>)
                break;
            case BREADCRUMB_PROFILE:
                title = intl.formatMessage({id: "user_profile"});
                break;
            case BREADCRUMB_MANAGEMENT:
                title = intl.formatMessage({id: "user_management"});
                break;
            default:
                break;
        }
    }

    getBreadCrumbs();

    return (
        <div className={classes.root}>
            <TitlePageComponent title={title} classes={classes}/>
            <Hidden mdDown>
                <div>
                    {breadCrumbs && <BreadCrumbsComponent breadCrumbs={breadCrumbs} classes={classes}/>}
                </div>
            </Hidden>
        </div>
    )
}

PageHeaderComponent.propTypes = {
    type: PropTypes.string.isRequired
}
