import React, {useState, useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import {useIntl} from "react-intl";

import {makeStyles} from "@material-ui/core/styles";
import {styles} from "./NewUserFormStyles";
import {
    HTTP_CODE_409,
    MESSAGE_TIMEOUT,
    MIN_HEIGHT,
    ROLE_TIIVII_CLIENT_USER,
    SEVERITY_ERROR
} from "../../../utils/constants";
import Button from "@material-ui/core/Button";
import {showMessage} from "../../shared/notificationSnack/NotificationSnack";
import {useKeycloak} from '@react-keycloak/web';
import {getUserInfo, postAccessToClientService, postNewUserService} from "../../../services/commonServices";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => styles(theme, {MIN_HEIGHT}));

const NewUserFormComponent = ({handleNewUserModal, customer, handleGetUserList}) => {

    const intl = useIntl();
    const classes = useStyles();
    const {keycloak} = useKeycloak();

    const [partnerId, setPartnerId] = useState(null);

    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');

    const [successMessage, setSuccessMessage] = useState(false);
    const [failureMessage, setFailureMessage] = useState(false);
    const [alreadyExists, setAlreadyExistsMessage] = useState(false);

    const resetInputValues = () => {
        handleUsername('');
        handlePassword('');
        handleName('');
        handleDescription('');
        handleEmail('');
        handleTelephone('');
    }

    useEffect(() => {
        resetInputValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePartnerId = response => {
        setPartnerId(response.data._id)
    };

    useEffect(() => {
        if (keycloak) {
            keycloak.loadUserProfile()
                .then((profile) => {
                    getUserInfo(profile.username, handlePartnerId);
                })
                .catch((error) => showMessage(error, SEVERITY_ERROR));
        }
    }, [keycloak])

    const handleUsername = value => {
        setUsername(value)
    };

    const handlePassword = value => {
        setPassword(value)
    };

    const handleName = value => {
        setName(value)
    };

    const handleDescription = value => {
        setDescription(value)
    };

    const handleEmail = value => {
        setEmail(value)
    };

    const handleTelephone = value => {
        setTelephone(value)
    };

    const handleSetSuccessMessage = value => {
        setSuccessMessage(value)
    };

    const handleSetFailureMessage = value => {
        setFailureMessage(value)
    };

    const handleSetAlreadyExistsMessage = value => {
        setAlreadyExistsMessage(value)
    }

    const handleUpdateSuccess = response => {

        handleSetSuccessMessage(true);
        postAccessToClientService(response.data, customer._id, handleGetUserList);

        setTimeout(() => {
            handleSetSuccessMessage(false);
            resetInputValues();
            handleNewUserModal();
        }, MESSAGE_TIMEOUT)
    };

    const handleUpdateFailure = error => {

        error?.response?.status === HTTP_CODE_409 ?
            handleSetAlreadyExistsMessage(true) :
            handleSetFailureMessage(true);

        setTimeout(() => {
            error?.response?.status === HTTP_CODE_409 ?
                handleSetAlreadyExistsMessage(false) :
                handleSetFailureMessage(false);

        }, MESSAGE_TIMEOUT)
    }

    const handleCreateNewUser = () => {
        const body = {
            credentials: {
                username,
                password
            },
            description,
            roles: [ROLE_TIIVII_CLIENT_USER],
            person: {
                email,
                name,
                telephone
            },
            owned_by_partner_id: partnerId
        };

        postNewUserService(body, handleUpdateSuccess, handleUpdateFailure);
    };

    const validatePassword = () => {
        return !!username && !!password && !!name
    };

    return (
        <form className={classes.textFieldContainer}>
            <div className={classes.textFieldBlock}>
                <TextField id="user-username" type="text" required value={username}
                           label={intl.formatMessage({id: 'user_username'})}
                           className={classes.textField} onChange={e => handleUsername(e.target.value)}/>
                <TextField id="user-password" type="password" required value={password}
                           label={intl.formatMessage({id: 'user_password'})}
                           className={classes.textField} onChange={e => handlePassword(e.target.value)}/>
            </div>
            <TextField id="user-name" type="text"
                       label={intl.formatMessage({id: 'user_name'})} required value={name}
                       className={classes.textField} onChange={e => handleName(e.target.value)}/>
            <TextField id="user-description" type="text"
                       label={intl.formatMessage({id: 'user_description'})} value={description}
                       className={classes.textField} onChange={e => handleDescription(e.target.value)}/>
            <div className={classes.textFieldBlock}>
                <TextField id="user-email" type="text"
                           label={intl.formatMessage({id: 'user_email'})} value={email}
                           className={classes.textField} onChange={e => handleEmail(e.target.value)}/>
                <TextField id="user-telephone" type="text"
                           label={intl.formatMessage({id: 'user_telephone'})} value={telephone}
                           className={classes.textField} onChange={e => handleTelephone(e.target.value)}/>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    onClick={handleNewUserModal}
                    className={classes.secondaryButton}
                    classes={{disabled: classes.disabled}}>
                    {intl.formatMessage({id: 'close'})}
                </Button>
                <Button
                    onClick={handleCreateNewUser}
                    disabled={!validatePassword()}
                    className={classes.primaryButton}
                    classes={{disabled: classes.disabled}}>
                    {intl.formatMessage({id: 'user_add'})}
                </Button>
            </div>
            {successMessage && <Typography
                variant="subtitle2"
                color="secondary">
                {intl.formatMessage({id: 'user_new_success'})}
            </Typography>}
            {failureMessage && <Typography
                variant="subtitle2"
                color="secondary">
                {intl.formatMessage({id: 'user_new_error'})}
            </Typography>}
            {alreadyExists && <Typography
                variant="subtitle2"
                color="secondary">
                {intl.formatMessage({id: 'user_new_already_exists'})}
            </Typography>}
        </form>
    )
};

export default NewUserFormComponent;