import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import AbstractTable from '../../../../../../../shared/abstractTable/AbstractTable';
import {GRAY} from "../../../../../../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    headerButtonContainer: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    headerButton: {
        padding: "4px !important",
        color: 'white',
        width: 35,
        height: 35,
        '&.Mui-disabled': {
            color: theme.palette.disabled.light
        },
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    cameraHeaderButton: {
        backgroundColor: theme.palette.white.main,
        padding: "4px !important",
        color: GRAY,
        width: 35,
        height: 35
    },
    cameraHeaderButtonBackground: {
        position: 'absolute',
        width: 35,
        height: 35,
        backgroundColor: theme.palette.white.main,
        zIndex: -1
    },
    cameraHeaderButtonBackgroundDisabled: {
        position: 'absolute',
        width: 35,
        height: 35,
        backgroundColor: 'transparent',
        zIndex: -1
    },
    button: {
        height: 32,
    },
    ellipsis: {
        maxWidth: 150,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    checkboxChecked: {
        color: `${theme.palette.primary.main} !important`
    },
    checkboxUnchecked: {
        color: GRAY
    }
}));

const CamerasTable = ({customerCameras, index, groups, handleSetGroups, readMosaic}) => {

    const intl = useIntl();
    const classes = useStyles();

    const [filterButtonDisabled, setFilterButtonDisabled] = useState(false);
    const [selectedCameras, setSelectedCameras] = useState([]);

    const customerCamerasIds = customerCameras.map(camera => camera._id);

    useEffect(() => {
        if (groups[index].length) {
            setSelectedCameras(groups[index])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, groups[index]]);

    useEffect(() => {
        const array = [...groups];
        array[index] = selectedCameras;
        handleSetGroups(array);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCameras])

    useEffect(() => {
            setFilterButtonDisabled(selectedCameras.length === 0)

    }, [filterButtonDisabled, selectedCameras])

    const isCameraSelected = (camera) => {
        return !!selectedCameras.find(item => item === camera._id)
    };

    const unselectCamera = (camera) => {
        const cameraArray = selectedCameras.filter(cam => cam !== camera._id);
        setSelectedCameras(cameraArray);
    };

    const selectCamera = camera => {
        const cameraArray = [...selectedCameras];
        cameraArray.push(camera._id);
        setSelectedCameras(cameraArray);
    };

    const handleSelectedCameras = (camera) => {
        isCameraSelected(camera) ? unselectCamera(camera) : selectCamera(camera);
    };

    const allCamerasSelected = () => {
        return customerCameras.toString() === selectedCameras.toString() || customerCameras.length === selectedCameras.length;
    };

    const handleAllCameras = () => {
        allCamerasSelected() ? setSelectedCameras([]) : setSelectedCameras(customerCamerasIds);
    };

    const CAMERA_DESCRIPTION = intl.formatMessage({id: 'camera_description'});
    const CAMERA_TYPE = intl.formatMessage({id: 'camera_type'});
    const CAMERA_MODEL = intl.formatMessage({id: 'camera_model'});

    return (
        <>
            {customerCameras && <AbstractTable
                key={customerCameras.length}
                columns={[
                    {
                        title: !readMosaic ? <Checkbox
                            checked={!!allCamerasSelected()}
                            onChange={() => handleAllCameras()}
                            inputProps={{'aria-label': 'primary checkbox'}}
                            color={!!allCamerasSelected() ? 'primary' : 'secondary'}
                        /> : '',
                        render: (rowData) => (
                            !readMosaic ? <Checkbox
                                key={rowData._id}
                                checked={!!isCameraSelected(rowData)}
                                onChange={() => handleSelectedCameras(rowData)}
                                inputProps={{'aria-label': 'primary checkbox'}}
                                className={!!isCameraSelected(rowData) ? classes.checkboxChecked : classes.checkboxUnchecked}
                            /> : (isCameraSelected(rowData) ? <VideocamRoundedIcon color={"primary"}/> : '')
                        ),
                        sorting: false,
                        draggable: false,
                        headerStyle: {
                            textAlign: 'center',
                            minWidth: 10,
                            padding: '0px !important'
                        },
                        cellStyle: {
                            textAlign: 'center',
                            minWidth: 10
                        },
                    },
                    {
                        title: CAMERA_DESCRIPTION,
                        field: 'description',
                        render: (rowData) => <Typography>{rowData.description}</Typography>,
                    },
                    {
                        title: CAMERA_TYPE,
                        field: 'type',
                        render: (rowData) => (
                            <Typography>
                                {rowData.type}
                                {' '}
                                {rowData.use}
                            </Typography>
                        ),
                    },
                    {
                        title: CAMERA_MODEL,
                        field: 'type',
                        render: (rowData) => (
                            <Typography>
                                {rowData.brand}
                                {' '}
                                {rowData.model}
                            </Typography>
                        ),
                    }
                ]}
                data={customerCameras}
                options={{
                    showEmptyDataSourceMessage: true,
                    doubleHorizontalScroll: false,
                    pageSize: customerCameras.length < 5 && customerCameras.length > 0 ? customerCameras.length : 5,
                    pageSizeOptions: [customerCameras.length < 5 && customerCameras.length > 0 ? customerCameras.length : 0, 5, 10, 25, 50],
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: GRAY,
                        color: 'white',
                        fontWeight: 'bold'
                    },
                    rowStyle: () => ({
                        padding: '0px !important'
                    })
                }}
                style={{boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)'}}
            />}
        </>
    );
};


export default CamerasTable;
