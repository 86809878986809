import React from 'react';
import {useIntl} from "react-intl";
import Card from "@material-ui/core/Card";
import {CardContent, CardHeader, Typography} from "@material-ui/core";

const UserInfoComponent = ({userData, classes}) => {

    const intl = useIntl();

    return (
        <Card className={classes.cardRoot}>
            <CardHeader title={intl.formatMessage({id: "user_user_info"})} className={classes.cardHeader}/>
            <CardContent className={classes.cardContent}>
                <div className={classes.infoContainer}>
                    <div>
                        <Typography>
                            {intl.formatMessage({id: "user_username"})}
                        </Typography>
                        <Typography className={classes.title}>
                            {userData.username}
                        </Typography>
                    </div>
                    <div>
                        <Typography>
                            {intl.formatMessage({id: "user_firstName"})}
                        </Typography>
                        <Typography className={classes.title}>
                            {userData.firstName}
                        </Typography>
                    </div>
                    <div>
                        <Typography>
                            {intl.formatMessage({id: "user_lastName"})}
                        </Typography>
                        <Typography className={classes.title}>
                            {userData.lastName}
                        </Typography>
                    </div>
                    <div>
                        <Typography>
                            {intl.formatMessage({id: "user_role"})}
                        </Typography>
                        <Typography className={classes.title}>
                            {userData.role}
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
};

export default UserInfoComponent;