import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './CustomersIconStyles';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';

const useStyles = makeStyles((theme) => styles(theme));

export default function CustomersIconComponent() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <PeopleAltRoundedIcon className={classes.img} />
    </div>
  );
}
