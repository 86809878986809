import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {styles} from './LogoutStyles';
import NoMeetingRoomRoundedIcon from '@material-ui/icons/NoMeetingRoomRounded';

const useStyles = makeStyles((theme) => styles(theme));

export default function LogoutIconComponent() {

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <NoMeetingRoomRoundedIcon className={classes.img}/>
        </div>
    );
}
