import React from 'react';
import MaterialTable from 'material-table';

import Search from '@material-ui/icons/Search';
import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import Delete from '@material-ui/icons/Delete';
import DetailPanel from '@material-ui/icons/ChevronRight';
import Edit from '@material-ui/icons/Edit';
import Export from '@material-ui/icons/SaveAlt';
import Filter from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import NextPage from '@material-ui/icons/SkipNext';
import PreviousPage from '@material-ui/icons/SkipPrevious';
import SortArrow from '@material-ui/icons/ArrowUpward';
import ThirdStateCheck from '@material-ui/icons/IndeterminateCheckBox';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useIntl } from 'react-intl';

const icons = {
  Add,
  Check,
  Clear,
  Delete,
  DetailPanel,
  Edit,
  Export,
  Filter,
  FirstPage,
  LastPage,
  NextPage,
  PreviousPage,
  ResetSearch: Clear,
  Search,
  SortArrow,
  ThirdStateCheck,
  ViewColumn,
};

const localization = {
  en: {
    pagination: {
      labelRowsSelect: 'rows', // rows
      labelRowsPerPage: 'rows per page:', // Rows per page:
      labelDisplayedRows: '{from}-{to} of {count}', // {from}-{to} of {count}
      firstAriaLabel: 'First page', // First Page
      firstTooltip: 'First page', // First Page
      previousAriaLabel: 'Previous page', // Previous Page
      previousTooltip: 'Previous page', // Previous Page
      nextAriaLabel: 'Next page', // Next Page
      nextTooltip: 'Next page', // Next Page
      lastAriaLabel: 'Last page', // Last Page
      lastTooltip: 'Last page', // Last Page
    },
    toolbar: {
      nRowsSelected: '{0} rows(s) selected', // {0} row(s) selected
      showColumnsTitle: 'Show columns', // Show Columns
      showColumnsAriaLabel: 'Show columns', // Show Columns
      exportTitle: 'Export', // Export
      exportAriaLabel: 'Export', // Export
      exportName: 'Export as CSV', // Export as CSV
      exportCSVName: 'Export as CSV', // Export as CSV
      exportPDFName: 'Export as PDF', // Export as PDF
      searchTooltip: 'Search', // Search
      searchPlaceholder: 'Search', // Search
    },
    header: {
      actions: 'Actions', // Actions
    },
    body: {
      emptyDataSourceMessage: 'No records to display', // No records to display
      filterRow: {
        filterTooltip: 'Filter', // Filter
      },
    },
  },
  es: {
    pagination: {
      labelRowsSelect: 'filas', // rows
      labelRowsPerPage: 'filas por página:', // Rows per page:
      labelDisplayedRows: '{from}-{to} de {count}', // {from}-{to} of {count}
      firstAriaLabel: 'Primera página', // First Page
      firstTooltip: 'Primera página', // First Page
      previousAriaLabel: 'Página anterior', // Previous Page
      previousTooltip: 'Página anterior', // Previous Page
      nextAriaLabel: 'Siguiente página', // Next Page
      nextTooltip: 'Siguiente página', // Next Page
      lastAriaLabel: 'Última página', // Last Page
      lastTooltip: 'Última página', // Last Page
    },
    toolbar: {
      nRowsSelected: '{0} Columna(s) seleccionadas', // {0} row(s) selected
      showColumnsTitle: 'Mostrar columnas', // Show Columns
      showColumnsAriaLabel: 'Mostrar columnas', // Show Columns
      exportTitle: 'Exportar', // Export
      exportAriaLabel: 'Exportar', // Export
      exportName: 'Exportar a CSV', // Export as CSV
      exportCSVName: 'Exportar a CSV', // Export as CSV
      exportPDFName: 'Exportar a PDF', // Export as PDF
      searchTooltip: 'Buscar', // Search
      searchPlaceholder: 'Buscar', // Search
    },
    header: {
      actions: 'Acciones', // Actions
    },
    body: {
      emptyDataSourceMessage: 'No hay datos para mostrar', // No records to display
      filterRow: {
        filterTooltip: 'Filtro', // Filter
      },
    },
  },
  gl: {
    pagination: {
      labelRowsSelect: 'filas', // rows
      labelRowsPerPage: 'filas por páxina:', // Rows per page:
      labelDisplayedRows: '{from}-{to} de {count}', // {from}-{to} of {count}
      firstAriaLabel: 'Primeira páxina', // First Page
      firstTooltip: 'Primeira páxina', // First Page
      previousAriaLabel: 'Páxina anterior', // Previous Page
      previousTooltip: 'Páxina anterior', // Previous Page
      nextAriaLabel: 'Seguinte páxina', // Next Page
      nextTooltip: 'Seguinte páxina', // Next Page
      lastAriaLabel: 'Última páxina', // Last Page
      lastTooltip: 'Última páxina', // Last Page
    },
    toolbar: {
      nRowsSelected: '{0} Columna(s) seleccionadas', // {0} row(s) selected
      showColumnsTitle: 'Amosar columnas', // Show Columns
      showColumnsAriaLabel: 'Amosar columnas', // Show Columns
      exportTitle: 'Exportar', // Export
      exportAriaLabel: 'Exportar', // Export
      exportName: 'Exportar a CSV', // Export as CSV
      exportCSVName: 'Exportar a CSV', // Export as CSV
      exportPDFName: 'Exportar a PDF', // Export as PDF
      searchTooltip: 'Buscar', // Search
      searchPlaceholder: 'Buscar', // Search
    },
    header: {
      actions: 'Accións', // Actions
    },
    body: {
      emptyDataSourceMessage: 'Non hai datos que amosar', // No records to display
      filterRow: {
        filterTooltip: 'Filtro', // Filter
      },
    },
  },
};

export default function AbstractTable(props) {
  const intl = useIntl();

  return (
    <MaterialTable localization={localization[intl.locale]} {...props} />
  );
}

AbstractTable.defaultProps = {
  icons,
};
