import React from "react";
import {IconButton} from "@material-ui/core";
import Replay10RoundedIcon from "@material-ui/icons/Replay10Rounded";
import FastRewindRoundedIcon from "@material-ui/icons/FastRewindRounded";
import PauseRoundedIcon from "@material-ui/icons/PauseRounded";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import FastForwardRoundedIcon from "@material-ui/icons/FastForwardRounded";
import Forward10RoundedIcon from "@material-ui/icons/Forward10Rounded";
import Tooltip from "@material-ui/core/Tooltip";
import {useIntl} from "react-intl";
import {FF_CONTROL_LABEL, REWIND_CONTROL_LABEL} from "../../../../utils/constants";

const VideoControlsComponent = ({classes, handleGoToNewPosition, handleRewind, handlePlaying, handleFastForward, playing}) => {

    const intl = useIntl();

    return (
        <>
            <Tooltip title={intl.formatMessage({id: "video_control_jump_back"})}>
                <IconButton onClick={() => handleGoToNewPosition(REWIND_CONTROL_LABEL)}
                            classes={{root: classes.iconButton}}>
                    <Replay10RoundedIcon color="primary"/>
                </IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage({id: "video_control_rewind_hold"})}>
                <IconButton onMouseDown={() => handleRewind()} onMouseUp={() => handleRewind()}
                            classes={{root: classes.iconButton}}>
                    <FastRewindRoundedIcon color="primary"/>
                </IconButton>
            </Tooltip>
            {playing ?
                <Tooltip title={intl.formatMessage({id: "video_control_pause"})}>
                    <IconButton onClick={() => handlePlaying()} classes={{root: classes.iconButton}}>
                        <PauseRoundedIcon color="primary"/>
                    </IconButton>
                </Tooltip> :
                <Tooltip title={intl.formatMessage({id: "video_control_play"})}>
                    <IconButton onClick={() => handlePlaying()} classes={{root: classes.iconButton}}>
                        <PlayArrowRoundedIcon color="primary"/>
                    </IconButton>
                </Tooltip>
            }
            <Tooltip title={intl.formatMessage({id: "video_control_forward_hold"})}>
                <IconButton onMouseDown={() => handleFastForward()} onMouseUp={() => handleFastForward()}
                            classes={{root: classes.iconButton}}>
                    <FastForwardRoundedIcon color="primary"/>
                </IconButton>
            </Tooltip>
            <Tooltip title={intl.formatMessage({id: "video_control_jump_forward"})}>
                <IconButton onClick={() => handleGoToNewPosition(FF_CONTROL_LABEL)}
                            classes={{root: classes.iconButton}}>
                    <Forward10RoundedIcon color="primary"/>
                </IconButton>
            </Tooltip>
        </>
    )
};

export default VideoControlsComponent;