import axios from 'axios';
import {turnOffLoadingActionCreator, turnOnLoadingActionCreator} from '../actions/commonActions';
import store from '../store';
import keycloak from './keycloak';
import {showMessage, showMessageInt} from '../components/shared/notificationSnack/NotificationSnack';
import {HTTP_CODE_401, HTTP_CODE_422, SEVERITY_ERROR} from './constants';


const getOptions = (config, headers) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(!!keycloak && !!keycloak.token) ? keycloak.token : undefined}`,
        },
        ...config,
    };
    if (headers) {
        const keys = Object.keys(headers);
        keys.forEach(key => options.headers[key] = headers[key])
    }
    return options;
};

const resolvePromise = (response, loading, responseThen) => {
    if (loading) {
        store.dispatch(turnOffLoadingActionCreator());
    }
    if (responseThen) {
        responseThen(response);
    }
};

const rejectPromise = (error, loading, responseCatch) => {
    if (loading) {
        store.dispatch(turnOffLoadingActionCreator());
    }
    if (error.response && error.response.status === HTTP_CODE_401 && !!keycloak) {
        keycloak.login();
    }
    if (responseCatch) {
        responseCatch(error, SEVERITY_ERROR);
    } else if (error.response && error.response.status === HTTP_CODE_422) {
        showMessageInt(error.response.data.error);
    } else {
        showMessage(error);
    }
};

export const put = (url, data, config, loading = true, responseThen, responseCatch, headers) => {
    if (loading) {
        store.dispatch(turnOnLoadingActionCreator());
    }
    axios.put(url, data, getOptions(config, headers))
        .then((response) => resolvePromise(response, loading, responseThen))
        .catch((error) => rejectPromise(error, loading, responseCatch));
};

export const post = (url, data, config, loading, responseThen = undefined, responseCatch = undefined, headers) => {
    if (loading) {
        store.dispatch(turnOnLoadingActionCreator());
    }
    axios.post(url, data, getOptions(config, headers))
        .then((response) => resolvePromise(response, loading, responseThen))
        .catch((error) => rejectPromise(error, loading, responseCatch));
};

export const patch = (url, data, config, loading, responseThen = undefined, responseCatch = undefined, headers) => {
    if (loading) {
        store.dispatch(turnOnLoadingActionCreator());
    }
    axios.patch(url, data, getOptions(config, headers))
        .then((response) => resolvePromise(response, loading, responseThen))
        .catch((error) => rejectPromise(error, loading, responseCatch));
};

export const get = (url, config, loading = true, responseThen, responseCatch, headers) => {
    if (loading) {
        store.dispatch(turnOnLoadingActionCreator());
    }
    axios.get(url, getOptions(config, headers))
        .then((response) => resolvePromise(response, loading, responseThen))
        .catch((error) => rejectPromise(error, loading, responseCatch));
};
export const erase = (url, config, loading, responseThen = undefined, responseCatch = undefined, headers) => {
    if (loading) {
        store.dispatch(turnOnLoadingActionCreator());
    }
    axios.delete(url,  getOptions(config, headers))
        .then((response) => resolvePromise(response, loading, responseThen))
        .catch((error) => rejectPromise(error, loading, responseCatch));
};

export const deleteRequest = (url, config, loading, responseThen = undefined, responseCatch = undefined, headers) => {
    if (loading) {
        store.dispatch(turnOnLoadingActionCreator());
    }
    axios.delete(url, getOptions(config, headers))
        .then((response) => resolvePromise(response, loading, responseThen))
        .catch((error) => rejectPromise(error, loading, responseCatch));
};

export const getPromise = (url) => axios.get(url);

export const getCancelToken = () => axios.CancelToken;
export const getCancelRequest = error => axios.isCancel(error)
/**
 * Dado un valor para url = 'https://bert.tiivii.com/api/studio/:id/event/:event_id' y un valor para variables = {
 *   id: "un_id", event_id: "un_event_id"}, devolvería https://bert.tiivii.com/api/studio/un_id/event/un_event_id
 * @param url Url con placeholders
 * @param variables objeto con las substituciones a realizar
 * @returns {string} con la url generada a partir de las substituciones.
 */
export const buildUrl = (url, variables) => url.replace(/:(\w+)/g, (match, p1) => (p1 in variables ? variables[p1] : match));
