import { Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

export default function TitlePageComponent({ title, classes }) {
  return (
    <Typography variant="h4" className={classes.titlePage}>{title}</Typography>
  );
}

TitlePageComponent.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};
