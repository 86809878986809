import React, {useRef} from 'react';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import AlertDialog from './components/AlertDialog';
import DownloadSettingsComponent
    from '../cameraCardComponent/components/DownloadSettingsComponent';
import ShowInfoCameraComponent from '../cameraCardComponent/components/ShowInfoCameraComponent';
import {
    ALERT_DIALOG_TYPE_ACTIVATE,
    ALERT_DIALOG_TYPE_DOWNLOAD,
    ALERT_DIALOG_TYPE_INFO,
} from './AlertContainerConstants';
import ActivateCamera from '../cameraCardComponent/components/ActivateCamera';

export default function AlertContainer(props) {
    const intl = useIntl();
    const {
        cameraActive, componentToRender, changeComponentToRender, filters, camera, changeCameraState
    } = props;
    const downloadFunction = useRef(null);
    const onOffCameraFunction = useRef(null);

    const handleAlertContent = () => {
        const componentObject = {
            buttonCloseText: intl.formatMessage({id: 'cancel'}),
            buttonAcceptText: intl.formatMessage({id: 'accept'}),
        };

        switch (componentToRender) {
            case ALERT_DIALOG_TYPE_DOWNLOAD:
                componentObject.component = (
                    <DownloadSettingsComponent
                        filters={filters}
                        camera={camera}
                        description={camera.description}
                        ref={downloadFunction}
                        changeComponentToRender={changeComponentToRender}/>
                );
                componentObject.acceptFunction = () => downloadFunction.current.postCreateClips();
                componentObject.title = intl.formatMessage({id: 'download_video'});
                return componentObject;
            case ALERT_DIALOG_TYPE_ACTIVATE:
                componentObject.title = intl.formatMessage({id: cameraActive ? 'deactivate_camera' : 'active_camera'});
                componentObject.acceptFunction = () => onOffCameraFunction.current.handleOnOffCamera();
                componentObject.component = (
                    <ActivateCamera
                        camera_id={camera._id}
                        cameraActive={cameraActive}
                        changeCameraState={changeCameraState}
                        ref={onOffCameraFunction}
                        changeComponentToRender={changeComponentToRender}
                    />
                );
                return componentObject;
            case ALERT_DIALOG_TYPE_INFO:
                componentObject.component = <ShowInfoCameraComponent camera={camera}/>;
                componentObject.buttonCloseText = intl.formatMessage({id: 'global_buttons_close'});
                componentObject.buttonAcceptText = '';
                componentObject.title = intl.formatMessage({id: 'camera_info'});
                return componentObject;
            default:
                return componentObject;
        }
    };

    const objectToRender = componentToRender !== null ? handleAlertContent() : {
        component: null,
        acceptFunction: () => void (0),
        title: null,
        buttonCloseText: intl.formatMessage({id: 'cancel'}),
        buttonAcceptText: intl.formatMessage({id: 'accept'}),
    };

    const handleCloseDialog = () => {
        changeComponentToRender(null);
    };

    return (
        <div>
            <AlertDialog
                buttonCloseText={objectToRender.buttonCloseText}
                buttonAcceptText={objectToRender.buttonAcceptText}
                titleDialog={objectToRender.title}
                description={objectToRender.component}
                onClose={handleCloseDialog}
                dialogOnAccept={objectToRender.acceptFunction}
                openDialog={componentToRender !== null}
            />
        </div>
    );
}

AlertContainer.propTypes = {
    cameraActive: PropTypes.bool,
    componentToRender: PropTypes.string,
    changeComponentToRender: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    camera: PropTypes.object.isRequired,
    changeCameraState: PropTypes.func.isRequired
};
