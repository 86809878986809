import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {useIntl} from 'react-intl';
import {Tooltip} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CamerasTable from './components/CamerasTable';
import IconButton from "@material-ui/core/IconButton";
import {GRAY} from "../../../../../../../utils/constants";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel"
             hidden={value !== index}
             id={`scrollable-auto-tabpanel-${index}`}
             aria-labelledby={`scrollable-auto-tab-${index}`}
             {...other}>
            {value === index && (
                <Box p={3}>{children}</Box>)}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '700px',
        backgroundColor: theme.palette.background.paper
    },
    flex: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
    },
    flexTable: {
        marginTop: '-2.5rem',
        width: '107%',
    },
    verticalTabsButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    tabsDisabled: {
        pointerEvents: 'none',
    },
    mosaicIconButton: {
        color: GRAY,
        '&:hover': {
            color: theme.palette.primary.main + '!important'
        }
    }
}));

const MosaicTabsComponent = ({savedFiltersMosaic, customerCameras, groups, handleSetGroups, groupIndex, handleSetGroupIndex, newMosaic, readMosaic}) => {

    const classes = useStyles();
    const intl = useIntl();

    const handleChange = (event, newValue) => {
        handleSetGroupIndex(newValue);
    };

    const handleNewGroup = () => {
        const groupArray = [...groups];
        groupArray.push([]);

        handleSetGroups(groupArray);
        handleSetGroupIndex(groupArray.length - 1);
    };

    const handleGroupPositionsDelete = () => {
        const groupArray = [...groups];
        const filteredArray = groupArray.filter((group, index) => index !== groupIndex);

        handleSetGroups(filteredArray);
        handleSetGroupIndex(groupIndex - 1);
    };

    return (
        <div className={classes.root}>
            <div className={classes.flex}>
                {newMosaic && <div className={classes.verticalTabsButton}>
                    <Tooltip title={intl.formatMessage({id: 'filter_button_add'})}>
                        <IconButton onClick={handleNewGroup} className={classes.mosaicIconButton}>
                            <AddCircleIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={intl.formatMessage({id: 'filter_button_delete_group'})}>
                        <span>
                            <IconButton disabled={groups.length <= 1} onClick={() => handleGroupPositionsDelete()} className={classes.mosaicIconButton}>
                                <DeleteIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>}
                <AppBar position="static" color="default">
                    <Tabs
                        value={groupIndex}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example">
                        {groups?.map((item, index) => <Tab key={index}
                                                           label={`${intl.formatMessage({id: 'filter_group'})} ${index + 1}`} {...a11yProps(groupIndex)} />)}
                    </Tabs>
                </AppBar>
                <div className={classes.flexTable}>
                    {groups?.map((item, index) => <TabPanel key={index} value={groupIndex} index={index}>
                        <div className={!savedFiltersMosaic ? null : classes.tabsDisabled}>
                            <CamerasTable
                                customerCameras={customerCameras}
                                index={index}
                                groups={groups}
                                handleSetGroups={handleSetGroups}
                                readMosaic={readMosaic}/>
                        </div>
                    </TabPanel>)}
                </div>

            </div>
        </div>
    );
}

export default MosaicTabsComponent;