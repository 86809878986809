export const styles = (theme) => ({
    spinnerContainer: {
        display: 'flex', justifyContent: 'center', padding: '2rem'
    },
    radioIconUnchecked: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white.main,
        margin: '0px',
    },
    radioIconChecked: {
        color: theme.palette.white.main,
        backgroundColor: theme.palette.primary.main,
        margin: '0px',
    },
    radioButton: {
        marginLeft: 0,
        marginRight: 0,
    },
    rotate270: {
        transform: 'rotate(270deg)',
    },
    rotate180: {
        transform: 'rotate(180deg)',
    },
    rotate90: {
        transform: 'rotate(90deg)',
    },
    overlaySwitchContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    overlayFormGrid: {
        display: 'grid',
        gridTemplateColumns: '195px 1fr',
        gridTemplateRows: '50px 50px 50px auto',
    },
    overlayFormLabels: {
        paddingTop: '8px',
        gridColumn: '1 / 2',
    },
    overlayFormInputs: {
        gridColumn: '2 / 3',
    },
    overlayFormRow1: {
        gridRow: '1 / 2',
    },
    overlayFormRow2: {
        gridRow: '2 / 3',
    },
    overlayFormRow3: {
        gridRow: '3 / 4',
    },
    overlayFormRow4: {
        gridRow: '4 / 5',
    },
    radioGroup: {
        flexDirection: 'row',
        margin: '0px',
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
          },
    },
    textAreaContainer: {
        width: '80%',
    },
});
