import {MIN_HEIGHT} from "../../utils/constants";
import {tenantPrimaryButton} from "../../utils/tenantStyles";

export const styles = theme => ({
    root: {
        zIndex: 0,
        padding: theme.spacing(0, 20),
        textAlign: 'left',
        minHeight: MIN_HEIGHT,
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 2),
            marginBottom: theme.spacing(6),
        },
    },
    userContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(12)
    },
    userForm: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0, 12),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: theme.spacing(4)
        },
    },
    customerSelect: {
        minWidth: 400
    },
    actionColumn: {
        display: 'flex',
        gap: theme.spacing(3)
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    tableButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    iconButton: {
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    primaryButton: tenantPrimaryButton(theme),
    disabled: {
        backgroundColor: theme.palette.disabled.light + '!important',
        border: 0 + '!important',
        color: theme.palette.disabled.main + '!important',
        minWidth: 200,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center'
    }
});
