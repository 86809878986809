import React from 'react';

import {useKeycloak} from '@react-keycloak/web';
import {useHistory} from 'react-router-dom';

import ErrorComponent from '../errorComponent/ErrorComponent';
import {hasRoles} from '../../../utils/keycloak';
import EmptyComponent from '../../common/emptyComponent/EmptyComponent';
import LandingComponent from "../../landingComponent/LandingComponent";
import {ROUTE_DEPLOYMENT_LIST} from "../../../utils/routes";
import {ROUTE_NAME_CUSTOMER_LIST} from "../../../utils/constants";

const withAuthorization = (roles) => (Component) => {
    const WithAuthorization = (props) => {
        const {keycloak, initialized} = useKeycloak();
        let history = useHistory();

        const isAuthorized = () => {
            if (keycloak && roles) {
                return hasRoles(roles);
            }
            return false;
        };
        if (!initialized) {
            return <EmptyComponent/>;
        }
        if (!keycloak.authenticated) {
            return <LandingComponent/>;
        }
        if (isAuthorized()) {
            return <Component {...props} />;
        }

        if (window.location.pathname.split('/')[2] === ROUTE_NAME_CUSTOMER_LIST && !isAuthorized()) {
            history.replace(ROUTE_DEPLOYMENT_LIST);
        }

        return <ErrorComponent error="error_403"/>;
    };
    return WithAuthorization;
};

export default withAuthorization;
