import React from "react";
import {styles} from "./Button.module.js";
import PropTypes from "prop-types";
import { BUTTON } from "./buttonConstants";
import { makeStyles } from "@material-ui/core";

function ButtonComponent({
  onClick = () => {},
  variant = BUTTON.VARIANT_PRIMARY,
  text,
  type = BUTTON.TYPE_BUTTON,
  icon,
  disabled = false,
  hiddenTextMobileVersion = false,
  hiddenIcon = false,
  className = "",
  setSelectedButton = "",
}) {
  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();
  return (
    <button
      className={`${classes.button} ${classes[variant]} ${!!disabled && classes.disabled} ${className}`}
      disabled={disabled}
      onClick={
        !!setSelectedButton
          ? () => {
              onClick();
              setSelectedButton(text);
            }
          : onClick
      }
      type={type}
    >
      {!!icon ? <span className={`${hiddenIcon && classes.hiddenIcon}`}>{icon}</span> : null}
      {!!text ? <span className={`${hiddenTextMobileVersion && classes.hiddenTextMobilButton} `}> {text} </span> : null}
    </button>
  );
}

export default ButtonComponent;

ButtonComponent.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf([BUTTON.TYPE_BUTTON, BUTTON.TYPE_SUBMIT, BUTTON.TYPE_RESET]),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    BUTTON.VARIANT_PRIMARY,
  ]),
};
