import React from 'react';
import * as Hls from 'hls.js';
import PropTypes from "prop-types";
import {FF_REWIND_VALUE_SECONDS, TIME_FF_REWIND, TIME_SECOND, VIDEO_NETWORK_ERROR_TIMEOUT, VIDEO_HLS_ERROR_TIMEOUT} from "../../utils/constants";

const INITIAL_STATE = {
    error: null,
    poster: null
};

class VideoHlsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
        this.hls = null;
        this.errorHandlingInProgress = false;
        this.networkErrorHandlingInProgress = false;
    }

    componentDidMount() {
        this.createHls();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {playing, fastForward, rewind, video, storeSeconds, videoRef} = this.props;

        if (prevProps.video !== video) {
            this.hls.destroy();
            clearInterval(this.interval);
            clearInterval(this.initialInterval);
            this.createHls();
        }

        if (prevProps.playing !== playing && !this.state.error) {
            this.handlePlay();
            if (playing) {
                this.interval = setInterval(this.updateCurrentTimestamp, TIME_SECOND);
            } else {
                clearInterval(this.interval)
                clearInterval(this.initialInterval)
            }
        }

        if (prevProps.rewind !== rewind) {
            rewind ?
                this.rewindInterval = setInterval(this.rewindVideo, TIME_FF_REWIND) :
                clearInterval(this.rewindInterval)
        }

        if (prevProps.fastForward !== fastForward) {
            fastForward ?
                this.fastForwardInterval = setInterval(this.fastForwardVideo, TIME_FF_REWIND) :
                clearInterval(this.fastForwardInterval)
        }

        if (prevProps.storeSeconds !== storeSeconds) {
            videoRef.current.currentTime = storeSeconds
        }
    }


    componentWillUnmount() {
        const {videoRef} = this.props;
        this.hls.destroy();
        videoRef.current.removeEventListener('play', this.onPlay(true));
        videoRef.current.removeEventListener('pause', this.onPlay(false));
        clearInterval(this.interval);
        clearInterval(this.initialInterval);
        clearInterval(this.rewindInterval);
        clearInterval(this.fastForwardInterval);
    }

    createHls() {
        const {authorization, video, playing, live, storeSeconds, videoRef} = this.props;

        if (Hls.isSupported) {
            this.hls = new Hls({
                initialLiveManifestSize: 3,
                liveSyncDurationCount: 2,
                liveMaxLatencyDurationCount: 5,
                maxLiveSyncPlaybackRate: 2,
                xhrSetup: function (xhr) {
                    //xhr.withCredentials = true; // do send cookie
                    xhr.setRequestHeader("authorization", `Bearer ${authorization.idToken}`);
                }
            });

            videoRef.current.addEventListener('play', this.onPlay(true));
            videoRef.current.addEventListener('pause', this.onPlay(false));
            this.handleVideoHls(this.hls, video);
            if (playing && !live) {
                this.initialInterval = setInterval(this.updateCurrentTimestamp, TIME_SECOND);
            }
            if (storeSeconds !== 0) {
                videoRef.current.currentTime = storeSeconds
            }
        } else {
            console.error('Hls is not supported')
        }
    }

    handleError = (hls) => {
        const {poster} = this.props;
        this.setState({poster: poster, error: true});
        hls.startLoad();
        hls.recoverMediaError();
        this.forceUpdate();
        this.errorHandlingInProgress = false;
    };

    handleNetworkError = () => {
        const {poster} = this.props;
        this.setState({poster: poster, error: true});
        this.networkErrorHandlingInProgress = false;
    }

    handleVideoHls = (hls, url) => {
        const {videoRef} = this.props;
        hls.attachMedia(videoRef.current);
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            hls.loadSource(url);
            hls.on(Hls.Events.LEVEL_LOADED, (event, level) => {
                this.setState({error: false, poster: null});
                return level.details.fragments[0].programDateTime;
            });
        })
        hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal && data.type === Hls.ErrorTypes.NETWORK_ERROR) {
                if (!this.networkErrorHandlingInProgress) {
                    this.networkErrorHandlingInProgress = true;
                    setTimeout(() => this.handleNetworkError(), VIDEO_NETWORK_ERROR_TIMEOUT)
                }
            } else {
                if (!this.errorHandlingInProgress) {
                    this.errorHandlingInProgress = true;
                    setTimeout(() => this.handleError(hls), VIDEO_HLS_ERROR_TIMEOUT)
                }
            }
        })
    };

    onPlay = () => {
    };

    handlePlay = () => {
        const {playing, videoRef} = this.props
        videoRef.current.paused && playing ? videoRef.current.play().catch(() => {
            if (!playing) {
                videoRef.current.pause();
            }
        }) : videoRef.current.pause();
    };

    updateCurrentTimestamp = () => {
        const {setSeconds, live, videoRef} = this.props;
        if (!!videoRef.current && videoRef.current.currentTime && !live) {
            this.forceUpdate()
            setSeconds(videoRef.current.currentTime)
        }
    };

    rewindVideo = () => {
        const {setSeconds, videoRef} = this.props;
        setSeconds(videoRef.current.currentTime - FF_REWIND_VALUE_SECONDS)
    };

    fastForwardVideo = () => {
        const {setSeconds, videoRef} = this.props;
        setSeconds(videoRef.current.currentTime + FF_REWIND_VALUE_SECONDS)
    };


    render() {
        const {mute, playing, videoSize, fullScreen, videoRef, poster} = this.props;

        return (
            <video
                ref={videoRef}
                width={'100%'}
                height={fullScreen ? '100%' : videoSize}
                controls={false}
                poster={poster}
                allow={playing ? "autoplay" : "pause"}
                autoPlay={playing}
                muted={mute}/>
        )
    }
}

VideoHlsComponent.propTypes = {
    video: PropTypes.string,
    playing: PropTypes.bool.isRequired,
    poster: PropTypes.string.isRequired,
    mute: PropTypes.bool.isRequired
};

export default VideoHlsComponent;
