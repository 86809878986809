import React, {useCallback, useEffect, useState} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {makeStyles} from '@material-ui/core/styles';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Typography} from '@material-ui/core';
import {useIntl} from 'react-intl';
import Grid from '@material-ui/core/Grid';
import CameraTableComponent from './components/cameraTableComponent/CameraTableComponent';
import DeploymentCardComponent from './components/deploymentCardComponent/DeploymentCardComponent';
import {getDeployments} from '../../services/commonServices';
import PageHeaderComponent from '../common/pageHeaderComponent/PageHeaderComponent';
import {BREADCRUMB_DEPLOYMENTS_DETAIL, ROLES_ALL} from '../../utils/constants';
import {fetchDeploymentCameras} from '../../actions/commonActions';
import withAuthorization from '../shared/auth/withAuthorization';
import EmptyComponent from '../common/emptyComponent/EmptyComponent';
import {GRAY} from "../../utils/constants";
import {getRecordingPolicy} from '../../services/commonServices';
import AlertsContainer from "../deploymentListComponent/components/alertsContainer/AlertsContainer";
import { tenantFiltersMosaicButton } from '../../utils/tenantStyles';
import MapComponent from './components/mapComponent/MapComponent';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 0,
        padding: theme.spacing(0, 20),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 6),
            marginBottom: theme.spacing(6),
        },
    },
    infoTables: {
        marginBottom: theme.spacing(2)
    },
    subtitle: {
        marginBottom: theme.spacing(2),
        color: GRAY,

    },
    cameraHeader: {
        display: 'flex',

    },
    flexTitle: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(-10),
    },
    deploymentDetails: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(3),
        },
    },
    tableContainer: {
        width: '100%',
        marginBottom: theme.spacing(12),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6)
    },
    mapContainer: {
        width: '100%',
        marginBottom: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6)
    },
    primaryButton: tenantFiltersMosaicButton(theme),
  
}));

const DeploymentComponent = (locale) => {
    const [recordingPolicy, setRecordingPolicy] = useState(null);
    const [deployment, setDeployment] = useState(null);
    const [deploymentLocation, setDeploymentLocation] = useState(null);
    const [noData, setNodata] = useState(true);
    const dispatch = useDispatch();
    const getState = useSelector((state) => state.commonState);
    const {keycloak, initialized} = useKeycloak();
    const classes = useStyles();
    const {deployment_id} = useParams();
    const customerId = localStorage.getItem('customer');

    const intl = useIntl();

    const getPolicy = useCallback(() => {
        const getRecordingPolicySuccess = (response) => {
            setRecordingPolicy(response.data);
        };
        getRecordingPolicy(deployment_id, undefined, getRecordingPolicySuccess);
    }, [deployment_id]);

    const getFilteredCameras = () => getState.filteredCameraList[deployment_id] || [];

    const handleSetDeploymentState = data => {
        setDeployment(data);
        setDeploymentLocation(data?.place?.geo);
        setNodata(false);
    };

    const callDeployments = useCallback(() => {
        if (initialized && keycloak) {
            getDeployments(keycloak, deployment_id, handleSetDeploymentState);
        }
    }, [initialized, keycloak, deployment_id]);

    const callCameras = useCallback(() => {
        if (deployment_id) {
            dispatch(fetchDeploymentCameras(deployment_id));
        }
    }, [deployment_id, dispatch]);

    useEffect(() => {
        callDeployments();
    }, [callDeployments]);

    useEffect(() => {
        callCameras();
    }, [callCameras]);

    useEffect(() => {
        getPolicy();
    }, [getPolicy])


    return (
        <div>
            <Grid className={classes.root}>
                <PageHeaderComponent type={BREADCRUMB_DEPLOYMENTS_DETAIL}/>
                {deployment ? (
                    <>
                        <Grid container spacing={6} className={classes.infoTables}>
                            <Grid item md={12} lg={4} className={classes.deploymentDetails}>
                                <Typography
                                    variant="h5"
                                    align="left"
                                    className={classes.subtitle}>
                                    {intl.formatMessage({id: 'details'})}
                                </Typography>
                                <DeploymentCardComponent deployment={deployment} recordingPolicy={recordingPolicy}/>
                            </Grid>
                            <Grid item md={12} lg={8}>
                                <div className={`${classes.cameraHeader} ${classes.subtitle}`}>
                                    <Typography variant="h5">
                                        {intl.formatMessage({id: 'deployment_camera_map'})}
                                    </Typography>
                                </div>
                                <div className={classes.mapContainer}>
                                    <MapComponent deploymentLocation={deploymentLocation} locale={locale}/>
                                </div>                                    
                                <div className={`${classes.cameraHeader} ${classes.subtitle}`}>
                                    <Typography variant="h5">
                                        {intl.formatMessage({id: 'deployment_camera_list'})}
                                    </Typography>
                                    </div>                               
                                <div className={classes.tableContainer}>
                                    <CameraTableComponent  getFilteredCameras={getFilteredCameras}/> 
                                    <AlertsContainer customerId={customerId} deploymentId={deployment_id} locale={locale}/>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                ) : <EmptyComponent noData={noData} title="no_data_deployment"/>}
            </Grid>
        </div>
    );
};

export default withAuthorization(ROLES_ALL)(DeploymentComponent);
