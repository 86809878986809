import {
    CHANGE_CAMERA_DEFAULT_SPEED_ACTION,
    CHANGE_CAMERA_SECONDARY_SPEED_ACTION,
    CHANGE_DEAD_ZONE_ACTION,
    RESET_PTZ_VALUES,
    LOAD_PTZ_PRESETS_VALUES_ACTION,
    GAMEPAD_CONNECTED_ACTION,
    STORE_VIDEO_DIMENSIONS, STORE_CONNECTED_CAMERA_ID
} from "../actions/actionTypes";
import {DEFAULT_VALUE_DEAD_ZONE, MAX_CAMERA_SPEED} from "../components/common/ptz/PtzConstants";

const INITIAL_STATE = {
    connectedCamera: null,
    presets: [],
    home: {},
    cameraDefaultSpeed: +localStorage.getItem("cameraDefaultSpeed") || MAX_CAMERA_SPEED,
    cameraSecondarySpeed: +localStorage.getItem("cameraSecondarySpeed") || MAX_CAMERA_SPEED,
    deadZone: +localStorage.getItem("deadZone") || DEFAULT_VALUE_DEAD_ZONE,
    gamepad: false,
    videoDimensions: {
        width: null,
        height: null
    }
};

function ptzState(state = INITIAL_STATE, action) {
    switch (action.type) {
        case STORE_CONNECTED_CAMERA_ID: {
            return {...state, connectedCamera: action.data}
        }
        case LOAD_PTZ_PRESETS_VALUES_ACTION: {
            const {presets, home} = action.data;
            return {...state, presets, home}
        }
        case RESET_PTZ_VALUES: {
            return {
                ...INITIAL_STATE,
                cameraSecondarySpeed: +localStorage.getItem("cameraSecondarySpeed") || MAX_CAMERA_SPEED,
                cameraDefaultSpeed: +localStorage.getItem("cameraDefaultSpeed") || MAX_CAMERA_SPEED,
                deadZone: +localStorage.getItem("deadZone") || DEFAULT_VALUE_DEAD_ZONE,
            }
        }
        case CHANGE_CAMERA_SECONDARY_SPEED_ACTION: {
            localStorage.setItem("cameraSecondarySpeed", action.data);
            return {...state, cameraSecondarySpeed: action.data}
        }
        case CHANGE_CAMERA_DEFAULT_SPEED_ACTION: {
            localStorage.setItem("cameraDefaultSpeed", action.data);
            return {...state, cameraDefaultSpeed: action.data}
        }
        case CHANGE_DEAD_ZONE_ACTION: {
            localStorage.setItem("deadZone", action.data);
            return {...state, deadZone: action.data}
        }
        case GAMEPAD_CONNECTED_ACTION: {
            return {...state, gamepad: action.data}
        }
        case STORE_VIDEO_DIMENSIONS: {
            return {...state, videoDimensions: action.data}
        }
        default:
            return state;
    }
}

export default ptzState;