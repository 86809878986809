import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl } from 'react-intl';
import { styles } from './CameraIconStyles';
import svg from '../../../../images/cctv1.svg';

const useStyles = makeStyles((theme) => styles(theme));
export default function CameraIconComponent({ tooltipText, disabled }) {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.root}>
      <Tooltip title={intl.formatMessage({ id: tooltipText })}>
        <img alt="" className={disabled ? classes.disabled : classes.img} src={svg} />
      </Tooltip>
    </div>
  );
}
