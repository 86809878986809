import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import AddressCardComponent from './components/addressCardComponent/AddressCardComponent';
import ContactCardComponent from './components/contactCardComponent/ContactCardComponent';
import InfoCardComponent from './components/infoCardComponent/InfoCardComponent';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4)
    }
}));

const DeploymentCardComponent = ({deployment, recordingPolicy}) => {
    const classes = useStyles();

    const {_id, codename, description, place, contacts} = deployment;

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <InfoCardComponent id={_id} codename={codename} description={description}
                                   recordingPolicy={recordingPolicy}/>
            </Grid>
            <Grid item xs={12}>
                <AddressCardComponent address={place}/>
            </Grid>
            <Grid item xs={12}>
                <ContactCardComponent contacts={contacts}/>
            </Grid>
        </Grid>
    );
};

DeploymentCardComponent.propTypes = {
    deployment: PropTypes.object.isRequired,
    recordingPolicy: PropTypes.object
};

export default DeploymentCardComponent;
